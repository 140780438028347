import React, { useState } from "react";
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TextField, InputAdornment } from "@material-ui/core";
import { getSearchResultAgainstBudgetHead } from "./api.call";
import { useDebounce } from "react-use";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from "@material-ui/icons/Search";
import emptyFile from "../../Assets/emptyData.svg"
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import Checkbox from '@material-ui/core/Checkbox';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs';
import LinearProgress from '@material-ui/core/LinearProgress';
import BudgetUpdateWorkitemBody from "./BudgetUpdateWorkitemBody";

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            {/* tslint:disable-next-line: max-line-length */}
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

function TransitionComponent(props) {
    const style = useSpring({
        from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
        to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
    });

    return (
        <animated.div style={style}>
            <Collapse {...props} />
        </animated.div>
    );
}

TransitionComponent.propTypes = {
    /**
     * Show the component; triggers the enter or exit states
     */
    in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
    iconContainer: {
        '& .close': {
            opacity: 0.3,
        },
    },
    group: {
        marginLeft: 7,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles((theme) => ({
    budgetCostHead: {
        backgroundColor: "#ececec"
    },
    budgetCostBody: {
        backgroundColor: "#fbfbfb"
    },
    budgetHeadHead: {
        backgroundColor: "#e0e0e0",
    },
    budgetHeadBody: {
        backgroundColor: "#ececec",
    },
    subheadHead: {
        backgroundColor: "#d4d4d4"
    },
    subheadBody: {
        backgroundColor: "#e0e0e0"
    },
    workitemHead: {
        backgroundColor: "#b8b8b8"
    },
    workitemBody: {
        backgroundColor: "#d4d4d4"
    },
    budgetCostHeading: {
        fontSize: "17px",
        fontWeight: "500",
        color: "black"
    },
    headHeading: {
        fontSize: "16px",
        fontWeight: "500",
        color: "black"
    },
    subheadHeading: {
        fontSize: "15px",
        fontWeight: "500",
        color: "black"
    },
    workitemHeading: {
        fontSize: "14px",
        fontWeight: "500",
        color: "black"
    },
    typeText: {
        fontSize: "10px",
        fontWeight: "400",
        color: "white",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "3px",
        padding: "1px 4px"
    },
    headTextCont: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    },
    searchCont: {
        width: "100%",
        height: "80px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    allCostCont: {
        width: "100%",
        overflowY: "auto"
    },
    searchTextBox: {
        width: "80%",
        padding: "5px 10px",
        backgroundColor: "#f7f7f7",
        borderRadius: "8px",
        fontWeight: "400",
    },
    accordionmainCont: {
        marginBottom: "5px"
    },
    emptyCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "50%",
            height: "auto"
        }
    },
    searchResultCont: {
        width: "100%",
        padding: "25px 25px 0px",
    },
    treeCont: {
        width: "100%",
    },
    headLabelSty: {
        width: "100%",
        "& p": {
            fontSize: "17px",
            color: "black",
            fontWeight: "500"
        },
    },
    subheadLabelSty: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& p": {
            fontSize: "15px",
            color: "#434343",
            fontWeight: "500",
            marginLeft: "5px"
        },
        "& .MuiSvgIcon-root": {
            width: "20px",
            height: "20px",
        }
    },
    loaderCont: {
        height: "5px",
        width: "100%"
    }
}));

const BudgetUpdateHeadBody = ({
    projectProfileId, parentChildrenMap, selectedBudgetId,
    setSelectedBudgetId, handleCheck, headSubheadWorkitemData,
    isFromBudgetMapping = false
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [searchText, setSearchText] = useState("")
    const [searchResult, setSearchResult] = useState(null)
    const [searchLoader, setSearchLoader] = useState(false)

    const getSubheadWorkitemByText = async () => {
        if (projectProfileId && searchText && searchText.length > 1) {
            setSearchLoader(true)
            await getSearchResultAgainstBudgetHead({ projectProfileId: projectProfileId, searchText: searchText })
                .then((data) => {
                    setSearchResult(data)
                    setSearchLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            setSearchResult(null)
        }
    }

    useDebounce(() => {
        getSubheadWorkitemByText()
    }, 1500, [searchText]);

    console.log(headSubheadWorkitemData)

    return (<>
        <div className={classes.searchCont} >
            <TextField
                placeholder="Search..."
                type="text"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                className={classes.searchTextBox}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{ color: "#7C7C8D" }} />
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                }}
            />
        </div>
        <div className={classes.loaderCont} >
            {searchLoader && (<LinearProgress />)}
        </div>
        <div className={classes.allCostCont} style={{ height: isFromBudgetMapping ? "calc(100vh - 490px)" : "calc(100vh - 135px)" }} >
            {searchText && searchText.length > 1 && searchResult !== null ? (<>
                {searchResult && searchResult.length == 0 ? (<div className={classes.emptyCont} >
                    <img src={emptyFile} />
                </div>) : (<div className={classes.searchResultCont} >
                    <TreeView
                        className={classes.treeCont}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                    >
                        {searchResult.map((result, jk) => (<>
                            {result?.usingAs === "asSubheadChild" ? (<StyledTreeItem
                                key={jk}
                                nodeId={`${result?.name}-${jk}`}
                                label={<div className={classes.subheadLabelSty} >
                                    <Checkbox
                                        color="primary"
                                        checked={result?._id && selectedBudgetId.includes(result?._id)}
                                        onChange={(event) => {
                                            handleCheck(result?._id, event.target.checked)
                                        }}
                                    />
                                    <p><span style={{ color: "#2e4cd3" }} >{result?.subworkhead?.budgetCostHead?.name}</span><span style={{ margin: "0px 3px" }} >|</span><span style={{ color: "#738bf7" }} >{result?.subworkhead?.name}</span><span style={{ margin: "0px 3px" }} >|</span>{result?.name}</p>
                                </div>}
                            />) : (<StyledTreeItem
                                key={jk}
                                nodeId={`${result?.name}-${jk}`}
                                label={<div className={classes.subheadLabelSty} >
                                    <Checkbox
                                        color="primary"
                                        checked={result?._id && selectedBudgetId.includes(result?._id)}
                                        onChange={(event) => {
                                            handleCheck(result?._id, event.target.checked)
                                        }}
                                    />
                                    <p><span style={{ color: "#2e4cd3" }} >{result?.budgetCostHead?.name}</span><span style={{ margin: "0px 3px" }} >|</span>{result?.name}</p>
                                </div>}
                            />)}
                        </>))}
                    </TreeView>
                </div>)}
            </>) : (<>
                {headSubheadWorkitemData && headSubheadWorkitemData.map((budgetCost, index) => (<Accordion key={index} >
                    <div className={classes.budgetCostHead} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h3 className={classes.budgetCostHeading}>{budgetCost?.title}</h3>
                        </AccordionSummary>
                    </div>
                    <div className={classes.budgetCostBody} >
                        <div>
                            <AccordionDetails>
                                <div style={{ padding: "25px" }} >
                                    <TreeView
                                        className={classes.treeCont}
                                        defaultExpanded={['1']}
                                        defaultCollapseIcon={<MinusSquare />}
                                        defaultExpandIcon={<PlusSquare />}
                                        defaultEndIcon={<CloseSquare />}
                                    >
                                        {budgetCost.budgetHead && budgetCost.budgetHead.map((headData, i) => (
                                            <StyledTreeItem
                                                key={i}
                                                nodeId={`${headData?.name}-${i}`}
                                                label={<div className={classes.headLabelSty} >
                                                    <p>
                                                        {headData?.name || ""}
                                                    </p>
                                                </div>}
                                            >
                                                {headData.items && headData.items.map((subheadData, j) => (
                                                    <StyledTreeItem
                                                        key={j}
                                                        nodeId={`${subheadData?.name}-${i}-${j}`}
                                                        label={<div className={classes.subheadLabelSty} >
                                                            <Checkbox
                                                                color="primary"
                                                                checked={selectedBudgetId.includes(subheadData?._id)}
                                                                onChange={(event) => {
                                                                    handleCheck(subheadData?._id, event.target.checked)
                                                                }}
                                                            />
                                                            <p>
                                                                {subheadData?.name || ""}
                                                            </p>
                                                        </div>}
                                                    >
                                                        {subheadData?.items && subheadData.items.map((workitemData, k) => (
                                                            <BudgetUpdateWorkitemBody
                                                                key={k}
                                                                workitemData={workitemData}
                                                                i={i}
                                                                j={j}
                                                                k={k}
                                                                parentChildrenMap={parentChildrenMap}
                                                                selectedBudgetId={selectedBudgetId}
                                                                setSelectedBudgetId={setSelectedBudgetId}
                                                                handleCheck={handleCheck}
                                                            />
                                                        ))}
                                                    </StyledTreeItem>
                                                ))}
                                            </StyledTreeItem>
                                        ))}
                                    </TreeView>
                                </div>
                            </AccordionDetails>
                        </div>
                    </div>
                </Accordion>))}
            </>)}
        </div>
    </>);
};

export default BudgetUpdateHeadBody;