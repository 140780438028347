import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CSLinkedTransactions from "./customersComponent/CSLinkedTransactions";
import CSLinkedTickets from "./customersComponent/CSLinkedTickets";
import CSLinkedBookings from "./customersComponent/CSLinkedBookings";

const useStyles = makeStyles((theme) => ({
    tabMainCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        padding: "10px"
    }
}));

export default function CustomerDataAnalysis({
    contactData, walletId, curEntityId, entity
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [curOption, setCurOption] = useState(0)


    return (
        <>
            <div>
                <Tabs
                    value={curOption}
                    onChange={(event, newValue) => { setCurOption(newValue); }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    <Tab label="Transactions" />
                    <Tab label="Bookings" />
                    <Tab label="Tickets" />
                </Tabs>
            </div>
            <Paper elevation={2} className={classes.tabMainCont}  >
                {curOption === 0 && (
                    <CSLinkedTransactions
                        walletId={walletId}
                        secondPartyId={contactData?.profile?._id}
                    />
                )}
                {curOption === 1 && (
                    <CSLinkedBookings
                        curEntityId={curEntityId}
                        entity={entity}
                        customerProfileId={contactData?.profile?._id}
                    />
                )}
                {curOption === 2 && (
                    <CSLinkedTickets
                        curEntityId={curEntityId}
                        entity={entity}
                        customerProfileId={contactData?.profile?._id}
                    />
                )}
            </Paper>
        </>
    );
}