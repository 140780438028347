import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { BiSave } from "react-icons/bi";
import { updateLibraryById } from "./Api.call";
import NormalDialog from "../styled/CommonComponents/NormalDialog";

export default function LibraryEditDrawer({
  open,
  setOpen,
  afterCreateOrEditNewLibrary,
  data,
}) {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("")

  useEffect(() => {
    setTitle(data?.name)
  }, [data])

  const handleSubmit = async () => {
    setLoading(true);
    const updatedObj = {
      name: title,
      _id: data?._id,
    };
    await updateLibraryById(updatedObj)
      .then((data) => {
        if (data) {
          afterCreateOrEditNewLibrary(data, true);
        }
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={() => { setOpen(false); }}
      pageTitle={"Update Library"}
      content={<>
        <TextField
          size="small"
          id="title"
          autoFocus
          name="title"
          label="Title"
          type="text"
          value={title}
          onChange={(e)=>{
            setTitle(e.target.value)
          }}
          style={{ width: "350px", margin: "10px 0px 20px" }}
          variant="outlined"
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <LoadingButton
            loading={loading}
            type="submit"
            size="small"
            variant="contained"
            endIcon={<BiSave />}
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
        </div>
      </>}
    />
  );
}
