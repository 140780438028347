const { makeStyles } = require("@material-ui/core");
const { Paper, Typography } = require("@material-ui/core");

const useStyles = makeStyles((theme) => ({
  analyticsContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1.5),
    backgroundColor: "#fff",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  distributionContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(1),
  },
  distributionBox: {
    padding: theme.spacing(1.5),
    backgroundColor: "#f5f5f5",
    borderRadius: theme.shape.borderRadius,
    boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
  },
  boxTitle: {
    fontSize: "0.85rem",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(0.5),
  },
  itemList: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  itemRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.25, 0.75),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#fff",
    "& span": {
      fontSize: "0.75rem",
      color: theme.palette.text.secondary,
    },
    "& strong": {
      fontSize: "0.8rem",
      color: theme.palette.text.primary,
    },
  },
  statsWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
    gap: theme.spacing(1.5),
  },
  statItem: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minHeight: "60px",
    backgroundColor: `${theme.palette.primary.main}99`,
  },
  statLabel: {
    color: "#fff",
    fontSize: "0.75rem",
    marginBottom: theme.spacing(0.25),
    fontWeight: 500,
  },
  statValue: {
    color: "#fff",
    fontSize: "1.2rem",
    fontWeight: 600,
    margin: 0,
  },
}));

const AnalyticsSummary = ({ analytics, loading }) => {
  const classes = useStyles();

  if (loading || !analytics) {
    return null;
  }

  const summaryData = [
    { label: "Total Checklists", value: analytics?.totalCount || 0 },
    { label: "Total Checkpoints", value: analytics?.totalCheckpoints || 0 },
    { label: "Total Blocks", value: analytics?.totalBlocks || 0 },
    { label: "Total Units", value: analytics?.totalUnits || 0 },
  ];

  return (
    <div className={classes.analyticsContainer}>
      <div className={classes.section}>
        <h3 className={classes.sectionTitle}>Summary Statistics</h3>
        <div className={classes.statsWrapper}>
          {summaryData.map((item, index) => (
            <div key={index} className={classes.statItem}>
              <span className={classes.statLabel}>{item.label}</span>
              <p className={classes.statValue}>{item.value}</p>
            </div>
          ))}
        </div>
      </div>

      <div className={classes.section}>
        <div className={classes.distributionContainer}>
          <Paper className={classes.distributionBox}>
            <Typography className={classes.boxTitle}>
              Status Distribution
            </Typography>
            <div className={classes.itemList}>
              {Object.entries(analytics?.statusCounts || {}).map(
                ([status, count], index) => (
                  <div key={index} className={classes.itemRow}>
                    <span>{status}</span>
                    <strong>{count}</strong>
                  </div>
                )
              )}
            </div>
          </Paper>

          <Paper className={classes.distributionBox}>
            <Typography className={classes.boxTitle}>
              Structure Distribution
            </Typography>
            <div className={classes.itemList}>
              {Object.entries(analytics?.structureCounts || {}).map(
                ([id, data], index) => (
                  <div key={index} className={classes.itemRow}>
                    <span>{data.name}</span>
                    <strong>{data.count}</strong>
                  </div>
                )
              )}
            </div>
          </Paper>

          <Paper className={classes.distributionBox}>
            <Typography className={classes.boxTitle}>
              Activity Distribution
            </Typography>
            <div className={classes.itemList}>
              {Object.entries(analytics?.labelCounts || {}).map(
                ([id, data], index) => (
                  <div key={index} className={classes.itemRow}>
                    <span>{data.name}</span>
                    <strong>{data.count}</strong>
                  </div>
                )
              )}
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsSummary;
