import {
  Drawer,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  Grid,
  TextField,
  Chip,
  CircularProgress,
  Avatar,
  Tooltip,
  styled,
} from "@material-ui/core";
import { useState } from "react";
import OrgPicker from "../../styled/generic/OrgPicker";
import ProjectPicker from "../../styled/generic/ProjectPicker";
import BlockUnitViewer from "../tasks/components/BlockUnitViewer";
import { tooltipClasses } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormBox from "../../styled/generic/FormBox";
import CategoryAutocomplete from "../../OfferingsAndProducts/CategoryAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import useShareAndAssign from "../../ResourceAccessControl/shareAndAssign";
import { Add } from "@mui/icons-material";
import { useHistory } from "react-router-dom";

const assignBtn = (
  <Button variant="outlined" startIcon={<Add />}>
    Click here to assign
  </Button>
);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function ChecklistDrawer({
  isDrawerOpen,
  setIsDrawerOpen,
  checklists,
  profile,
  libraryId,
  profileParent,
  getData,
  preData,
}) {
  const { assignedProfiles, AssignButton, AssigneDrawer, AssignView } =
    useShareAndAssign({
      initAssigned: null,
      resourceId: null,
      resourceName: null,
      customShareBtn: null,
      customAssignBtn: assignBtn,
    });
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(preData?.organization || "");
  const [selectedProject, setSelectedProject] = useState(
    preData?.project || profileParent?.parent?._id
  );
  const [selectedBlocks, setSelectedBlocks] = useState(preData?.blocks || []);
  const [selectedUnits, setSelectedUnits] = useState(preData?.units || []);
  const [selectedColumn, setSelectedColumn] = useState(preData?.columns);
  const [structures, setStructures] = useState(preData?.structures || []);
  const [labels, setLabels] = useState(preData?.labels || []);
  const [loading, setLoading] = useState(false);
  const [customChecklistId, setCustomChecklistId] = useState("");

  const resetChecklist = () => {
    setSelectedChecklist(null);
    setSelectedOrg("");
    setSelectedProject(profileParent?.parent?._id);
    setSelectedBlocks([]);
    setSelectedUnits([]);
    setSelectedColumn([]);
    setCustomChecklistId("");
    setStructures([]);
    setLabels([]);
  };

  const handleCreateChecklist = async () => {
    try {
      setLoading(true);
      if (!selectedChecklist?._id) {
        throw new Error("Please select a checklist template");
      }
      if (!customChecklistId || !selectedOrg || !selectedProject) {
        throw new Error("Please select all required fields");
      }
      const res = await Api.post("checklist/create", {
        checklistId: selectedChecklist?._id,
        customId: customChecklistId,
        orgId: selectedOrg._id,
        projectId: selectedProject._id,
        blocks: selectedBlocks,
        units: selectedUnits,
        columns: selectedColumn,
        structures: structures,
        labels: labels,
        profile: profile?._id,
        library: libraryId,
        userProfile: user?.profile,
        assigned: assignedProfiles?.map((profile) => profile?._id),
        tasks: preData?.tasks,
      });
      const data = res?.data;
      if (data && data?._id) {
        history.push(`/planning/checklist/${data?._id}`);
      }
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Checklist created successfully",
        },
      });
      // resetChecklist();
      // getData();
    } catch (error) {
      console.log(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: error.message,
        },
      });
    } finally {
      setLoading(false);
      setIsDrawerOpen(false);
    }
  };

  const formatColumnLabel = (columnNumber) => {
    return `Column-${columnNumber}`;
  };

  return (
    <Drawer
      title="Create Checklist"
      anchor="right"
      onClose={() => setIsDrawerOpen(false)}
      open={isDrawerOpen}
      PaperProps={{ style: { width: "800px" } }}
    >
      <Box
        p={3}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <Typography variant="h5" gutterBottom>
          Create New Checklist
        </Typography>

        <Box mt={2}>
          <TextField
            fullWidth
            size="small"
            label="Checklist ID"
            variant="outlined"
            value={customChecklistId}
            onChange={(e) => setCustomChecklistId(e.target.value)}
            placeholder="Enter a unique identifier for this checklist"
          />
        </Box>
        <Box>
          <Autocomplete
            options={checklists}
            value={selectedChecklist}
            onChange={(event, value) => {
              setSelectedChecklist(value);
            }}
            fullWidth
            size="small"
            getOptionLabel={(option) => option?.title || ""}
            getOptionKey={(option) => option?._id || ""}
            renderInput={(params) => (
              <TextField
                fullWidth
                size="small"
                label="Checklist Template"
                variant="outlined"
                {...params}
                placeholder={"Select checklist template"}
              />
            )}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <OrgPicker
                selectedOrg={selectedOrg}
                setSelectedOrg={setSelectedOrg}
                defaultOrganizationId={
                  preData?.organization ||
                  profile?.parent?.ownerProfile?.parent ||
                  profile?.parent?._id
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <ProjectPicker
                selectedProject={selectedProject}
                setSelectedProject={setSelectedProject}
                defaultProjectId={
                  preData?.project || profileParent?.parent?._id
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <div style={{ width: "100%" }}>
          <BlockUnitViewer
            projectId={selectedProject?._id}
            selectedBlocks={selectedBlocks}
            setSelectedBlocks={setSelectedBlocks}
            selectedUnits={selectedUnits}
            setSelectedUnits={setSelectedUnits}
            fullWidth={true}
          />
        </div>
        <FormBox label="Columns">
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={selectedColumn}
            onChange={(event, newValue) => {
              // Convert string inputs to numbers and validate
              const numericValues = newValue
                .map((val) => {
                  const num = parseInt(val);
                  return isNaN(num) ? null : num;
                })
                .filter((val) => val !== null);
              setSelectedColumn(numericValues);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter column numbers"
                helperText="Enter column numbers and press Enter"
                size="small"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={formatColumnLabel(option)}
                  {...getTagProps({ index })}
                  size="small"
                />
              ))
            }
          />
        </FormBox>
        <FormBox label="Structure">
          <CategoryAutocomplete
            libraryId={libraryId}
            selectedCategories={structures}
            setSelectedCategories={setStructures}
            isMultiple={true}
            label={"Structure"}
            profileId={profile?._id}
            type={"Structure"}
            isCreateNew={true}
            placeholder="Select Structure"
          />
        </FormBox>

        <FormBox label="Activities">
          <CategoryAutocomplete
            libraryId={libraryId}
            selectedCategories={labels}
            setSelectedCategories={setLabels}
            isMultiple={true}
            label={"Activity Type"}
            profileId={profile?._id}
            type={"Task"}
            isCreateNew={true}
            placeholder="Select/Add task activity category"
          />
        </FormBox>

        <FormBox label="Assignees">
          {AssignButton} {AssigneDrawer}
          <div>{AssignView}</div>
        </FormBox>

        <Box mt={4} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateChecklist}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Create"}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
