import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { getPublicLibrary, cloneLibrary } from './Api.call'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import arrayToReducer from "../../helpers/arrayToReducer";
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  bodyCont: {
    width: "100%",
    height: "calc(100% - 55px)",
    padding: "20px"
  },
  btnCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: "15px",
  }
}));

export default function OfferingCopyDrawer({
  library, cloneOfferingDrawer, setCloneOfferingDrawer,
  setLoader, loader, afterClone
}) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(null);
  const [libIds, setLibIds] = useState([])
  const [libDict, setLibDict] = useState({})

  useEffect(() => {
    if (cloneOfferingDrawer) {
      getPublicLibrary()
        .then(data => {
          const { newDict, idArr } = arrayToReducer(data)
          setLibDict(newDict)
          setLibIds(idArr)
          setValue(idArr[0])
        })
    }
  }, [cloneOfferingDrawer])


  function getLibraryId(library) {
    if (typeof library === 'object' && library !== null) {
      return library?._id;
    } else if (typeof library === 'string') {
      return library;
    }
    return null;
  }

  const onCloneSubmit = async () => {
    setLoader(true)
    const success = await cloneLibrary({
      newLibraryId: getLibraryId(library),
      cloneFromLibraryId: value
    })
    dispatch({
      type: "AddApiAlert",
      payload: {
        success,
        message: success ? "Successfully cloned library" : "Cloning library failed",
      },
    });
    afterClone()
    setLoader(false)
    setCloneOfferingDrawer(false)
  }

  return (
    <NormalDrawer
      openDrawer={cloneOfferingDrawer}
      setOpenDrawer={setCloneOfferingDrawer}
      anchor={"right"}
      width={"400px"}
      title={"Select library"}
      content={<>
        <div style={{ width: "100%", height: "5px" }} >
          {loader && (<LinearProgress />)}
        </div>
        <FormControl
          component="fieldset"
          className={classes.bodyCont}
        >
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          >
            {libIds ? libIds.map(libId => {
              const lib = libDict[libId];
              return (
                <FormControlLabel
                  key={libId}
                  value={libId}
                  control={<Radio />}
                  label={lib?.name}
                />
              )
            }) : null}
          </RadioGroup>
        </FormControl>
        <div className={classes.btnCont} >
          <Button
            onClick={onCloneSubmit}
            variant="contained"
            color="primary"
            size="small"
            disabled={loader ? true : value ? false : true}
          >
            Clone Library
          </Button>
        </div>
      </>}
    />
  )
}