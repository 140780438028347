import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from '../styled/CommonComponents/NormalDrawer';
import NotifyAlert from '../styled/CommonComponents/NotifyAlert';
import {
    Autocomplete, Button, Grid, TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import OrgPicker from '../styled/generic/OrgPicker';
import ProjectPicker from '../styled/generic/ProjectPicker';
import PaginatedEntityDropdown from '../styled/CommonComponents/PaginatedEntityDropdown';
import GooglePlaceAutocomplete from '../styled/CommonComponents/Google.Place.Auto';
import { createEventApiCall, createRecurringEventApiCall, updateEventDetailsById } from './api.call';
import RecurringEventUI, { generateRecurringEventDates } from './RecurringEventUI';
import * as yup from "yup";
import "../../App.css";

const addEventSchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    type: yup.string().required('Type is required'),
    startDate: yup.date().required('Start date is required'),
    endDate: yup
        .date()
        .required('End date is required')
        .test(
            'is-greater',
            'End date must be greater than start date',
            function (value) {
                const { startDate } = this.parent;
                return value > startDate;
            }
        ),
    participants: yup
        .array()
        .of(yup.string().required('Participants is required'))
        .required('Participants are required')
        .min(1, 'At least one participant is required'),
});


const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "15px",
        paddingBottom: "30px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    label: {
        fontSize: "15px",
        fontWeight: "500",
        marginBottom: "3px",
        marginTop: "2px"
    },
    addressCont: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    requiredSty: {
        color: "red"
    },
    validationSty: {
        color: "red",
        fontSize: "12px",
        marginTop: "2px"
    }
}));

export default function EventAddEdit({
    openEventAddEditDrawer, setOpenEventAddEditDrawer,
    editEventData = null, profileId, defaultType = null,
    tags = [], parent = null, parentModelName = null,
    defaultStartDate = null, defaultEndDate = null,
    setDefaultStateDate, setDefaultEndDate, getAllEvents,
    setEditEventData
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false)
    const [alertObj, setAlertObj] = useState({})
    const [eventCreateType, setEventCreateType] = useState("Once")
    const [eventType, setEventType] = useState(defaultType || "Meeting")
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [selectedProject, setSelectedProject] = useState(null)
    const [eventName, setEventName] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [selectedRequiredAttendees, setSelectedRequiredAttendees] = useState([]);
    const [selectedOptionalAttendees, setSelectedOptionalAttendees] = useState([]);
    const [fullAddressLine, setFullAddressLine] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [regionCode, setRegionCode] = useState("");
    const [country, setCountry] = useState(null);
    const [countryCode, setCountryCode] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [startDateRE, setStartDateRE] = useState(dayjs());
    const [repeatEvery, setRepeatEvery] = useState(1);
    const [repeatType, setRepeatType] = useState("Day");
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [monthlyOption, setMonthlyOption] = useState("OnDate");
    const [dayOfMonth, setDayOfMonth] = useState(1);
    const [dayOfWeek, setDayOfWeek] = useState("Monday");
    const [endDateRE, setEndDateRE] = useState(dayjs());
    const [yearlyOption, setYearlyOption] = useState("OnDate");
    const [specificDateMonth, setSpecificDateMonth] = useState("0");
    const [specificDateDay, setSpecificDateDay] = useState(1);
    const [nthWeek, setNthWeek] = useState("1");
    const [weekDay, setWeekDay] = useState("Monday");
    const [nthWeekMonth, setNthWeekMonth] = useState("0");
    const [nthWeekOfMonth, setNthWeekOfMonth] = useState("1")
    const [validationObj, setValidationObj] = useState(null);


    useEffect(() => {
        if (openEventAddEditDrawer) {
            if (editEventData?._id) {
                setEventType(editEventData?.type)
                setSelectedOrg(editEventData?.organization)
                setSelectedProject(editEventData?.project)
                setEventName(editEventData?.title)
                setEventDescription(editEventData?.description)
                setStartDate(dayjs(new Date(editEventData?.startDate)))
                setEndDate(dayjs(new Date(editEventData?.endDate)))
                setSelectedRequiredAttendees((editEventData?.roles.filter((s) => s?.role === "Required")).map((s) => {
                    return {
                        label: s?.user?.parent?.displayName,
                        value: s?.user?._id,
                        data: s?.user
                    }
                }))
                setSelectedOptionalAttendees((editEventData?.roles.filter((s) => s?.role === "Optional")).map((s) => {
                    return {
                        label: s?.user?.parent?.displayName,
                        value: s?.user?._id,
                        data: s?.user
                    }
                }))
                setFullAddressLine(editEventData?.location?.address_line1)
                setStreetAddress(editEventData?.location?.address_line2)
                setZip(editEventData?.location?.postcode)
                setCity(editEventData?.location?.city)
                setRegion(editEventData?.location?.state)
                setRegionCode(editEventData?.location?.state_code)
                setCountry(editEventData?.location?.country)
                setCountryCode(editEventData?.location?.country_code)
                setLatitude(editEventData?.location?.latitude)
                setLongitude(editEventData?.location?.longitude)
            } else {
                setEventType(defaultType || "Meeting")
                setSelectedOrg(null)
                setSelectedProject(null)
                setEventName("");
                setEventDescription("");
                setStartDate(dayjs());
                setEndDate(dayjs());
                setSelectedRequiredAttendees([]);
                setSelectedOptionalAttendees([]);
                setFullAddressLine("");
                setStreetAddress("");
                setZip("");
                setCity("");
                setRegion("");
                setRegionCode("");
                setCountry(null);
                setCountryCode(null);
                setLatitude(null);
                setLongitude(null);
            }
        } else {
            setEditEventData(null)
        }
    }, [editEventData, openEventAddEditDrawer])

    useEffect(() => {
        if (!editEventData?._id && openEventAddEditDrawer && defaultStartDate) {
            setStartDate(dayjs(defaultStartDate))
        }
    }, [openEventAddEditDrawer, defaultStartDate])

    useEffect(() => {
        if (!editEventData?._id && openEventAddEditDrawer && defaultEndDate) {
            setEndDate(dayjs(defaultEndDate))
        }
    }, [openEventAddEditDrawer, defaultEndDate])

    const resetData = () => {
        setOpenEventAddEditDrawer(false)
        setEventType(defaultType || "Meeting")
        setSelectedOrg(null)
        setSelectedProject(null)
        setEventName("");
        setEventDescription("");
        setStartDate(dayjs());
        setEndDate(dayjs());
        setSelectedRequiredAttendees([]);
        setSelectedOptionalAttendees([]);
        setFullAddressLine("");
        setStreetAddress("");
        setZip("");
        setCity("");
        setRegion("");
        setRegionCode("");
        setCountry(null);
        setCountryCode(null);
        setLatitude(null);
        setLongitude(null);
        setStartDateRE(dayjs())
        setRepeatEvery(1)
        setRepeatType("Day")
        setDaysOfWeek([])
        setMonthlyOption("OnDate")
        setDayOfMonth(1)
        setDayOfWeek("Monday")
        setEndDateRE(dayjs())
        setYearlyOption("OnDate")
        setSpecificDateMonth("0")
        setSpecificDateDay(1)
        setNthWeek("1")
        setWeekDay("Monday")
        setNthWeekMonth("0")
        setNthWeekOfMonth("1")
        if (setDefaultStateDate) {
            setDefaultStateDate(null)
        }
        if (setDefaultEndDate) {
            setDefaultEndDate(null)
        }
    }

    useEffect(() => {
        setAlertObj({})
        setValidationObj({})
    }, [
        eventCreateType, eventType, selectedOrg, selectedProject, eventName,
        eventDescription, startDate, endDate, selectedRequiredAttendees, weekDay,
        selectedOptionalAttendees, fullAddressLine, streetAddress, zip, city,
        region, regionCode, country, countryCode, latitude, longitude, startDateRE,
        repeatEvery, repeatType, daysOfWeek, monthlyOption, dayOfMonth, dayOfWeek,
        endDateRE, yearlyOption, specificDateMonth, specificDateDay, nthWeek, 
        nthWeekMonth, nthWeekOfMonth
    ])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (editEventData && editEventData?._id) {
            let profileArr = new Set([]);
            if (selectedOrg?.profile) {
                profileArr.add(selectedOrg?.profile)
            }
            if (selectedProject?.profile) {
                profileArr.add(selectedProject?.profile)
            }

            let profileIdRoleObjMap = {}
            editEventData?.roles.map((s) => {
                profileIdRoleObjMap[s?.user?._id] = s
            })
            let haveToInsertOnes = []
            let haveToUpdateOnes = []
            let existingRoleIds = []
            let haveToDeleteIds = [];

            selectedRequiredAttendees.map((s) => {
                profileArr.add(s?.value)
                if (profileIdRoleObjMap[s?.value]) {
                    if (profileIdRoleObjMap[s?.value]?.role !== "Required") {
                        //need to update
                        haveToUpdateOnes.push({
                            updateOne: {
                                filter: { _id: profileIdRoleObjMap[s?.value]?._id },
                                update: {
                                    $set: {
                                        role: "Required"
                                    }
                                }
                            },
                        });
                    }
                    existingRoleIds.push(profileIdRoleObjMap[s?.value]?._id)
                } else {
                    //need to insert
                    haveToInsertOnes.push({
                        user: s?.value,
                        role: "Required",
                        parent: null,
                        parentModelName: "CalendarEvent",
                        creator: user?.profile,
                        createdAt: new Date()
                    })
                }
            })

            selectedOptionalAttendees.map((s) => {
                profileArr.add(s?.value)
                if (profileIdRoleObjMap[s?.value]) {
                    if (profileIdRoleObjMap[s?.value]?.role !== "Optional") {
                        //need to update
                        haveToUpdateOnes.push({
                            updateOne: {
                                filter: { _id: profileIdRoleObjMap[s?.value]?._id },
                                update: {
                                    $set: {
                                        role: "Optional"
                                    }
                                }
                            },
                        });
                    }
                    existingRoleIds.push(profileIdRoleObjMap[s?.value]?._id)
                } else {
                    //need to insert
                    haveToInsertOnes.push({
                        user: s?.value,
                        role: "Optional",
                        parent: null,
                        parentModelName: "CalendarEvent",
                        creator: user?.profile,
                        createdAt: new Date()
                    })
                }
            })

            editEventData?.roles.map((s) => {
                if (!existingRoleIds.includes(s?._id)) {
                    haveToDeleteIds.push(s?._id)
                }
            })

            const updatedEventData = {
                title: eventName,
                description: eventDescription,
                type: eventType,
                location: {
                    address_line1: fullAddressLine,
                    address_line2: streetAddress,
                    city: city,
                    country: country,
                    country_code: countryCode,
                    latitude: latitude,
                    longitude: longitude,
                    state: region,
                    state_code: regionCode,
                    postcode: zip
                },
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
                durationMins: endDate.diff(startDate, "minute"),
                participants: [...profileArr],
                roles: {
                    haveToInsertOnes,
                    haveToUpdateOnes,
                    existingRoleIds,
                    haveToDeleteIds
                },
            }
            if (selectedOrg?._id) {
                updatedEventData.organization = selectedOrg?._id
            }
            if (selectedProject?._id) {
                updatedEventData.project = selectedProject?._id
            }
            await addEventSchema
                .validate(updatedEventData, { abortEarly: false })
                .then(async (validationRes) => {
                    setLoader(true)
                    await updateEventDetailsById({ _id: editEventData?._id, updatedEventData })
                        .then((data) => {
                            getAllEvents()
                            setAlertObj({
                                show: true,
                                message: "Event successfully updated!",
                                status: "success"
                            })
                            setOpenEventAddEditDrawer(false)
                            resetData();
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                        .finally(() => {
                            setLoader(false)
                        })
                })
                .catch((err) => {
                    console.log(err);
                    let errorArr = err?.inner;
                    let obj = {};
                    errorArr.map((err) => {
                        obj[err?.path] = err?.message;
                    });
                    console.log(obj)
                    setValidationObj(obj);
                    setAlertObj({
                        show: true,
                        message: "Please enter valid data in the below forms",
                        status: "error",
                    });
                });
        } else {
            let profileArr = new Set([]);
            if (selectedOrg?.profile?._id) {
                profileArr.add(selectedOrg?.profile?._id)
            }
            if (selectedProject?.profile?._id) {
                profileArr.add(selectedProject?.profile?._id)
            }
            let profileRoleArr = []
            selectedRequiredAttendees.map((s) => {
                profileArr.add(s?.value)
                profileRoleArr.push({
                    user: s?.value,
                    role: "Required",
                    parent: null,
                    parentModelName: "CalendarEvent",
                    creator: user?.profile,
                    createdAt: new Date()
                })
            })
            selectedOptionalAttendees.map((s) => {
                profileArr.add(s?.value);
                profileRoleArr.push({
                    user: s?.value,
                    role: "Optional",
                    parent: null,
                    parentModelName: "CalendarEvent",
                    creator: user?.profile,
                    createdAt: new Date()
                })
            })
            const minDiff = endDate.diff(startDate, "minute");
            const newEventData = {
                title: eventName,
                description: eventDescription,
                type: eventType,
                location: {
                    address_line1: fullAddressLine,
                    address_line2: streetAddress,
                    city: city,
                    country: country,
                    country_code: countryCode,
                    latitude: latitude,
                    longitude: longitude,
                    state: region,
                    state_code: regionCode,
                    postcode: zip
                },
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
                tags: tags,
                durationMins: minDiff,
                participants: [...profileArr],
                roles: profileRoleArr,
                addedBy: user?.profile,
                user: user?._id
            }
            if (selectedOrg?._id) {
                newEventData.organization = selectedOrg?._id
            }
            if (selectedProject?._id) {
                newEventData.project = selectedProject?._id
            }
            if (parent) {
                newEventData.parent = parent
            }
            if (parentModelName) {
                newEventData.parentModelName = parentModelName
            }

            await addEventSchema
                .validate(newEventData, { abortEarly: false })
                .then(async (validationRes) => {
                    if (eventCreateType === "Recurring") {
                        let isAnyIssue = false;
                        if (repeatType === "Day") {
                            if (
                                repeatEvery === null ||
                                repeatEvery === undefined ||
                                parseInt(repeatEvery) < 0 ||
                                parseInt(repeatEvery) === 0
                            ) {
                                isAnyIssue = true;
                            }
                        } else if (repeatType === "Week") {
                            if (
                                daysOfWeek.length === 0 ||
                                repeatEvery === null ||
                                repeatEvery === undefined ||
                                parseInt(repeatEvery) < 0 ||
                                parseInt(repeatEvery) === 0
                            ) {
                                isAnyIssue = true;
                            }
                        } else if (repeatType === "Month") {
                            if (
                                (
                                    monthlyOption === "OnDate" &&
                                    (
                                        dayOfMonth === null ||
                                        dayOfMonth === undefined ||
                                        parseInt(dayOfMonth) === 0 ||
                                        parseInt(dayOfMonth) < 0 ||
                                        parseInt(dayOfMonth) > 31
                                    )
                                ) ||
                                repeatEvery === null ||
                                repeatEvery === undefined ||
                                parseInt(repeatEvery) < 0 ||
                                parseInt(repeatEvery) === 0
                            ) {
                                isAnyIssue = true;
                            }
                        } else if (repeatType === "Year") {
                            if (
                                (
                                    yearlyOption === "OnDate" &&
                                    (
                                        specificDateDay === null ||
                                        specificDateDay === undefined ||
                                        parseInt(specificDateDay) === 0 ||
                                        parseInt(specificDateDay) < 0 ||
                                        parseInt(specificDateDay) > 31
                                    )
                                ) ||
                                repeatEvery === null ||
                                repeatEvery === undefined ||
                                parseInt(repeatEvery) < 0 ||
                                parseInt(repeatEvery) === 0
                            ) {
                                isAnyIssue = true;
                            }
                        }

                        if (!isAnyIssue) {
                            const genertedEvents = generateRecurringEventDates({
                                startDate: startDateRE,
                                endDate: endDateRE,
                                repeatEvery,
                                repeatType,
                                daysOfWeek,
                                monthlyOption,
                                dayOfMonth,
                                nthWeekOfMonth,
                                dayOfWeek,
                                yearlyOption,
                                specificDateMonth,
                                specificDateDay,
                                nthWeek,
                                weekDay,
                                nthWeekMonth
                            })
                            let startEndDatesArr = []
                            genertedEvents && genertedEvents.map((s) => {
                                const datePart = dayjs(s);
                                const timePart = dayjs(startDate);
                                const actualStartDate = datePart
                                    .hour(timePart.hour())
                                    .minute(timePart.minute())
                                    .second(timePart.second())
                                    .millisecond(timePart.millisecond());
                                const actualEndDate = actualStartDate.add(minDiff, 'minute');
                                startEndDatesArr.push({
                                    startDate: actualStartDate.toDate(),
                                    endDate: actualEndDate.toDate(),
                                })
                            });
                            setLoader(true)
                            createRecurringEventApiCall({ newEventData, startEndDatesArr })
                                .then((data) => {
                                    getAllEvents()
                                    setAlertObj({
                                        show: true,
                                        message: "Event successfully created!",
                                        status: "success"
                                    })
                                    setOpenEventAddEditDrawer(false)
                                    resetData();
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                                .finally(() => {
                                    setLoader(false)
                                })
                        } else {
                            setValidationObj({
                                recurring: "Enter valid data"
                            })
                            setAlertObj({
                                show: true,
                                message: "Enter valid data for recurring events!",
                                status: "error"
                            })
                        }
                    } else {
                        setLoader(true)
                        createEventApiCall({ newEventData })
                            .then((data) => {
                                getAllEvents()
                                setAlertObj({
                                    show: true,
                                    message: "Event successfully created!",
                                    status: "success"
                                })
                                setOpenEventAddEditDrawer(false)
                                resetData();
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                            .finally(() => {
                                setLoader(false)
                            })
                    }
                })
                .catch((err) => {
                    console.log(err);
                    let errorArr = err?.inner;
                    let obj = {};
                    errorArr.map((err) => {
                        obj[err?.path] = err?.message;
                    });
                    console.log(obj)
                    setValidationObj(obj);
                    setAlertObj({
                        show: true,
                        message: "Please enter valid data in the below forms",
                        status: "error",
                    });
                });
        }
    }

    return (<>
        <NormalDrawer
            openDrawer={openEventAddEditDrawer}
            setOpenDrawer={setOpenEventAddEditDrawer}
            anchor={"right"}
            width={"800px"}
            title={editEventData?._id ? "Edit Event" : "Add Event"}
            content={<>
                {openEventAddEditDrawer && (
                    <form className={classes.mainDialogCont} >
                        <div className={classes.bodyCont} >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Type<span className={classes.requiredSty} >*</span></p>
                                        <Autocomplete
                                            options={["Meeting", "Phone Call", "Site Visit", "Follow-up", "Other"]}
                                            value={eventType}
                                            onChange={(evt, newPlace) => setEventType(newPlace)}
                                            getOptionLabel={(option) => option || ""}
                                            fullWidth
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    size="small"
                                                />
                                            )}
                                        />
                                        <p className={classes.validationSty} >{validationObj?.type}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Title<span className={classes.requiredSty} >*</span></p>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            placeholder="Enter title"
                                            value={eventName}
                                            onChange={(e) => setEventName(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                        />
                                        <p className={classes.validationSty} >{validationObj?.title}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Description</p>
                                        <TextField
                                            multiline
                                            fullWidth
                                            value={eventDescription}
                                            onChange={(e) => setEventDescription(e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            rows={4}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className={classes.label} >Start Date<span className={classes.requiredSty} >*</span></p>
                                        <DateTimePicker
                                            value={startDate}
                                            onChange={(newValue) => setStartDate(newValue)}
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    fullWidth: true,
                                                    InputProps: {
                                                        value: dayjs(startDate).format("D MMM YYYY, h:mm A"),
                                                    },
                                                },
                                            }}
                                        />
                                        <p className={classes.validationSty} >{validationObj?.startDate}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <p className={classes.label} >End Date<span className={classes.requiredSty} >*</span></p>
                                        <DateTimePicker
                                            value={endDate}
                                            onChange={(newValue) => setEndDate(newValue)}
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    fullWidth: true,
                                                    InputProps: {
                                                        value: dayjs(endDate).format("D MMM YYYY, h:mm A"),
                                                    },
                                                },
                                            }}
                                        />
                                        <p className={classes.validationSty} >{validationObj?.endDate}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Event Create Type</p>
                                        <Autocomplete
                                            options={["Once", "Recurring"]}
                                            value={eventCreateType}
                                            onChange={(evt, newPlace) => setEventCreateType(newPlace)}
                                            getOptionLabel={(option) => option || ""}
                                            fullWidth
                                            disableClearable
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {eventCreateType === "Recurring" && (<>
                                        <p
                                            className={classes.label}
                                            style={{ marginLeft: "8px" }}
                                        >Set Recurrence</p>
                                        <Grid
                                            item
                                            xs={12}
                                            style={{
                                                border: "1px solid gray",
                                                marginLeft: "8px",
                                                marginRight: "3px",
                                                padding: "7px 15px 5px",
                                                borderRadius: "5px"
                                            }}
                                        >
                                            <Grid
                                                container
                                                spacing={1}
                                            >
                                                <RecurringEventUI
                                                    startDateRE={startDateRE}
                                                    setStartDateRE={setStartDateRE}
                                                    repeatEvery={repeatEvery}
                                                    setRepeatEvery={setRepeatEvery}
                                                    repeatType={repeatType}
                                                    setRepeatType={setRepeatType}
                                                    daysOfWeek={daysOfWeek}
                                                    setDaysOfWeek={setDaysOfWeek}
                                                    monthlyOption={monthlyOption}
                                                    setMonthlyOption={setMonthlyOption}
                                                    dayOfMonth={dayOfMonth}
                                                    setDayOfMonth={setDayOfMonth}
                                                    dayOfWeek={dayOfWeek}
                                                    setDayOfWeek={setDayOfWeek}
                                                    endDateRE={endDateRE}
                                                    setEndDateRE={setEndDateRE}
                                                    yearlyOption={yearlyOption}
                                                    setYearlyOption={setYearlyOption}
                                                    specificDateMonth={specificDateMonth}
                                                    setSpecificDateMonth={setSpecificDateMonth}
                                                    specificDateDay={specificDateDay}
                                                    setSpecificDateDay={setSpecificDateDay}
                                                    nthWeek={nthWeek}
                                                    setNthWeek={setNthWeek}
                                                    weekDay={weekDay}
                                                    setWeekDay={setWeekDay}
                                                    nthWeekMonth={nthWeekMonth}
                                                    setNthWeekMonth={setNthWeekMonth}
                                                    nthWeekOfMonth={nthWeekOfMonth}
                                                    setNthWeekOfMonth={setNthWeekOfMonth}
                                                />
                                            </Grid>
                                            <p className={classes.validationSty} >{validationObj?.recurring}</p>
                                        </Grid>
                                    </>)}
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Required Attendees</p>
                                        <PaginatedEntityDropdown
                                            value={selectedRequiredAttendees}
                                            onChange={(value) => {
                                                if (value.length > 0 && value.length > selectedRequiredAttendees.length) {
                                                    let optionalIds = selectedOptionalAttendees.map((s) => s?.value)
                                                    if (!optionalIds.includes(value[value.length - 1]?.value)) {
                                                        setSelectedRequiredAttendees(value)
                                                    }
                                                } else {
                                                    setSelectedRequiredAttendees(value)
                                                }
                                            }}
                                            isMulti={true}
                                            palCreate={false}
                                            noFilter={true}
                                            financialRelationType={"Admin"}
                                            givenFilterOption={[
                                                {
                                                    option: "Network",
                                                    types: ["User"]
                                                }
                                            ]}
                                        />
                                        <p className={classes.validationSty} >{validationObj?.participants}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Optional Attendees<span className={classes.requiredSty} >*</span></p>
                                        <PaginatedEntityDropdown
                                            value={selectedOptionalAttendees}
                                            onChange={(value) => {
                                                if (value.length > 0 && value.length > selectedOptionalAttendees.length) {
                                                    let optionalIds = selectedRequiredAttendees.map((s) => s?.value)
                                                    if (!optionalIds.includes(value[value.length - 1]?.value)) {
                                                        setSelectedOptionalAttendees(value)
                                                    }
                                                } else {
                                                    setSelectedOptionalAttendees(value)
                                                }
                                            }}
                                            isMulti={true}
                                            palCreate={false}
                                            noFilter={true}
                                            financialRelationType={"Admin"}
                                            givenFilterOption={[
                                                {
                                                    option: "Network",
                                                    types: ["User"]
                                                }
                                            ]}
                                        />
                                        <p className={classes.validationSty} >{validationObj?.participants}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Project(s)</p>
                                        <ProjectPicker
                                            selectedProject={selectedProject}
                                            setSelectedProject={setSelectedProject}
                                            defaultProjectId={profileId}
                                            fullWidth={true}
                                        />
                                        <p className={classes.validationSty} >{validationObj?.participants}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Organization(s)</p>
                                        <OrgPicker
                                            selectedOrg={selectedOrg}
                                            setSelectedOrg={setSelectedOrg}
                                            defaultOrganizationProfileId={profileId}
                                            fullWidth={true}
                                        />
                                        <p className={classes.validationSty} >{validationObj?.participants}</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p className={classes.label} >Location</p>
                                        <div style={{ border: "1px solid gray", borderRadius: "5px", padding: "10px" }} >
                                            <GooglePlaceAutocomplete
                                                inputContStyle={classes.addressCont}
                                                autoWidth={"100%"}
                                                textWidth={"100%"}
                                                isGetLogLat={true}
                                                fullAddressLine={fullAddressLine}
                                                setFullAddressLine={setFullAddressLine}
                                                streetAddress={streetAddress}
                                                setStreetAddress={setStreetAddress}
                                                zip={zip}
                                                setZip={setZip}
                                                city={city}
                                                setCity={setCity}
                                                region={region}
                                                setRegion={setRegion}
                                                regionCode={regionCode}
                                                setRegionCode={setRegionCode}
                                                country={country}
                                                setCountry={setCountry}
                                                countryCode={countryCode}
                                                setCountryCode={setCountryCode}
                                                latitude={latitude}
                                                setLatitude={setLatitude}
                                                longitude={longitude}
                                                setLongitude={setLongitude}
                                                isShowCountry={true}
                                                isShowCityStreet={true}
                                                isStateZip={true}
                                                noAddressShow={false}
                                                noMap={false}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </div>
                        <div style={{ width: "100%", height: "5px" }} >
                            {loader && (<LinearProgress />)}
                        </div>
                        <div className={classes.bottomAct} >
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                                disabled={loader}
                                onClick={onSubmit}
                            >
                                Save & Close
                            </Button>
                        </div>
                    </form>
                )}
            </>}
        />
        <NotifyAlert
            alertObj={alertObj}
            setAlertObj={setAlertObj}
        />
    </>);
}