import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { getBlockAndUnitsByProject } from "../../api.call";
import { makeStyles } from "@material-ui/core/styles";
import FormBox from "../../../styled/generic/FormBox";

const useStyles = makeStyles((theme) => ({
  inputBox: {
    width: "100%",
    marginBottom: "15px",
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
    },
  },
  blockContainer: {
    padding: "12px",
    backgroundColor: theme.palette.grey[50],
    borderRadius: "8px",
    marginBottom: "8px",
    border: `1px solid ${theme.palette.grey[200]}`,
  },
}));

const formatFloorLabel = (floorNumber) => {
  if (floorNumber < 0) {
    return `Basement ${Math.abs(floorNumber)}`;
  }
  if (floorNumber == 0) {
    return `GroundFloor`;
  }
  return `Floor ${floorNumber}`;
};

const BlockUnitViewer = ({
  projectId,
  selectedBlocks,
  setSelectedBlocks,
  setSelectedUnits,
  selectedUnits = [],
}) => {
  const classes = useStyles();

  const [blocks, setBlocks] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState([]);

  const getBlocks = async () => {
    try {
      if (projectId && projectId !== "EMPTY") {
        setLoading(true);
        await getBlockAndUnitsByProject({ projectId })
          .then((data) => {
            setAvailableUnits(data?.rentalUnits || []);
            setBlocks(data?.projectBlocks || []);
          })
          .catch((error) => {
            console.log(error);
            setSelectedBlocks([]);
            setSelectedUnits([]);
            setBlocks([]);
            setUnits([]);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFloorChange = (blockId, newValue) => {
    const numericValues = newValue
      .map((val) => {
        const num = parseInt(val);
        return isNaN(num) ? null : num;
      })
      .filter((val) => val !== null);

    setSelectedFloor({
      ...selectedFloor,
      [blockId]: numericValues,
    });
  };

  useEffect(() => {
    if (projectId) {
      getBlocks();
    }
  }, [projectId]);

  useEffect(() => {
    if (availableUnits?.length > 0) {
      setUnits(
        availableUnits?.filter((unit) =>
          selectedFloor[unit?.projectBlock]?.includes(unit?.floor)
        )
      );
    }
  }, [availableUnits, selectedFloor, selectedBlocks]);

  useEffect(() => {
    if (selectedUnits?.length > 0 && availableUnits?.length > 0) {
      const floorsByBlock = selectedUnits.reduce((acc, unit) => {
        if (!acc[unit.projectBlock]) {
          acc[unit.projectBlock] = new Set();
        }
        acc[unit.projectBlock].add(unit.floor);
        return acc;
      }, {});
      const initialSelectedFloors = Object.keys(floorsByBlock).reduce(
        (acc, blockId) => {
          acc[blockId] = Array.from(floorsByBlock[blockId]);
          return acc;
        },
        {}
      );
      setSelectedFloor(initialSelectedFloors);
    }
  }, [selectedUnits, availableUnits]);

  // Add new function to get unique floor numbers for a block
  const getFloorOptionsForBlock = (blockId) => {
    const blockUnits = availableUnits.filter(
      (unit) => unit.projectBlock === blockId
    );
    const uniqueFloors = [...new Set(blockUnits.map((unit) => unit.floor))]
      .filter((floor) => floor !== undefined && floor !== null)
      .sort((a, b) => a - b);
    return uniqueFloors;
  };

  const handleBlockChange = (event, value) => {
    const currentSelectedBlocks = Array.isArray(selectedBlocks)
      ? selectedBlocks
      : [];

    const removedBlocks = currentSelectedBlocks.filter(
      (block) => !value.map((v) => v._id).includes(block._id)
    );

    if (removedBlocks.length > 0) {
      const updatedSelectedFloor = { ...selectedFloor };
      removedBlocks.forEach((block) => {
        delete updatedSelectedFloor[block._id];
      });
      setSelectedFloor(updatedSelectedFloor);

      const unitsArray = Array.isArray(selectedUnits) ? selectedUnits : [];
      const filteredUnits = unitsArray.filter(
        (unit) =>
          !removedBlocks.map((block) => block._id).includes(unit?.projectBlock)
      );
      setSelectedUnits(filteredUnits);
    }
    setSelectedBlocks(value);
  };

  return (
    <>
      {projectId ? (
        <div>
          <div className={classes.inputBox}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <p>Block(s)</p>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          blocks?.length > 0 &&
                          selectedBlocks?.length === blocks?.length
                        }
                        indeterminate={
                          selectedBlocks?.length > 0 &&
                          selectedBlocks?.length < blocks?.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedBlocks(blocks);
                          } else {
                            setSelectedBlocks([]);
                          }
                        }}
                        disabled={blocks?.length === 0}
                      />
                    }
                    label="Select All"
                    sx={{ "& .MuiTypography-root": { fontSize: "0.875rem" } }}
                  />
                </Box>
              </Box>
            </Box>
            <Autocomplete
              options={blocks?.filter(
                (block) =>
                  !selectedBlocks.map((sb) => sb._id).includes(block._id)
              )}
              value={selectedBlocks}
              onChange={handleBlockChange}
              fullWidth
              multiple={true}
              size="small"
              getOptionLabel={(option) => option?.name || ""}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  {...params}
                  placeholder={"Select block"}
                />
              )}
            />
          </div>

          {selectedBlocks?.length > 0 && (
            <div className={classes.inputBox}>
              <p>Unit(s)</p>
              {selectedBlocks.map((block) => (
                <div key={block._id} className={classes.blockContainer}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      mb: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: "text.secondary",
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        textTransform: "uppercase",
                        letterSpacing: "0.5px",
                      }}
                    >
                      {block.name}
                    </Typography>

                    <FormBox
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "0.75rem", fontWeight: 500 }}
                          >
                            Floors
                          </Typography>
                          <FormControlLabel
                            control={
                              <Checkbox
                                size="small"
                                checked={
                                  getFloorOptionsForBlock(block._id)?.length >
                                    0 &&
                                  selectedFloor[block._id]?.length ===
                                    getFloorOptionsForBlock(block._id)?.length
                                }
                                indeterminate={
                                  selectedFloor[block._id]?.length > 0 &&
                                  selectedFloor[block._id]?.length <
                                    getFloorOptionsForBlock(block._id)?.length
                                }
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    handleFloorChange(
                                      block._id,
                                      getFloorOptionsForBlock(block._id)
                                    );
                                  } else {
                                    handleFloorChange(block._id, []);
                                  }
                                }}
                                disabled={
                                  getFloorOptionsForBlock(block._id)?.length ===
                                  0
                                }
                              />
                            }
                            label="Select All"
                            sx={{
                              "& .MuiTypography-root": { fontSize: "0.875rem" },
                            }}
                          />
                        </Box>
                      }
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: "0.75rem",
                          fontWeight: 500,
                        },
                      }}
                    >
                      <Autocomplete
                        multiple
                        options={getFloorOptionsForBlock(block._id)}
                        value={selectedFloor[block._id] || []}
                        onChange={(event, newValue) => {
                          handleFloorChange(block._id, newValue);
                        }}
                        getOptionLabel={(option) => formatFloorLabel(option)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select floor numbers"
                            helperText="Select floors from available units"
                            size="small"
                            sx={{
                              "& .MuiInputBase-root": {
                                fontSize: "0.75rem",
                                padding: "2px 4px",
                              },
                              "& .MuiFormHelperText-root": {
                                fontSize: "0.625rem",
                                marginTop: "2px",
                              },
                            }}
                          />
                        )}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={index}
                              label={formatFloorLabel(option)}
                              {...getTagProps({ index })}
                              size="small"
                              sx={{
                                height: "20px",
                                "& .MuiChip-label": {
                                  fontSize: "0.75rem",
                                  padding: "0 6px",
                                },
                              }}
                            />
                          ))
                        }
                      />
                    </FormBox>

                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={
                                units?.filter(
                                  (unit) => unit.projectBlock === block._id
                                ).length > 0 &&
                                units
                                  ?.filter(
                                    (unit) => unit.projectBlock === block._id
                                  )
                                  .every((unit) =>
                                    selectedUnits.some(
                                      (u) => u._id === unit._id
                                    )
                                  )
                              }
                              indeterminate={
                                units
                                  ?.filter(
                                    (unit) => unit.projectBlock === block._id
                                  )
                                  .some((unit) =>
                                    selectedUnits.some(
                                      (u) => u._id === unit._id
                                    )
                                  ) &&
                                !units
                                  ?.filter(
                                    (unit) => unit.projectBlock === block._id
                                  )
                                  .every((unit) =>
                                    selectedUnits.some(
                                      (u) => u._id === unit._id
                                    )
                                  )
                              }
                              onChange={(e) => {
                                const blockUnits = units?.filter(
                                  (unit) => unit.projectBlock === block._id
                                );
                                if (e.target.checked) {
                                  setSelectedUnits([
                                    ...selectedUnits,
                                    ...blockUnits,
                                  ]);
                                } else {
                                  setSelectedUnits(
                                    selectedUnits?.filter(
                                      (unit) => unit?.projectBlock !== block._id
                                    )
                                  );
                                }
                              }}
                              disabled={
                                units?.filter(
                                  (unit) => unit.projectBlock === block._id
                                ).length === 0
                              }
                            />
                          }
                          label="Select All"
                          sx={{
                            "& .MuiTypography-root": { fontSize: "0.875rem" },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                      mb: 1.5,
                    }}
                  >
                    {units
                      ?.filter((unit) => unit.projectBlock === block._id)
                      .map((unit) => (
                        <Chip
                          key={unit._id}
                          label={unit.name}
                          size="small"
                          variant={
                            selectedUnits?.find((u) => u._id === unit._id)
                              ? "filled"
                              : "outlined"
                          }
                          color={
                            selectedUnits?.find((u) => u._id === unit._id)
                              ? "primary"
                              : "default"
                          }
                          onClick={() => {
                            if (
                              selectedUnits?.find((u) => u._id === unit._id)
                            ) {
                              setSelectedUnits(
                                selectedUnits?.filter((u) => u._id !== unit._id)
                              );
                            } else {
                              setSelectedUnits([...selectedUnits, unit]);
                            }
                          }}
                          sx={{
                            height: "24px",
                            borderRadius: "4px",
                            "& .MuiChip-label": {
                              fontSize: "0.75rem",
                              px: 1,
                            },
                            "&:hover": {
                              backgroundColor: selectedUnits.find(
                                (u) => u._id === unit._id
                              )
                                ? "primary.dark"
                                : "action.hover",
                            },
                          }}
                        />
                      ))}
                  </Box>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <p>Please select project first to link block(s) or unit(s)</p>
      )}
    </>
  );
};

export default BlockUnitViewer;
