import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PSLinkedDocuments from "./prospectsComponent/PSLinkedDocuments";
import PSLinkedIssues from "./prospectsComponent/PSLinkedIssues";
import PSLinkedNotes from "./prospectsComponent/PSLinkedNotes";
import PSLinkedEvents from "./prospectsComponent/PSLinkedEvents";

const useStyles = makeStyles((theme) => ({
    tabMainCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        padding: "10px"
    }
}));

export default function ProspectDataAnalysis({
 contactData
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [curOption, setCurOption] = useState(0)

    return (
        <>
            <div>
                <Tabs
                    value={curOption}
                    onChange={(event, newValue) => { setCurOption(newValue); }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    <Tab label="Events" />
                    <Tab label="Notes" />
                    <Tab label="Tickets" />
                    <Tab label="Documents" />
                </Tabs>
            </div>
            <Paper elevation={2} className={classes.tabMainCont}  >
                {curOption === 0 && (
                    <PSLinkedEvents
                        finrelId={contactData?._id}
                    />
                )}
                {curOption === 1 && (
                    <PSLinkedNotes
                        finrelId={contactData?._id}
                    />
                )}
                {curOption === 2 && (
                    <PSLinkedIssues
                        finrelId={contactData?._id}
                    />
                )}
                {curOption === 3 && (
                    <PSLinkedDocuments
                        finrelId={contactData?._id}
                    />
                )}
            </Paper>
        </>
    );
}