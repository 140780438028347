import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import MinusSquare from "../OfferingsAndProducts/icons/MinusSquare";
import PlusSquare from "../OfferingsAndProducts/icons/PlusSquare";
import CloseSquare from "../OfferingsAndProducts/icons/CloseSquare";
import { Typography, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  },
  treeItem: {
    padding: "2px",
    borderRadius: 4,
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  projectNode: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: "0.95rem",
  },
  blockNode: {
    color: theme.palette.warning.dark,
    fontWeight: 500,
    fontSize: "0.9rem",
  },
  floorNode: {
    color: theme.palette.info.main,
    fontWeight: 500,
    fontSize: "0.9rem",
  },
  unitNode: {
    color: theme.palette.success.main,
    fontWeight: 500,
    fontSize: "0.9rem",
  },
  categoryNode: {
    color: theme.palette.text.secondary,
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "0.85rem",
  },
}));

const ProjectStructureTree = ({
  data,
  onNodeSelect,
  hierarchicalCounts,
  selectedStructures = [],
  setSelectedStructures,
}) => {
  const classes = useStyles();
  const structureCategories = data?.structureCategories;

  const [expanded, setExpanded] = useState([]);

  const handleNodeToggle = (event, nodeIds) => {
    let newExpanded = nodeIds;

    if (nodeIds.length > expanded.length) {
      const newNode = nodeIds.find((n) => !expanded.includes(n));
      if (newNode) {
        const newNodeType = newNode.split("-")[0];
        newExpanded = nodeIds.filter((n) => {
          const nodeType = n.split("-")[0];
          if (nodeType === newNodeType && n !== newNode) {
            return false;
          }
          return true;
        });
      }
    }

    const finalExpanded = newExpanded.filter((node) => {
      if (node.startsWith("floor-") && !node.startsWith("floor-category-")) {
        const parts = node.split("-");
        const expectedParent = `block-${parts[1]}`;
        if (!newExpanded.includes(expectedParent)) return false;
      }
      if (node.startsWith("block-category-")) {
        const parts = node.split("-");
        const expectedParent = `block-${parts[1]}`;
        if (!newExpanded.includes(expectedParent)) return false;
      }
      if (node.startsWith("floor-category-")) {
        const parts = node.split("-");
        const expectedParent = `floor-${parts[1]}-${parts[2]}`;
        if (!newExpanded.includes(expectedParent)) return false;
      }
      if (node.startsWith("unit-category-")) {
        const parts = node.split("-");
        const expectedParent = `unit-${parts[1]}`;
        if (!newExpanded.includes(expectedParent)) return false;
      }
      if (node.startsWith("unit-") && !node.startsWith("unit-category-")) {
        const unitId = node.replace("unit-", "");
        const unitObj = data?.rentalUnits?.find((u) => u?._id === unitId);
        if (unitObj) {
          const expectedFloor = `floor-${unitObj.projectBlock}-${String(
            unitObj.floor
          )}`;
          const expectedBlock = `block-${unitObj.projectBlock}`;
          if (
            !newExpanded.includes(expectedBlock) ||
            !newExpanded.includes(expectedFloor)
          ) {
            return false;
          }
        }
      }
      return true;
    });

    setExpanded(finalExpanded);

    const filters = {
      projects: [],
      blocks: [],
      floors: [],
      units: [],
    };

    finalExpanded.forEach((nodeId) => {
      if (nodeId.startsWith("project-")) {
        filters.projects.push(nodeId.replace("project-", ""));
      } else if (
        nodeId.startsWith("block-") &&
        !nodeId.startsWith("block-category-")
      ) {
        const block = data?.projectBlocks?.find(
          (b) => b?._id === nodeId.replace("block-", "")
        );
        if (block) {
          filters.blocks.push(block);
        }
      } else if (
        nodeId.startsWith("floor-") &&
        !nodeId.startsWith("floor-category-")
      ) {
        const parts = nodeId.split("-");
        filters.floors.push(parseInt(parts[2]));
      } else if (
        nodeId.startsWith("unit-") &&
        !nodeId.startsWith("unit-category-")
      ) {
        const unit = data?.rentalUnits?.find(
          (u) => u?._id === nodeId.replace("unit-", "")
        );
        if (unit) {
          filters.units.push(unit);
        }
      }
    });

    if (onNodeSelect) {
      onNodeSelect(filters);
    }
  };

  const handleStructureSelect = (structure) => {
    const isSelected = selectedStructures.some((s) => s._id === structure._id);
    let newSelectedStructures;
    if (isSelected) {
      newSelectedStructures = selectedStructures.filter(
        (s) => s._id !== structure._id
      );
    } else {
      newSelectedStructures = [...selectedStructures, structure];
    }
    setSelectedStructures(newSelectedStructures);
  };

  const renderUnits = (units, blockId) => {
    return units.map((unit) => (
      <TreeItem
        key={unit._id}
        nodeId={`unit-${unit._id}`}
        classes={{ root: classes.treeItem }}
        label={
          <Typography variant="body2" className={classes.unitNode}>
            {unit.name}{" "}
            <span style={{ color: "red" }}>
              ({hierarchicalCounts?.units[unit?._id] || 0})
            </span>
          </Typography>
        }
      >
        {structureCategories
          .filter((cat) => cat.structureType === "Unit")
          .map((category) => (
            <TreeItem
              key={`${unit._id}-${category._id}`}
              nodeId={`unit-category-${unit._id}-${category._id}`}
              classes={{ root: classes.treeItem }}
              label={
                <Typography variant="body2" className={classes.categoryNode}>
                  {category.name}{" "}
                  <span style={{ color: "red" }}>
                    (
                    {hierarchicalCounts?.unitStructures[
                      `${unit._id}_${category._id}`
                    ] || 0}
                    )
                  </span>
                </Typography>
              }
              style={{
                backgroundColor: selectedStructures.some(
                  (s) => s._id === category._id
                )
                  ? "#fff3e0"
                  : "transparent",
              }}
              onClick={() => handleStructureSelect(category)}
            />
          ))}
      </TreeItem>
    ));
  };

  const renderFloors = (block) => {
    // Group units by floor number
    const floorUnits = block?.rentalUnits?.reduce((acc, unit) => {
      const floorNum = unit?.floor || 0;
      if (!acc[floorNum]) acc[floorNum] = [];
      acc[floorNum].push(unit);
      return acc;
    }, {});

    return Object.entries(floorUnits || {})
      .sort(([a], [b]) => Number(a) - Number(b))
      .map(([floorNum, units]) => (
        <TreeItem
          key={`floor-${block._id}-${floorNum}`}
          nodeId={`floor-${block._id}-${floorNum}`}
          classes={{ root: classes.treeItem }}
          label={
            <Typography variant="body2" className={classes.floorNode}>
              {floorNum < 0
                ? `Basement ${Math.abs(floorNum)}`
                : floorNum === "0"
                ? "Ground Floor"
                : `Floor ${floorNum}`}{" "}
              <span style={{ color: "red" }}>
                ({hierarchicalCounts?.floors[`${block?._id}_${floorNum}`] || 0})
              </span>
            </Typography>
          }
        >
          {structureCategories
            .filter((cat) => cat.structureType === "Floor")
            .map((category) => (
              <TreeItem
                key={`floor-category-${block._id}-${floorNum}-${category._id}`}
                nodeId={`floor-category-${block._id}-${floorNum}-${category._id}`}
                classes={{ root: classes.treeItem }}
                label={
                  <Typography variant="body2" className={classes.categoryNode}>
                    {category.name}{" "}
                    <span style={{ color: "red" }}>
                      (
                      {hierarchicalCounts?.floorStructures[
                        `${block._id}_${floorNum}_${category._id}`
                      ] || 0}
                      )
                    </span>
                  </Typography>
                }
                style={{
                  backgroundColor: selectedStructures.some(
                    (s) => s._id === category._id
                  )
                    ? "#fff3e0"
                    : "transparent",
                }}
                onClick={() => handleStructureSelect(category)}
              />
            ))}
          {renderUnits(units, block._id)}
        </TreeItem>
      ));
  };

  const renderBlocks = (blocks) => {
    blocks.forEach((block) => {
      block.rentalUnits = data.rentalUnits.filter(
        (unit) => unit?.projectBlock === block?._id
      );
    });

    return blocks.map((block) => (
      <TreeItem
        key={block._id}
        nodeId={`block-${block._id}`}
        classes={{ root: classes.treeItem }}
        label={
          <Typography variant="body2" className={classes.blockNode}>
            {block.name}{" "}
            <span style={{ color: "red" }}>
              ({hierarchicalCounts?.blocks[block?._id] || 0})
            </span>
          </Typography>
        }
      >
        {structureCategories
          .filter((cat) => cat.structureType === "Block")
          .map((category) => (
            <TreeItem
              key={`block-category-${block._id}-${category._id}`}
              nodeId={`block-category-${block._id}-${category._id}`}
              classes={{ root: classes.treeItem }}
              label={
                <Typography variant="body2" className={classes.categoryNode}>
                  {category.name}{" "}
                  <span style={{ color: "red" }}>
                    (
                    {hierarchicalCounts?.blockStructures[
                      `${block._id}_${category._id}`
                    ] || 0}
                    )
                  </span>
                </Typography>
              }
              style={{
                backgroundColor: selectedStructures.some(
                  (s) => s._id === category._id
                )
                  ? "#fff3e0"
                  : "transparent",
              }}
              onClick={() => handleStructureSelect(category)}
            />
          ))}
        {renderFloors(block)}
      </TreeItem>
    ));
  };

  return (
    <div className={classes.root}>
      <TreeView
        defaultCollapseIcon={<MinusSquare />}
        defaultExpandIcon={<PlusSquare />}
        defaultEndIcon={<CloseSquare />}
        expanded={expanded}
        onNodeToggle={handleNodeToggle}
        multiSelect={false}
      >
        {data?.projects?.map((project) => (
          <TreeItem
            key={project?._id}
            nodeId={`project-${project?._id}`}
            classes={{ root: classes.treeItem }}
            label={
              <Typography variant="body1" className={classes.projectNode}>
                {project?.displayName}{" "}
                <span style={{ color: "red" }}>
                  ({hierarchicalCounts?.projects[project?._id] || 0})
                </span>
              </Typography>
            }
          >
            {structureCategories
              .filter((cat) => cat.structureType === "Project")
              .map((category) => (
                <TreeItem
                  key={`project-category-${project?._id}-${category?._id}`}
                  nodeId={`project-category-${project?._id}-${category?._id}`}
                  classes={{ root: classes.treeItem }}
                  label={
                    <Typography
                      variant="body2"
                      className={classes.categoryNode}
                    >
                      {category?.name}{" "}
                      <span style={{ color: "red" }}>
                        (
                        {hierarchicalCounts?.projectStructures[
                          `${project?._id}_${category?._id}`
                        ] || 0}
                        )
                      </span>
                    </Typography>
                  }
                  style={{
                    backgroundColor: selectedStructures.some(
                      (s) => s._id === category._id
                    )
                      ? "#fff3e0"
                      : "transparent",
                  }}
                  onClick={() => handleStructureSelect(category)}
                />
              ))}
            {renderBlocks(
              data?.projectBlocks?.filter(
                (block) => block?.project === project?._id
              )
            )}
          </TreeItem>
        ))}
      </TreeView>
    </div>
  );
};

export default ProjectStructureTree;
