import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import { getCategoriesByLibrary } from "./Api.call";
import CategoryCreateDrawer from "./Category.Crearte.Drawer";
import HierarchicalAutocomplete from "../styled/CommonComponents/Hierarchical.Autocomplete";

const useStyles = makeStyles((theme) => ({}));
export default function CategoryAutocomplete({
  libraryId,
  isMultiple = false,
  selectedCategories,
  setSelectedCategories,
  type = "Common",
  label,
  placeholder,
  isCreateNew,
  profileId
}) {
  selectedCategories = selectedCategories ?? (isMultiple ? [] : null);
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [allCategories, setAllCategories] = useState([]);
  const [createCategoryDawer, setCreateCategoryDrawer] = useState(false);

  async function getCategoriesByLibraryApiCall() {
    await getCategoriesByLibrary({
      librayId: libraryId,
      type: type,
    })
      .then((data) => {
        
        if (isCreateNew) {

          const filteredData = Array.isArray(data) ? data.filter(item => item && item?._id) : [];
          const obj = {
            _id: "new",
            name: "+ Add New",
            parent: null,
            depth: 0,
            numChildren: 0,
          };
          setAllCategories([obj, ...filteredData]);
        } else {
          const filteredData = Array.isArray(data) ? data.filter(item => item && item?._id) : [];
          setAllCategories(filteredData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if(libraryId){
      getCategoriesByLibraryApiCall();
    }
  }, [libraryId]);



  return (
    <>
      {allCategories?.length > 0 && (
        <HierarchicalAutocomplete
          lableKey={"name"}
          options={allCategories || []}
          value={selectedCategories || (isMultiple ? [] : null)}
          setValue={setSelectedCategories}
          rootId={"_id"}
          parentId={"parent"}
          isMultiple={isMultiple}
          label={label}
          onChange={(e, v) => {

            if (v && v?.length > 0 && v[v?.length - 1]?._id === "new") {
              setCreateCategoryDrawer(true);
            } else {
              if (v?._id === "new") {
                setCreateCategoryDrawer(true);
              } else {
                setSelectedCategories(v);
              }
            }
          }}
          placeholder={placeholder}
        />
      )}



      <CategoryCreateDrawer
        createCategoryDawer={createCategoryDawer}
        setCreateCategoryDrawer={setCreateCategoryDrawer}
        profileId={profileId}
        categoryType={type}
        afterCategoryCreateOrEdit={(value) => {
          if (value) {
            setAllCategories([...allCategories, value]);
            if (isMultiple) {
              setSelectedCategories([...selectedCategories, value]);
            } else {
              setSelectedCategories(value);
            }
            setCreateCategoryDrawer(false);
          }
        }}
        libraryId={libraryId}
      />
    </>
  );
}
