import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Box, Grid, Pagination } from "@mui/material";
import { TextField, InputAdornment, ClickAwayListener } from "@material-ui/core";
import { useDebounce } from "react-use";
import TuneIcon from '@material-ui/icons/Tune';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from "@material-ui/core/LinearProgress";
import { FaInbox } from "react-icons/fa";
import CRMCard from "../../issue/issue.crm.card";
import SimpleCard from "../../issue/issue.simple.card";
import { getFilterOptionsForIssuesByLead, getPaginatedByLeadTickets } from "../api.call";
import "../../../App.css";

const useStyles = makeStyles((theme) => ({
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #d2d1d1",
    },
    sectionTop: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "17px",
            fontWeight: "500",
            color: "#193B56"
        }
    },
    progressCont: {
        width: "100%",
        height: "5px"
    },
    filterCont: {
        display: "block",
        position: "absolute",
        width: "500px",
        top: "45px",
        right: "0px",
        padding: "15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    hideFilterCont: {
        display: "none",
        position: "absolute",
        width: "500px",
        top: "45px",
        right: "0px",
        padding: "15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    emptyCont: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "50px",
        "& p": {
            fontSize: "16px",
            fontWeight: "500",
            color: "gray"
        }
    },
    cardsCont: {
        padding: "10px 15px 15px",
        width: "100%",
        height: "calc(100% - 105px)",
        overflowY: "auto"
    }
}));

const PSLinkedIssues = ({ finrelId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [filterObj, setFilterObj] = useState({
        searchStr: "",
        template: null,
        projects: [],
        labels: [],
        assignes: [],
        statuses: []
    })
    const [filterOptions, setFilterOptions] = useState({
        projects: [],
        labels: [],
        assignes: [],
        statuses: []
    })
    const [issues, setIssues] = useState([])
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [changeState, setChangeState] = useState()
    const [totalPages, setTotalPages] = useState(0)
    const [totalResultsCount, setTotalResultsCount] = useState(0)
    const [showFilter, setShowFilter] = useState(false)
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        if (finrelId) {
            getFilterOptionsForIssuesByLead({ finrelId })
                .then((data) => {
                    console.log(data)
                    setFilterOptions(data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [finrelId])

    const getTickets = async () => {
        setLoader(true)
        await getPaginatedByLeadTickets({
            finrelId: finrelId,
            filterObj,
            curPage: curPage - 1,
            limit: pageLimit
        })
            .then((data) => {
                console.log(data)
                setIssues(data?.data || [])
                setTotalResultsCount(data?.count || 0)
                let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                setTotalPages(locTotalPage)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoader(false)
            })
    }

    useDebounce(() => {
        getTickets()
    }, 1000, [curPage, finrelId, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [filterObj, finrelId])

    return (
        <>
            <div className={classes.sectionTop} >
                <h3></h3>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative"
                    }}
                >
                    <ClickAwayListener
                        onClickAway={() => {
                            setShowFilter(false)
                        }}
                    >
                        <div>
                            <TextField
                                variant="outlined"
                                size="small"
                                placeholder="Search..."
                                value={filterObj?.searchStr}
                                style={{ width: "300px", marginRight: "10px" }}
                                onChange={(e) => {
                                    setFilterObj({
                                        ...filterObj,
                                        searchStr: e.target.value
                                    })
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setShowFilter(true)
                                            }}
                                        >
                                            <TuneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className={showFilter ? classes.filterCont : classes.hideFilterCont} >
                                <Grid container spacing={2} gridColumn={12}>
                                    <Grid item xs={12} >
                                        <Autocomplete
                                            value={filterObj?.template}
                                            options={filterOptions?.templates || []}
                                            getOptionLabel={(option) => { return option?.title || "" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Template"
                                                    placeholder="Select template"
                                                />
                                            )}
                                            onChange={(e, value) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    template: value,
                                                    statuses: []
                                                })
                                            }}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Autocomplete
                                            value={filterObj?.projects}
                                            options={filterOptions?.projects || []}
                                            getOptionLabel={(option) => { return option?.displayName || "" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Project(s)"
                                                    placeholder="Select project(s)"
                                                />
                                            )}
                                            multiple={true}
                                            onChange={(e, value) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    projects: value
                                                })
                                            }}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Autocomplete
                                            value={filterObj?.labels}
                                            options={filterOptions?.labels || []}
                                            getOptionLabel={(option) => { return option?.name || "" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Label(s)"
                                                    placeholder="Select label(s)"
                                                />
                                            )}
                                            multiple={true}
                                            onChange={(e, value) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    labels: value
                                                })
                                            }}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Autocomplete
                                            value={filterObj?.assignes}
                                            options={filterOptions?.assignes || []}
                                            getOptionLabel={(option) => { return option?.parent?.displayName || "" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="assigne(s)"
                                                    placeholder="Select assigne(s)"
                                                />
                                            )}
                                            multiple={true}
                                            onChange={(e, value) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    assignes: value
                                                })
                                            }}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Autocomplete
                                            value={filterObj?.statuses}
                                            options={filterObj?.template?._id ? filterOptions?.statuses.filter((s) => s?.template == filterObj?.template?._id) : []}
                                            getOptionLabel={(option) => { return option?.text || "" }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="status(s)"
                                                    placeholder="Select status(s)"
                                                />
                                            )}
                                            disabled={filterObj?.template?._id ? false : true}
                                            multiple={true}
                                            onChange={(e, value) => {
                                                setFilterObj({
                                                    ...filterObj,
                                                    statuses: value
                                                })
                                            }}
                                            fullWidth
                                            size="small"
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
            <div className={classes.progressCont} >
                {loader && (<LinearProgress />)}
            </div>
            {(issues && issues.length > 0) ? (<>
                <div className={classes.cardsCont} >
                    <Grid container spacing={1}>
                        {issues.map((issue) => {
                            if (!issue?.template?._id) return null;
                            const isCRM = issue.template?.isCRM;
                            let IssueCard = isCRM ? CRMCard : SimpleCard;
                            return (
                                <IssueCard
                                    key={issue._id}
                                    issue={issue}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    onIssueDelete={(deletedIssueId) => {
                                        setChangeState(!changeState)
                                    }}
                                />
                            );
                        })}
                    </Grid>
                </div>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
            </>) : (<div className={classes.emptyCont} >
                <FaInbox style={{ fontSize: "80px", color: "gray" }} />
                <p>No issues is available!</p>
            </div>)}
        </>
    );
};

export default PSLinkedIssues;