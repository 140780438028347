import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Organizations from "../organization/organizations";
import { Button, Snackbar, useMediaQuery } from "@material-ui/core";
import { useTheme } from "styled-components";
import Paper from "@material-ui/core/Paper";
import AnalysisCardSvg from "../../Assets/analysisCard.svg";
import ProjectManagementCardSvg from "../../Assets/proj_mgmt_logo.svg";
import DocumentCardSvg from "../../Assets/Doc_logo.svg";
import AccountingCardSvg from "../../Assets/accounting_logo.svg";
import propertyManagement from "../../Assets/propertyManagement.svg";
import { Alert } from "@material-ui/lab";
import Api from "../../helpers/Api";
import AnalysisCard from "./cards/AnalysisCard";
import DocumentCard from "./cards/DocumentCard";
import CalendarCard from "./cards/CalendarCard";
import PaymentsCard from "./cards/PaymentsCard";
import ProjectManagementCard from "./cards/ProjectManagementCard";
import PropertyManagementCard from "./cards/PropertyManagementCard";
import ContactsCard from "./cards/ContactsCard";
import EstimationAnalyticsCard from "./cards/EstimationAnalyticsCard";
import PermitAndApprovalCard from "./cards/PermitAndApprovalCard";
import useWorkspace from "../useWorkspace";
import OfferingsAndProductsCard from "./cards/OfferingsAndProducts.Card";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import SpaceBetween from "../styled/generic/SpaceBetween";
import FormBox from "../styled/generic/FormBox";
import WorkspaceApp from "../styled/generic/WorkspaceApp";
import { Add, Sort, TuneOutlined } from "@material-ui/icons";
import StandardContainer from "../styled/generic/StandardContainer";
import DashboardInvitations from "./Dashboard.Invitations";
import OrgTable from "../organization/OrgTable";
import LabelWithSingleActionButton from "../styled/generic/LabelWithSingleActionButton";
import LabelWithSingleActionIconButton from "../styled/generic/LabelWithSingleActionIconButton";
import CreateOrgDialog from "../organization/create.org.dialog";
import ProjectContacts from "../projects/project.contacts";
import TabsWithCommonAction from "../styled/generic/TabsWithCommonAction";
import TasksView from "./activities/TasksView";
import BugsView from "./activities/BugsView";
import ActivitiesList from "../activity/ActivitiesList";
import configObject from '../../config/index';
import { setAuthCookieData } from '../../helpers/cookie.helper';

const useStyles = makeStyles((theme) => ({
  mainContForDesktop: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
    },
  },
  left_container: {
    width: "68%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  left_top: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    borderRadius: "10px",
    padding: "0px 0px 10px",
    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  left_top_card: {
    width: "18%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "49%",
    },
  },
  left_top_img: {
    width: "55%",
    height: "auto",
  },
  left_top_header: {
    color: "#00345D",
    fontWeight: "500",
    textAlign: "center",
  },
  left_cards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  singleCardCont: {
    width: "49%",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  right_container: {
    width: "30%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  orgInvtCont: {
    marginBottom: "20px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    borderRadius: "7px",
    justifyContent: "center",
  },
  contactCont: {
    borderRadius: "7px",
    marginTop: "10px",
    "& .headTitle": {
      fontSize: "20px",
      fontWeight: "500",
      padding: "15px 0px 0px 15px"
    }
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector((state) => state.auth);
  let { invitations } = useSelector((state) => state.team);

  const [showCreateOrgDialog, setShowCreateOrgDialog] = useState(false);
  const [openSignSucess, setOpenSignSuccess] = useState(false);
  const [docs, setDocs] = useState([]);
  const [issues, setIssues] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [chartAccounts, setChartAccounts] = useState([]);
  const [reports, setReports] = useState([]);
  const [projectPortfolios, setProjectPortfolios] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Tasks");

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isExMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const onCreate = (newTeam) => {
    let locRows = [newTeam, ...rows];
    let locFilteredRows = [newTeam, ...filteredRows];
    setRows(locRows);
    setFilteredRows(locFilteredRows);
    setShowCreateOrgDialog(false);
  };

  const handleCloseSignSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSignSuccess(false);
  };

  const getData = async () => {
    setLoader(true);
    const res = await Api.post("profile/getData", {
      userId: user?._id,
      profileId: user?.profile,
      walletId: user?.wallet,
      isAdmin: true,
    });
    if (res?.data) {
      const data = res.data;
      setProjectPortfolios(data?.projectPortfolios || []);
      setDocs(data?.docs || []);
      setIssues(data?.issues || []);
      setReports(data?.analysisReports || []);
      setAppointments(data?.appointments || []);
      setChartAccounts(data?.chartAccounts || []);
      setTransactions(data?.transactions || []);
    }
    setLoader(false);
  };

  useEffect(() => {
    getData();
  }, [invitations]);

  const { setId, setShowWorkspaceConfigDialog, workspaceConfigDialog, config } =
    useWorkspace({
      type: "User",
      title: "User Configuration",
      configTitle: "Select user apps",
    });

  useEffect(() => {
    // User id
    if (user) {
      setId(user._id);
    }
  }, [user]);

  const entireState = useSelector(state => state);

  const getSubdomain = (code) => {
    switch (code) {
      case "ticketing":
      case "teams": return "teams";
      case "booking": return "bookings";
      case "planning": return "planning";
      case "bugs": return "bugs";
      case "accounts": return "accounts";
      case "documents": return "docs";
      case "events": return "calendar";
      case "portfoliomanagement": return "app";
      case "sitereport": return "sitereport";
      case "assets": return "assets";
      case "approval": return "approval";
      case "mywork": return "mywork";
      case "meeting": return "app";
      default: return "app";
    }
  };

  const handleAppClick = (code) => {
    let profileId = user?.profile;

    if (configObject.mode === 'prod') {
      const token = localStorage.getItem("token");
      if (entireState && typeof entireState === 'object') {
        setAuthCookieData({
          user, token, entireState
        });
      } else {
        console.warn("Skipped state persistence due to invalid state");
      }
    }

    let path;

    switch (code) {
      case "assets":
        path = `/assets/home/${profileId}`;
        break;
      case "approval":
        path = `/approval/${profileId}`;
        break;
      case "ticketing":
      case "teams":
        path = `/issues/profile/${profileId}`;
        break;
      case "accounts":
        path = `/finance/${user?.wallet}/bank-accounts`;
        break;
      case "documents":
        path = `/docs/${profileId}`;
        break;
      case "events":
        path = `/calendar/${profileId}?mode=open`;
        break;
      case "portfoliomanagement":
        path = `/projectportfolio/${profileId}`;
        break;
      case "sitereport":
        path = `/site-report/${profileId}`;
        break;
      case "meeting":
        path = `/meeting/${profileId}`;
        break;
      case "mywork":
        path = `/my-work/${profileId}`;
        break;
      case "planning":
        path = `/planning/${profileId}`;
        break;
      case "bugs":
        path = `/bugs/${profileId}`;
        break;
      case "booking":
        path = `/booking/${profileId}`;
        break;
      default:
        alert("No action found for this type of app");
        return;
    }

    navigateTo(code, path);
  };

  const navigateTo = (code, path) => {
    console.log(configObject.mode,' is the config mode ', configObject.mode === 'prod', ' is check ', path)

    if (configObject.mode === 'prod') {
      const subdomain = getSubdomain(code);
      if (subdomain === "app") {
        history.push(path);
      } else {
        const url = `https://${subdomain}.reallist.ai${path}`;
        window.open(url, "_self");
      }
    } else {
      history.push(path);
    }
  };

  const { auth, team: teamReducer } = useSelector((state) => state);
  const { teamIds, teamDictionary } = teamReducer;

  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const getDataFromReducer = () => {
    var tempRows = [];
    teamIds.map((teamId) => {
      var value = teamDictionary[teamId];
      if (value?.parentModelName === "Organization" && value?.parent) {
        tempRows.push(value);
      }
    });
    setRows(tempRows);
    setFilteredRows(tempRows);
  };

  useEffect(() => {
    getDataFromReducer();
  }, [teamDictionary, teamIds]);

  const addCreatedOne = (newOrg) => {
    let updatedOrgs = rows || [];
    updatedOrgs.push(newOrg);
    setRows(updatedOrgs);
    setFilteredRows(updatedOrgs);
  };

  return (
    <StandardContainer>
      <Grid container columnSpacing={2}>

      <Grid item xs={4}>
          <StandardAppContainerRounded>
            <LabelWithSingleActionButton
              label="Organization"
              actionButtonText="Add"
              actionButtonIcon={<Add />}
              actionButtonVariant="text"
              actionButtonListener={() => setShowCreateOrgDialog(true)}
            />
            <OrgTable rows={filteredRows} />

            <CreateOrgDialog
              open={showCreateOrgDialog}
              setOpen={setShowCreateOrgDialog}
              onCreate={onCreate}
            />
          </StandardAppContainerRounded>
          <div style={{ height: "10px" }} ></div>
          <DashboardInvitations addCreatedOne={addCreatedOne} />

          <Paper className={classes.contactCont} >
            <h3 className="headTitle" >Contacts</h3>
            <ProjectContacts
              team={{
                parent: {
                  _id: user?._id,
                  profile: {
                    _id: user?.profile,
                  },
                },
                parentModelName: "User",
              }}
              addSmallBtn={true}
              givenIsMdSmall={true}
              profileId={user?.profile}
              userRole={"Owner"}
              wallet={user?.wallet}
              parent={user?._id}
              parentModelName={"User"}
            />
          </Paper>
        </Grid>

        <Grid item xs={8}>
          <StandardAppContainerRounded>
            <LabelWithSingleActionButton
              label="My Apps"
              caption="Manage your workspace apps"
              actionButtonText="Apps"
              actionButtonIcon={<Add />}
              onClick={() => setShowWorkspaceConfigDialog(true)}
            />

            <Grid container spacing={2}>
              {config?.apps?.map((item) => (
                <Grid item xs={3}>
                  <WorkspaceApp
                    isSelectable={false}
                    onClick={() => handleAppClick(item.code)}
                    appData={item}
                  />
                </Grid>
              ))}
            </Grid>
          </StandardAppContainerRounded>

          <StandardAppContainerRounded>
            <FormBox label="Activities">
              <ActivitiesList
                  modules={[]}
                  profileId={user?.profile}
                  entityType="User"
                />
            </FormBox>
          </StandardAppContainerRounded>

          {/* <Grid container spacing={2}>
            <Grid item xs={4}>
              <ContactsCard
                profileId={user?.profile}
                wallet={user?.wallet}
                parent={user?._id}
                parentModelName={"User"}
              />
            </Grid>
            <Grid item xs={8}>
              <Organizations />
              <DashboardInvitations addCreatedOne={addCreatedOne} />
            </Grid>
              </Grid> */}
        </Grid>

      </Grid>

      {workspaceConfigDialog}
    </StandardContainer>
  );
};
export default Dashboard;
