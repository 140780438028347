import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { Button, TextField, InputAdornment, ClickAwayListener, Grid } from "@material-ui/core";
import NormalDrawer from "../NormalDrawer";
import TuneIcon from '@material-ui/icons/Tune';
import { getPaginatedOptimizedWarehouse } from "./api.call";
import { useDebounce } from "react-use";
import { useSelector, useDispatch } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import WarehouseCard from "./WarehouseCard";

const useStyles = makeStyles((theme) => ({
    drawerContent: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 155px)",
        padding: "20px",
        overflowY: "auto"
    },
    searchHeader: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #d2d1d1",
    },
    filterCont: {
        display: "block",
        position: "absolute",
        width: "500px",
        top: "45px",
        left: "35",
        padding: "15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    hideFilterCont: {
        display: "none",
        position: "absolute",
        width: "500px",
        top: "45px",
        left: "35",
        padding: "15px",
        backgroundColor: "white",
        borderRadius: "5px",
        zIndex: "999",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        transition: "all 0.3s",
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #d2d1d1",
    },
}));

const WarehouseSelectDrawer = ({
    profileId, openWarehouseSelect, setOpenWarehouseSelect, 
    linkedWarehouse, onSave
}) => {
    const classes = useStyles();

    const { teamDictionary, teamIds } = useSelector((state) => state.team);

    const [filterObj, setFilterObj] = useState({
        searchQuery: ""
    })
    const [loader, setLoader] = useState(true)
    const [showFilter, setShowFilter] = useState(false)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [totalPages, setTotalPages] = useState(1)
    const [options, setOptions] = useState([])
    const [changeState, setChangeState] = useState(false)
    const [curSelectedWarehouse, setCurSelectedWarehouse] = useState(null)

    useEffect(() => {
        if (openWarehouseSelect) {
            setCurSelectedWarehouse(linkedWarehouse)
        }
    }, [linkedWarehouse, openWarehouseSelect])

    const getBlocks = async () => {
        try {
            let locProjectId = null;
            teamIds && teamIds.length > 0 && teamIds.map((teamId) => {
                const teamData = teamDictionary[teamId];
                if (teamData?.parent?.profile?._id == profileId) {
                    locProjectId = teamData?.parent?._id
                } else if (teamData?.parent?.profile == profileId) {
                    locProjectId = teamData?.parent?._id
                }
            })
            setLoader(true)
            await getPaginatedOptimizedWarehouse({
                projectId: locProjectId,
                searchStr: filterObj?.searchQuery,
                curPage: curPage - 1,
                limit: pageLimit
            })
                .then((data) => {
                    setOptions(data?.data || [])
                    let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                    setTotalPages(locTotalPage)
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setLoader(false)
                })
        } catch (err) {
            console.log(err)
        }
    }

    useDebounce(() => {
        if (profileId && openWarehouseSelect) {
            getBlocks()
        }
    }, 1000, [curPage, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [filterObj, profileId, openWarehouseSelect])

    return (
        <NormalDrawer
            openDrawer={openWarehouseSelect}
            setOpenDrawer={setOpenWarehouseSelect}
            anchor={"right"}
            width={"800px"}
            title={"Select Warehouse(s)"}
            content={<div className={classes.drawerContent}>
                <div style={{ width: "100%", height: "5px" }} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.searchHeader} >
                    <ClickAwayListener
                        onClickAway={() => {
                            setShowFilter(false)
                        }}
                    >
                        <div style={{ width: "95%" }} >
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                placeholder="Search..."
                                value={filterObj.searchQuery}
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                    setFilterObj({
                                        ...filterObj,
                                        searchQuery: e.target.value
                                    })
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setShowFilter(!showFilter)
                                            }}
                                        >
                                            <TuneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {/* <div className={showFilter ? classes.filterCont : classes.hideFilterCont} >

                            </div> */}
                        </div>
                    </ClickAwayListener>
                </div>
                <div className={classes.bodyCont} >
                    <Grid container spacing={2}>
                        {options.map((cardData) => (
                            <Grid item xs={6} key={cardData?._id} >
                                <WarehouseCard
                                    cardData={cardData}
                                    setLinkedWarehouses={setCurSelectedWarehouse}
                                    linkedWarehouse={curSelectedWarehouse}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
                <div className={classes.bottomCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onSave(curSelectedWarehouse)
                        }}
                        size="small"
                    >
                        Save & Close
                    </Button>
                </div>
            </div>}
        />
    );
};

export default WarehouseSelectDrawer;
