import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  Skeleton,
  Stack,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@material-ui/core";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@material-ui/core";
import Button from "../styled/generic/Button";
import SearchField from "../styled/generic/SearchField";
import Select from "../styled/generic/Select";
import TaskCard from "./tasks/components/TaskCard";
import { getOptionsForTasksFilter, getTaskForParent } from "./api.call";
import { useDebounce } from "react-use";
import { FaFilter } from "react-icons/fa6";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Slider from "@mui/material/Slider";
import dayjs from "dayjs";
import Typography from "@mui/material/Typography";
import { getProfileDataPopulate } from "../team/procurements/api.call";
import { useDispatch, useSelector } from "react-redux";
import emptyPage from "../../Assets/EmptyPageSVG.svg";
import { createDefaultTemplateHook } from "../approval/approvalHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getLinerStatuses } from "../../helpers/approval";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  },
  profileLoaderCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.5)"
  },
  header: {
    width: "100%",
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: "0px 20px",
  },
  bodyCont: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexWrap: "wrap",
    overflowY: "auto",
    padding: "20px",
    alignContent: "flex-start",
  },
  emptyLibrarayCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& div": {
      width: "100%",
      marginTop: "10px",
      padding: "0px 20px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
      textAlign: "center",
    },
    "& a": {
      textDecoration: "none",
      fontSize: "14px",
      marginLeft: "5px",
      cursor: "pointer",
      color: "blue",
    },
  },
  drawerBody: {
    width: "100%",
    padding: "20px",
    height: "calc(100% - 80px)",
    overflowY: "auto",
  },
  drawerBottomBtn: {
    width: "100%",
    height: "80px",
    paddingBottom: "20px",
    padding: "0px 20px",
  },
  organizationCover: {
    position: "relative",
    width: "100%",
  },
  editOrg: {
    width: "fit-content",
    position: "absolute",
    right: "1rem",
    bottom: "3rem",
  },
  paginationCont: {
    width: "100%",
    minHeight: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #d2d1d1",
    backgroundColor: "white",
  },
  filterInputOp: {
    width: "450px",
    padding: "20px",
  },
}));

const ORDER_OPTIONS = {
  Title: "title",
  "Created Date": "createdAt",
  Progress: "progress",
  "Start Date": "startDate",
  "End Date": "endDate",
};

const TasksList = ({ entityType }) => {
  const classes = useStyles();
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { user } = useSelector((state) => state.auth);

  const [profileData, setProfileData] = useState(null);
  const [searchStr, setSearchStr] = useState("");
  const [orderFilter, setOrderFilter] = useState("");
  const [orderFilterOrder, setOrderFilterOrder] = useState("Ascending");
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editUnits, setEditUnits] = useState([]);
  const [editBlocks, setEditBlocks] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(15);
  const [totalPages, setTotalPages] = useState(0);
  const [stateChange, setStateChange] = useState(false);
  const [filterProjects, setFilterProjects] = useState([]);
  const [filterProjectsOptions, setFilterProjectsOptions] = useState([]);
  const [filterAssigneds, setFilterAssigneds] = useState([]);
  const [filterAssignedsOptions, setFilterAssignedsOptions] = useState([]);
  const [filterLabels, setFilterLabels] = useState([]);
  const [filterLabelsOptions, setFilterLabelsOptions] = useState([]);
  const [filterBlocks, setFilterBlocks] = useState([]);
  const [filterBlocksOptions, setFilterBlocksOptions] = useState([]);
  const [filterUnits, setFilterUnits] = useState([]);
  const [filterUnitsOptions, setFilterUnitsOptions] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterFloor, setFilterFloor] = useState([]);
  const [filterColumn, setFilterColumn] = useState([]);
  const [progessRange, setProgessRange] = useState([0, 100]);
  const [dateRanges, setDateRanges] = useState({
    plannedStart: { from: null, to: null },
    plannedFinish: { from: null, to: null },
    startDate: { from: null, to: null },
    endDate: { from: null, to: null },
  });
  const [filterStructures, setFilterStructures] = useState([]);
  const [filterStructuresOptions, setFilterStructuresOptions] = useState([]);
  const [defaultCreateLoader, setDefaultCreateLoader] = useState(false);
  const [profileDataLoader, setProfileDataLoader] = useState(false)

  useEffect(() => {
    getOptionsForTasksFilter({ profileId })
      .then((data) => {
        console.log("Task data is: ", data);
        setFilterProjectsOptions(data?.projects || []);
        setFilterAssignedsOptions(data?.assignees || []);
        setFilterLabelsOptions(data?.labels || []);
        setFilterBlocksOptions(data?.blocks || []);
        setFilterStructuresOptions(data?.structures || []);
        setFilterUnitsOptions(data?.units || []);
        setFilterFloor(data?.floors || []);
        setFilterColumn(data?.columns || []);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getTasksForListView = async () => {
    try {
      setLoading(true);
      await getTaskForParent({
        profileId,
        searchStr,
        orderFilter: ORDER_OPTIONS?.[orderFilter],
        orderFilterOrder: orderFilterOrder === "Ascending" ? -1 : 1,
        curPage: curPage - 1,
        limit: pageLimit,
        projects: filterProjects ? filterProjects.map((s) => s?._id) : [],
        assigneds: filterAssigneds ? filterAssigneds.map((s) => s?._id) : [],
        progressRange: [progessRange[0] - 1, progessRange[1] + 1],
        labels: filterLabels ? filterLabels.map((s) => s?._id) : [],
        blocks: filterBlocks ? filterBlocks.map((s) => s?._id) : [],
        units: filterUnits ? filterUnits.map((s) => s?._id) : [],
        structures: filterStructures ? filterStructures.map((s) => s?._id) : [],
        dateRanges,
        filterStatus,
        filterFloor,
        filterColumn,
      })
        .then((data) => {
          if (data) {
            setTasks(data?.data || []);
            let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
            setTotalPages(locTotalPage);
          } else {
            dispatch({
              type: "AddApiAlert",
              payload: {
                success: false,
                message: "An unknown error occurred while fetching tasks",
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "An unknown error occurred while fetching tasks",
            },
          });
        });
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while fetching tasks",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useDebounce(
    () => {
      getTasksForListView();
    },
    1000,
    [stateChange]
  );

  useEffect(() => {
    setStateChange(!stateChange);
  }, [curPage]);

  useEffect(() => {
    setCurPage(1);
    setStateChange(!stateChange);
  }, [
    searchStr,
    orderFilter,
    orderFilterOrder,
    filterProjects,
    filterAssigneds,
    progessRange,
    filterLabels,
    filterStructures,
    filterBlocks,
    dateRanges,
    filterStatus,
    filterFloor,
    filterColumn,
    filterUnits,
  ]);

  const handleFilterChipClick = (filterType) => {
    const today = dayjs();
    const startOfMonth = today.startOf("month");
    const endOfMonth = today.endOf("month");
    const startOfNextMonth = today.add(1, "month").startOf("month");
    const endOfNextMonth = today.add(1, "month").endOf("month");

    // Clear any existing date filters first
    setDateRanges((prev) => ({
      ...prev,
      plannedStart: { from: null, to: null },
      plannedFinish: { from: null, to: null },
      startDate: { from: null, to: null },
      endDate: { from: null, to: null },
    }));

    // Then set the new filter
    switch (filterType) {
      case "upcoming":
        setDateRanges({
          plannedStart: {
            from: today.format("YYYY-MM-DD"),
            to: today.add(15, "days").format("YYYY-MM-DD"),
          },
          plannedFinish: { from: null, to: null },
          startDate: { from: null, to: null },
          endDate: { from: null, to: null },
        });
        break;
      case "thisMonth":
        setDateRanges({
          plannedStart: {
            from: startOfMonth.format("YYYY-MM-DD"),
            to: endOfMonth.format("YYYY-MM-DD"),
          },
          plannedFinish: { from: null, to: null },
          startDate: { from: null, to: null },
          endDate: { from: null, to: null },
        });
        break;
      case "nextMonth":
        setDateRanges({
          plannedStart: {
            from: startOfNextMonth.format("YYYY-MM-DD"),
            to: endOfNextMonth.format("YYYY-MM-DD"),
          },
          plannedFinish: { from: null, to: null },
          startDate: { from: null, to: null },
          endDate: { from: null, to: null },
        });
        break;
      case "clear":
        setDateRanges({
          plannedStart: { from: null, to: null },
          plannedFinish: { from: null, to: null },
          startDate: { from: null, to: null },
          endDate: { from: null, to: null },
        });
        break;
    }
  };

  const renderFilterChips = () => (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        p: 2,
        overflowX: "auto",
        "&::-webkit-scrollbar": { height: "6px" },
        "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
        "&::-webkit-scrollbar-thumb": { background: "#888" },
        "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
      }}
    >
      <Chip
        label="Upcoming (15 days)"
        onClick={() => handleFilterChipClick("upcoming")}
        color={
          dateRanges.plannedStart.from === dayjs().format("YYYY-MM-DD")
            ? "primary"
            : "default"
        }
        variant={
          dateRanges.plannedStart.from === dayjs().format("YYYY-MM-DD")
            ? "filled"
            : "outlined"
        }
      />
      <Chip
        label="This Month"
        onClick={() => handleFilterChipClick("thisMonth")}
        color={
          dateRanges.plannedStart.from ===
            dayjs().startOf("month").format("YYYY-MM-DD")
            ? "primary"
            : "default"
        }
        variant={
          dateRanges.plannedStart.from ===
            dayjs().startOf("month").format("YYYY-MM-DD")
            ? "filled"
            : "outlined"
        }
      />
      <Chip
        label="Next Month"
        onClick={() => handleFilterChipClick("nextMonth")}
        color={
          dateRanges.plannedStart.from ===
            dayjs().add(1, "month").startOf("month").format("YYYY-MM-DD")
            ? "primary"
            : "default"
        }
        variant={
          dateRanges.plannedStart.from ===
            dayjs().add(1, "month").startOf("month").format("YYYY-MM-DD")
            ? "filled"
            : "outlined"
        }
      />
      {(dateRanges.plannedStart.from || dateRanges.plannedStart.to) && (
        <Chip
          label="Clear Filters"
          onClick={() => handleFilterChipClick("clear")}
          color="default"
          variant="outlined"
        />
      )}
    </Stack>
  );

  const getProjectData = async () => {
    setProfileDataLoader(true)
    await getProfileDataPopulate({ profileId: profileId })
      .then((data) => {
        setProfileData(data);
        setProfileDataLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setProfileDataLoader(false)
      })
  };

  useEffect(() => {
    if (profileId) {
      getProjectData();
    }
  }, [profileId]);

  const createDefaultsTemplates = async () => {
    setDefaultCreateLoader(true);
    const entity = profileData?.parent?._id;
    const entityModelName = profileData?.parentModelName;
    const entityParent = profileData?.parent?.ownerProfile?.parent;
    const entityParentModelName =
      profileData?.parent?.ownerProfile?.parentModelName;
    await createDefaultTemplateHook({
      profileId: profileId,
      entity,
      entityModelName,
      entityParent,
      userId: user?._id,
      entityParentModelName,
      ownerProfileId: profileData?.parent?.ownerProfile?._id,
      types: ["WBS"],
      libraryId: profileData?.parent?.wallet?.defaultLibrary?._id,
    })
      .then(async (data) => {
        console.log(data);
        await getProjectData();
        setDefaultCreateLoader(false);
      })
      .catch(async (error) => {
        console.log(error);
        await getProjectData();
        setDefaultCreateLoader(false);
      });
  };

  return (
    <div className={classes.mainCont}>
      {profileDataLoader ? (<div className={classes.profileLoaderCont} >
        <CircularProgress />
      </div>) : (<>
        {!profileData?.parent?.wallet?.defaultLibrary?._id ? (
          <div className={classes.emptyLibrarayCont}>
            <img src={emptyPage} />
            <div>
              <p>
                No Library exists in the current directory or any library still
                didn't selected as default library.
                <br />
                Click{" "}
                <a
                  onClick={() => {
                    history.push(`/offerings-categories/management/${profileId}`);
                  }}
                >
                  here
                </a>{" "}
                to create and manage your library(s), category(s), product(s) and
                service(s)
              </p>
            </div>
          </div>
        ) : (
          <>
            {!profileData?.parent?.wallet?.defaultLibrary?.defaultWBSApproval ? (
              <div className={classes.emptyLibrarayCont}>
                <img src={emptyPage} />
                <div>
                  <p>
                    {defaultCreateLoader ? (
                      `Template Creating....`
                    ) : (
                      <>
                        Some template(s) are missing in the current directory or
                        some template(s) are still didn't selected as default.
                        <br />
                        Click <a onClick={createDefaultsTemplates}>here</a> to
                        create default template(s)
                      </>
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className={classes.header}>
                  <MyPopOver
                    closeOnClick={false}
                    appearContent={
                      <Button
                        startIcon={<FaFilter />}
                        variant="outlined"
                        color="primary"
                      >
                        Add Filter
                      </Button>
                    }
                    showContent={
                      <div className={classes.filterInputOp}>
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                          <SearchField
                            value={searchStr}
                            onChange={(e) => setSearchStr(e.target.value)}
                            sx={{
                              backgroundColor: "white",
                              boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
                            }}
                            fullWidth={true}
                            size="small"
                          />
                        </Box>
                        {entityType === "Organization" && (
                          <Box flex="1" style={{ marginBottom: "15px" }}>
                            <Autocomplete
                              options={filterProjectsOptions}
                              value={filterProjects}
                              onChange={(event, value) => {
                                setFilterProjects(value);
                              }}
                              fullWidth
                              multiple={true}
                              size="small"
                              getOptionLabel={(option) => option?.displayName}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  {...params}
                                  placeholder={"Select project"}
                                />
                              )}
                            />
                          </Box>
                        )}
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                          <Autocomplete
                            options={filterAssignedsOptions}
                            value={filterAssigneds}
                            onChange={(event, value) => {
                              setFilterAssigneds(value);
                            }}
                            fullWidth
                            multiple={true}
                            size="small"
                            getOptionLabel={(option) =>
                              option?.parent?.displayName
                            }
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                {...params}
                                placeholder={"Select Assigned users"}
                              />
                            )}
                          />
                        </Box>
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                          <Autocomplete
                            options={filterLabelsOptions}
                            value={filterLabels}
                            onChange={(event, value) => {
                              setFilterLabels(value);
                            }}
                            fullWidth
                            multiple={true}
                            size="small"
                            getOptionLabel={(option) => option?.name}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                {...params}
                                placeholder={"Select labels"}
                              />
                            )}
                          />
                        </Box>
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                          <Autocomplete
                            options={filterBlocksOptions}
                            value={filterBlocks}
                            onChange={(event, value) => {
                              setFilterBlocks(value);
                            }}
                            fullWidth
                            multiple={true}
                            size="small"
                            getOptionLabel={(option) => option?.name}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                {...params}
                                placeholder={"Select blocks"}
                              />
                            )}
                          />
                        </Box>
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                          <Autocomplete
                            options={filterUnitsOptions}
                            value={filterUnits}
                            onChange={(event, value) => {
                              setFilterUnits(value);
                            }}
                            fullWidth
                            multiple={true}
                            size="small"
                            getOptionLabel={(option) => option?.name}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                {...params}
                                placeholder={"Select units"}
                              />
                            )}
                          />
                        </Box>
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                          <Autocomplete
                            options={filterStructuresOptions}
                            value={filterStructures}
                            onChange={(event, value) => {
                              setFilterStructures(value);
                            }}
                            fullWidth
                            multiple={true}
                            size="small"
                            getOptionLabel={(option) => option?.name}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                {...params}
                                placeholder={"Select structures"}
                              />
                            )}
                          />
                        </Box>
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                          <Autocomplete
                            options={getLinerStatuses("WBS")}
                            value={filterStatus}
                            onChange={(event, value) => {
                              setFilterStatus(value);
                            }}
                            fullWidth
                            multiple={true}
                            size="small"
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                size="small"
                                variant="outlined"
                                {...params}
                                placeholder={"Select statuses"}
                              />
                            )}
                          />
                        </Box>
                        <Box flex={1}>
                          <Autocomplete
                            multiple
                            freeSolo
                            options={[]}
                            value={filterFloor}
                            onChange={(event, newValue) => {
                              const numericValues = newValue
                                .map((val) => {
                                  const num = parseInt(val);
                                  return isNaN(num) ? null : num;
                                })
                                .filter((val) => val !== null);
                              setFilterFloor(numericValues);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Floors"
                                placeholder="Enter floor numbers"
                                helperText="Enter floor numbers and press Enter"
                                size="small"
                                fullWidth
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((floor, index) => (
                                <Chip
                                  key={floor}
                                  label={floor}
                                  size="small"
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderOption={(props, option) => (
                              <MenuItem {...props}>
                                {option < 0
                                  ? `Basement ${Math.abs(option)}`
                                  : option === 0
                                    ? "Ground Floor"
                                    : `Floor ${option}`}
                              </MenuItem>
                            )}
                          />
                        </Box>
                        <Box flex={1}>
                          <Autocomplete
                            multiple
                            freeSolo
                            value={filterColumn}
                            options={[]}
                            onChange={(event, newValue) => {
                              const numericValues = newValue
                                .map((val) => {
                                  const num = parseInt(val);
                                  return isNaN(num) ? null : num;
                                })
                                .filter((val) => val !== null);
                              setFilterColumn(numericValues);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Columns"
                                placeholder="Enter column numbers"
                                helperText="Enter column numbers and press Enter"
                                size="small"
                                fullWidth
                              />
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((column, index) => (
                                <Chip
                                  key={index}
                                  label={column}
                                  size="small"
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderOption={(props, option) => (
                              <MenuItem {...props}>Column {option}</MenuItem>
                            )}
                          />
                        </Box>
                        <Box flex="1" style={{ marginBottom: "15px" }}>
                          <BoxSpaceBetween>
                            <Select
                              value={orderFilter}
                              onChange={(e) => setOrderFilter(e.target.value)}
                              displayEmpty
                              size="small"
                              style={{ width: "200px" }}
                            >
                              <MenuItem value="">Filter (None)</MenuItem>
                              {Object.keys(ORDER_OPTIONS).map((option) => (
                                <MenuItem value={option}>{option}</MenuItem>
                              ))}
                            </Select>
                            <Select
                              value={orderFilterOrder}
                              onChange={(e) =>
                                setOrderFilterOrder(e.target.value)
                              }
                              displayEmpty
                              size="small"
                              style={{ width: "200px" }}
                            >
                              <MenuItem value="Ascending">Ascending</MenuItem>
                              <MenuItem value="Descending">Descending</MenuItem>
                            </Select>
                          </BoxSpaceBetween>
                        </Box>
                        <Box flex="1">
                          <Slider
                            value={progessRange}
                            onChange={(event, newValue) => {
                              setProgessRange(newValue);
                            }}
                            valueLabelDisplay="auto"
                          />
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="subtitle2">
                            Planned Start Date Range
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                value={dateRanges.plannedStart.from || ""}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    plannedStart: {
                                      ...prev.plannedStart,
                                      from: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                value={dateRanges.plannedStart.to || ""}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    plannedStart: {
                                      ...prev.plannedStart,
                                      to: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="subtitle2">
                            Planned Finish Date Range
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                value={dateRanges.plannedFinish.from || ""}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    plannedFinish: {
                                      ...prev.plannedFinish,
                                      from: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                value={dateRanges.plannedFinish.to || ""}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    plannedFinish: {
                                      ...prev.plannedFinish,
                                      to: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="subtitle2">
                            Start Date Range
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                value={dateRanges.startDate.from || ""}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    startDate: {
                                      ...prev.startDate,
                                      from: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                value={dateRanges.startDate.to || ""}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    startDate: {
                                      ...prev.startDate,
                                      to: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <Typography variant="subtitle2">
                            End Date Range
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                value={dateRanges.endDate.from || ""}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    endDate: {
                                      ...prev.endDate,
                                      from: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                value={dateRanges.endDate.to || ""}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    endDate: {
                                      ...prev.endDate,
                                      to: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    }
                  />
                  <div></div>
                </div>
                {renderFilterChips()}
                <div className={classes.bodyCont}>
                  {loading ? (
                    <Grid container spacing={2}>
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item) => (
                        <Grid item xs={12} sm={6} md={4}>
                          <Skeleton variant="rounded" width="100%" height={200} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      {tasks.map((task, index) => (
                        <Grid item xs={12} md={6} lg={4} spacing={2}>
                          <TaskCard
                            setEditUnits={setEditUnits}
                            setEditBlocks={setEditBlocks}
                            task={task}
                            tasks={tasks}
                            setTasks={setTasks}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </div>
                <div className={classes.paginationCont}>
                  <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => {
                      setCurPage(value);
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </>)}
    </div>
  );
};

export default TasksList;
