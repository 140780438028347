import { Typography } from "@material-ui/core";
import { Box } from "@mui/material";
import React from "react";

const WorkspaceApp = ({ isSelectable, isSelected, onClick, appData }) => {
  

  const getIconForApp = (code) => {
    let url;
    switch (code) {
      case "teams":
        url = "/icons/app/ticketing.svg";
        break;
      case "planning":
        url = "/icons/app/planning.svg";
        break;
      case "prefab":
        url = "/icons/app/PreFab.svg";
        break;
      case "booking":
        url = "/icons/app/booking.svg";
        break;
      case "bug":
        url = "/icons/app/bugs.svg";
        break;
      case "meeting":
        url = "/icons/app/meeting.svg";
        break;
      case "accounts":
        url = "/icons/app/accounts.svg";
        break;
      case "documents":
        url = "/icons/app/documents.svg";
        break;
      case "events":
        url = "/icons/app/events.svg";
        break;
      case "timesheet":
        url = "/icons/app/timesheets.svg";
        break;
      case "investment":
        url = "/icons/app/investment.svg";
        break;
      case "portfoliomanagement":
        url = "/icons/app/documents.svg"; // TODO
        break;
      case "propertymanagement":
        url = "/icons/app/rental.svg";
        break;
      case "productservices":
        url = "/icons/app/productsservices.svg";
        break;
      case "procurement":
        url = "/icons/app/procurement.svg";
        break;
      case "estimation":
        url = "/icons/app/budget.svg";
        break;
      case "inventory":
        url = "/icons/app/inventory.svg";
        break;
      case "sitereport":
        url = "/icons/app/sitereport.svg";
        break;
      case "hrm":
        url = "/icons/app/hrm.svg";
        break;
      case "mywork":
        url = "/icons/app/mywork.svg";
        break;
      case "approval":
        url = "/icons/app/approval.svg";
        break;
      case "assets":
        url = "/icons/app/Assets_Icon.svg";
        break;
      case "planning":
          url = "/icons/app/Assets_Icon.svg";
          break;
      case "bug":
        url = "/icons/app/Assets_Icon.svg";
        break;
      default:
        url = "";
        break;
    }
    return url;
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      sx={{
        borderRadius: "10px",
        border:
          isSelectable && isSelected
            ? "3px solid #2D76E0AF"
            : "1px solid rgba(0,0,0,0.10)",
        boxShadow:
          isSelectable && isSelected
            ? "0px 0px 80px 0px rgba(171, 190, 209, 0.15)"
            : "none",
        p: 2,
        minHeight: "150px",
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    >
      <img src={getIconForApp(appData?.code)} alt="app-icon" />
      <Typography
        variant="body1"
        style={{ fontWeight: 600, marginTop: "10px" }}
      >
        {appData?.name}
      </Typography>
    </Box>
  );
};

export default WorkspaceApp;
