
import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Paper, Checkbox, IconButton } from '@material-ui/core';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LessText from '../LessText';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    paperMainCont: {
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
        padding: "10px",
        "&:hover": {
			transform: "scale(1.02)"
		},
    },
    detailsCont: {
        width: "calc(100% - 60px)",
        "& h3": {
            fontSize: "18px",
            fontWeight: "500",
            marginBottom: "-3px"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "500",
            color: "gray"
        },
        "& h5": {
            fontSize: "12px"
        },
    },
    avatarNameCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px"
    }
}));

const BlockCard = ({
    cardData, linkedBlocks, setLinkedBlocks,
    isForRemove = false, removeFun, loader,
    needToMakeDisable
}) => {
    const classes = useStyles();
    const [selectedIds, setSelectedIds] = useState([])
    useEffect(() => {
        setSelectedIds(linkedBlocks.map((s) => s?._id))
    }, [linkedBlocks])

    return (
        <Paper
            elevation={2}
            className={classes.paperMainCont}
        >
            <div className={classes.detailsCont} >
                <div className={classes.avatarNameCont} >
                    <Avatar style={{ width: "38px", height: "38px" }} >
                        <WidgetsIcon />
                    </Avatar>
                    <div style={{ marginLeft: "10px" }} >
                        <h3>
                            <LessText
                                limit={28}
                                string={cardData?.name}
                            />
                        </h3>
                        <p>{`Floors:- ${cardData?.totalFloors} Units:- ${cardData?.unitCount}`}</p>
                    </div>
                </div>
                <h5>
                    <LessText
                        limit={85}
                        string={cardData?.description}
                    />
                </h5>
            </div>
            {!needToMakeDisable && (<>
                {isForRemove ? (
                    <IconButton
                        onClick={() => {
                            removeFun(cardData)
                        }}
                        disabled={loader}
                    >
                        <CancelIcon />
                    </IconButton>
                ) : (
                    <Checkbox
                        checked={selectedIds.includes(cardData?._id)}
                        onChange={(event) => {
                            if (event.target.checked) {
                                setLinkedBlocks([...linkedBlocks, cardData])
                            } else {
                                setLinkedBlocks(linkedBlocks.filter((s) => s?._id != cardData?._id))
                            }
                        }}
                        disabled={loader}
                        color="primary"
                    />
                )}
            </>)}
        </Paper>
    );
};

export default BlockCard;