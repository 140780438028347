import React from 'react';
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    container: {
        marginBottom: 32,
        padding: 20,
        backgroundColor: '#ffffff',
        borderRadius: 8,
        border: '1px solid #e5e7eb',
        boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
    },
    title: {
        fontSize: '12px',
        color: '#64748b',
        textTransform: 'uppercase',
        marginBottom: 8,
        fontWeight: 600,
        letterSpacing: '0.5px',
    },
    description: {
        fontSize: '16px',
        color: '#1e293b',
        fontWeight: 600,
        marginBottom: 16,
        lineHeight: 1.4,
    },
    ancestorList: {
        display: "flex",
        flexWrap: "wrap",
        gap: "8px",
        maxHeight: "5em",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#f1f5f9",
            borderRadius: "3px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#cbd5e1",
            borderRadius: "3px",
            "&:hover": {
                backgroundColor: "#94a3b8"
            }
        }
    },
    ancestorItem: {
        display: "flex",
        alignItems: "center",
        backgroundColor: '#f8fafc',
        borderRadius: 6,
        padding: '6px 12px',
        border: '1px solid #e2e8f0',
        transition: 'all 0.2s ease',
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: '#f1f5f9',
            transform: 'translateY(-1px)',
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
        }
    },
    ancestorText: {
        fontSize: '13px',
        color: '#334155',
        lineHeight: 1.4,
        fontWeight: 500,
    },
    separator: {
        fontSize: '14px',
        color: '#94a3b8',
        margin: '0 6px',
        fontWeight: 500,
    }
}));

const AncestorPath = ({ parentData }) => {
    const classes = useStyles();

    if (!parentData) return null;

    return (
        <div className={classes.container}>
            <Typography className={classes.title}>
                {parentData?.taskName ? 'Related Task' : 'Related Target'}
            </Typography>
            <Typography className={classes.description}>
                {parentData?.title || parentData?.taskName}
            </Typography>
            <Box className={classes.ancestorList}>
                {Array.isArray(parentData?.ancestors) && parentData?.ancestors?.map((ancestor, index) => (
                    <Box key={ancestor._id} className={classes.ancestorItem}>
                        <Typography className={classes.ancestorText}>
                            {ancestor?.taskName?.length > 25
                                ? `${ancestor?.taskName?.substring(0, 25)}...`
                                : ancestor?.taskName || "Unnamed"}
                        </Typography>
                        {index < parentData?.ancestors?.length - 1 && (
                            <Typography className={classes.separator}>›</Typography>
                        )}
                    </Box>
                ))}
            </Box>
        </div>
    );
};

export default AncestorPath; 