import React, { useEffect, useState } from 'react';
import {
  Chip,
  makeStyles,
  Grid,
  Paper
} from '@material-ui/core';
import { FaBinoculars } from 'react-icons/fa';
import html2plaintext from "html2plaintext";
import LessText from "../styled/CommonComponents/LessText";
import moment from 'moment';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  binocularsIcon: {
    color: theme.palette.primary.main,
    fontSize: "25px"
  },
  statusChip: {
    marginLeft: 'auto',
  },
  paperCont: {
    padding: "15px"
  },
  cardTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "10px"
  },
  iconTitleCont: {
    display: 'flex',
    alignItems: "center",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      color: "gray"
    },
    "& div": {
      marginLeft: "10px",
    }
  },
  bottomSty: {
    display: 'flex',
    alignItems: "center",
    padding: "10px 10px",
    backgroundColor: "#e2f3ff",
    justifyContent: "space-between",
    "& p": {
      fontSize: "14px",
      color: "gray"
    }
  },
  parentDataContainer: {
    backgroundColor: '#f8f9fa',
    padding: '12px 16px',
    borderRadius: '8px',
    marginBottom: '16px',
    border: '1px solid #e0e0e0',
    boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
  },
  parentDataTitle: {
    fontSize: '12px',
    color: 'gray',
    textTransform: 'uppercase',
    marginBottom: '6px',
    fontWeight: 600,
    letterSpacing: '0.5px',
  },
  parentDataDescription: {
    fontSize: '14px',
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginBottom: '8px',
  }
}));

const ObservationCard = ({ observation, onClick, xs = 12, md = 6, lg = 4 }) => {
  const classes = useStyles();

  const [parentData, setParentData] = useState(null);

  useEffect(() => {
    const parent = observation?.parent;
    if (parent?.parentModelName) {
      const newParent = parent?.parent
      setParentData(newParent);
    } else {
      setParentData(parent);
    }
  }, [observation?.parent]);

  console.log('parentData', parentData);

  return (
    <Grid item xs={xs} md={md} lg={lg}>
      <Paper style={{ cursor: "pointer" }} elevation={2} onClick={() => onClick(observation)}  >
        <div className={classes.paperCont} >
          <div className={classes.cardTop} >
            <div className={classes.iconTitleCont} >
              <FaBinoculars className={classes.binocularsIcon} />
              <div>
                <h3>{observation?.title || "Untitled"}</h3>
                <p>{moment(observation?.createdAt).format('MMM Do YYYY, hh:mm a')}</p>
              </div>
            </div>
            <Chip
              label={observation?.status}
              size="small"
              color="primary"
              className={classes.statusChip}
            />
          </div>

          {parentData && (
            <div className={classes.parentDataContainer}>
              <p className={classes.parentDataTitle}>
                {parentData?.taskName ? 'Related Task' : 'Related Target'}
              </p>
              <Typography className={classes.parentDataDescription}>
                {parentData?.title || parentData?.taskName}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "6px",
                  maxHeight: "4.8em",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px"
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "3px"
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#bdbdbd",
                    borderRadius: "3px",
                    "&:hover": {
                      background: "#9e9e9e"
                    }
                  },
                }}
              >
                {Array.isArray(parentData?.ancestors) && parentData?.ancestors?.length > 0 &&
                  parentData?.ancestors?.map((ancestor, index) => (
                    <Box
                      key={ancestor._id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#eef2ff",
                        borderRadius: "6px",
                        padding: "4px 8px",
                        border: "1px solid #e0e7ff",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          backgroundColor: "#e0e7ff",
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          color: "#4f46e5",
                          lineHeight: "1.3",
                          fontWeight: 500,
                        }}
                      >
                        {ancestor?.taskName?.length > 25
                          ? `${ancestor?.taskName?.substring(0, 25)}...`
                          : ancestor?.taskName || "Unnamed"}
                      </Typography>
                      {index < parentData?.ancestors?.length - 1 && (
                        <Typography
                          sx={{
                            fontSize: "0.75rem",
                            color: "#6366f1",
                            mx: 0.5,
                            fontWeight: 600,
                          }}
                        >
                          {"›"}
                        </Typography>
                      )}
                    </Box>
                  ))}
              </Box>
            </div>
          )}

          <div>
            <LessText
              limit={200}
              string={html2plaintext(observation?.description)}
            />
          </div>

        </div>
        <div className={classes.bottomSty} >
          <p>{observation.docs.length} Docs</p>
          <p>By {observation?.user?.displayName}</p>
        </div>
      </Paper>
    </Grid>
  );
};

export default ObservationCard;