import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Button,
  Grid,
  Typography,
  Chip,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../../helpers/Api";
import EditChecklistDrawer from "./EditChecklistDrawer";
import ApprovalAndActions from "../../approval/ApprovalAndActions";
import ApprovalTemplateChange from "../../approval/Approval.Template.Change";
import ApprovalActionDrawer from "../../approval/ApprovalActionDrawer";
import { createApprovalRequestFromMiddle } from "../../approval/api.call";
import StandardContainer from "../../styled/generic/StandardContainer";
import { MdOutlineForkRight, MdCheckCircleOutline } from "react-icons/md";
import { makeStyles } from "@material-ui/core/styles";
import { FaBinoculars } from "react-icons/fa";
import { BiCommentDetail } from "react-icons/bi";
import { AiOutlineFile } from "react-icons/ai";
import CheckpointManager from "../../OfferingsAndProducts/CheckpointManager";
import arrayToReducer from "../../../helpers/arrayToReducer";
import SmartCommentList from "../../comment/smart.comment.list";
import ObservationList from "../../sitereport/ObservationList";
import { Add } from "@mui/icons-material";
import DocPickerDrawer from "../../doc/doc.picker.drawer";
import AllKindOfDocViews from "../../styled/CommonComponents/AllKindOfDocViews";
import DeleteConfirmBox from "../../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import emptyFile from "../../../Assets/emptyData.svg";
import { removeDocFromChecklist } from "../api.call";
import LockIcon from "@material-ui/icons/Lock";
import { ArrowDropDown } from "@material-ui/icons";
import { allStatusOptions } from "../../../helpers/allStatuses";
import MyPopOver from "../../styled/CommonComponents/MyPopOver";
import ChecklistOptions from "./ChecklistOptions";
import LinkItems from "../../styled/CommonComponents/LinkResources/LinkItems";
import useOrgProjectUserConnector from "../../styled/CommonComponents/useOrgProjectUserConnector";
import { addObservationToParent } from "../../sitereport/Api.call";
import { Close } from "@mui/icons-material";
import ExportPDF from "../../styled/generic/ExportPDF";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    paddingBottom: "30px",
    overflow: "hidden",
    backgroundColor: "white",
  },
  header: {
    width: "100%",
    padding: "20px 20px 0px",
    borderBottom: "1px solid #ececec",
    "& h3": {
      fontSize: "25px",
      fontWeight: "600",
      marginBottom: "15px",
      color: "black",
    },
  },
  tabsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
  },
  selectedTab: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    opacity: 1,
    flexShrink: 0,
    padding: "15px 5px",
    marginRight: "20px",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    "& p": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
    },
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "18px",
    },
    "& h4": {
      padding: "0px 5px",
      borderRadius: "8px",
      border: "1px solid gray",
      color: "black",
    },
    transition: "opacity .3s ease-in-out",
  },
  unSelectedTab: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    opacity: 0.7,
    flexShrink: 0,
    padding: "15px 5px",
    marginBottom: "2px",
    marginRight: "20px",
    "& p": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
    },
    "& svg": {
      fontSize: "18px",
    },
    "& h4": {
      padding: "0px 5px",
      borderRadius: "8px",
      border: "1px solid gray",
      color: "black",
    },
    transition: "opacity .3s ease-in-out",
  },
  bodyCont: {
    width: "100%",
    padding: "20px 10px",
  },
  checklistItem: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: "#F3F3F3",
    borderRadius: "10px",
    "& .MuiTypography-root": {
      marginBottom: theme.spacing(1),
    },
  },
  checklistTitle: {
    fontWeight: 600,
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
  },
  checklistDescription: {
    color: theme.palette.text.secondary,
    fontSize: "0.9rem",
  },
  detailsSection: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  detailLabel: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    fontWeight: 500,
  },
  detailValue: {
    fontSize: "1rem",
    marginTop: theme.spacing(0.5),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  optionsCont: {
    padding: "5px",
  },
  typeSingleOptionSty: {
    padding: "4px 15px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  tagChip: {
    borderRadius: "6px",
    padding: "0 12px",
    height: "32px",
    "& .MuiChip-label": {
      padding: "0",
    },
  },
  unitChip: {
    borderRadius: "4px",
    backgroundColor: "#fff",
    borderColor: "#e0e0e0",
    padding: "0 4px",
    height: "20px",
    "& .MuiChip-label": {
      fontSize: "0.8rem",
      padding: "0 4px",
      lineHeight: 1.2,
    },
  },
}));

const TabComp = ({ Icon, title, count, selected, onClick }) => {
  const classes = useStyles();
  return (
    <div
      className={
        selected === title ? classes.selectedTab : classes.unSelectedTab
      }
      onClick={() => onClick(title)}
    >
      <Icon />
      <p>{title}</p>
      {count !== undefined && <h4>{count}</h4>}
    </div>
  );
};

const formatFloorLabel = (floorNumber) => {
  if (floorNumber < 0) {
    return `Basement ${Math.abs(floorNumber)}`;
  }
  if (floorNumber == 0) {
    return `GroundFloor`;
  }
  return `Floor ${floorNumber}`;
};

export default function ChecklistDetails() {
  const { checklistId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();

  const [mainLoader, setMainLoader] = useState(false);
  const [checklist, setChecklist] = useState();
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openApprovalFlow, setOpenApprovalFlow] = useState(false);
  const [tab, setTab] = useState("Checkpoints");
  const [checkpointDict, setCheckpointDict] = useState({});
  const [checkpointIds, setCheckpointIds] = useState([]);
  const [showDocPicker, setShowDocPicker] = useState(false);
  const [floors, setFloors] = useState({});
  // Approval related states
  const [needToMakeStatusDisable, setNeedToMakeStatusDisable] = useState(false);
  const [needToMakeDisable, setNeedToMakeDisable] = useState(false);
  const [activeStage, setActiveStage] = useState(null);
  const [selectedDocs, setSelectedDocs] = useState([]);
  const [formatedDocFiles, setFormatedDocFiles] = useState([]);
  const [removeAbleFiles, setRemoveAbleFiles] = useState([]);
  const [docDeleteOpen, setDocDeleteOpen] = useState(false);
  const [deleteDocLoader, setDeleteDocLoader] = useState(false);
  const [openAction, setOpenAction] = useState(false);
  const [actionStatus, setActionStatus] = useState(null);
  const [observationCount, setObservationCount] = useState(0);
  const [showUnitsDialog, setShowUnitsDialog] = useState(false);

  const { addProjectOrgUser, setOpenConnectorDialog } =
    useOrgProjectUserConnector({
      linkedEntityApi: "checklist/update/by-id",
      projectField: "project",
      organizationField: "organization",
      _id: checklistId,
      afterLinkEntity: (profileId) => {
        createApprovalRequestAndConnect(
          profileId,
          checklistId,
          "Checklist",
          "defaultChecklistApproval",
          user?.profile,
          checklist?.status
        );
      },
    });

  const getData = async () => {
    try {
      setMainLoader(true);
      const res = await Api.post("checklists/detail", { checklistId });

      const checklistData = res?.data?.checklist;

      if (checklistData) {
        setChecklist(checklistData);
        const { newDict, idArr } = arrayToReducer(checklistData?.checkpoints);
        setCheckpointDict(newDict);
        setCheckpointIds(idArr);
        setSelectedDocs(checklistData?.docs);
        if (!checklistData?.approvalRequest?._id) {
          if (
            checklistData?.project?.profile ||
            checklistData?.organization?.profile
          ) {
            let profileId = null;
            if (checklistData?.project?.profile) {
              profileId = checklistData?.project?.profile;
            } else if (checklistData?.organization?.profile) {
              profileId = checklistData?.organization?.profile;
            }
            createApprovalRequestAndConnect(
              profileId,
              checklistData?._id,
              "Checklist",
              "defaultChecklistApproval",
              user?.profile,
              checklistData?.status
            );
          } else {
            setOpenConnectorDialog(true);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching checklist:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: error.message,
        },
      });
    } finally {
      setMainLoader(false);
    }
  };

  const handleCreateObservation = async () => {
    try {
      const response = await addObservationToParent({
        parentId: checklistId,
        parentModelName: "Checklist",
        profileId: checklist?.project?.profile
          ? checklist?.project?.profile
          : checklist?.organization?.profile,
        userProfile: user?.profile,
        observationData: {
          user: user?._id,
          userProfile: user?.profile,
          project: checklist?.project?._id,
          organization: checklist?.organization?._id,
          tags: [{ data: checklistId, dataModel: "Checklist" }],
        },
      });
      await getObservationCount();
      return response?.newObservation;
    } catch (error) {
      console.error("Error creating observation:", error);
      throw error;
    }
  };

  useEffect(() => {
    let locActiveStage = checklist?.approvalRequest?.activeStage || null;
    setActiveStage(locActiveStage);
    let canEdit = false;
    if (locActiveStage?.approvalType === "anybody") {
      canEdit = true;
    } else {
      let approvers = locActiveStage?.approvers || [];
      for (let i = 0; i < approvers.length; i++) {
        if (
          approvers[i].profile == user?.profile &&
          !approvers[i]?.isApproved &&
          !approvers[i]?.isRejected
        ) {
          canEdit = true;
          break;
        }
      }
    }
    setNeedToMakeStatusDisable(!canEdit);
    if (locActiveStage?.canEditMainData && canEdit) {
      setNeedToMakeDisable(false);
    } else {
      setNeedToMakeDisable(true);
    }
  }, [checklist?.approvalRequest?.activeStage]);

  async function createApprovalRequestAndConnect(
    profileId,
    parentId,
    parentModelName,
    defaultTemplateField,
    userProfile,
    curStatus
  ) {
    setMainLoader(true);
    try {
      await createApprovalRequestFromMiddle({
        profileId,
        parentId,
        parentModelName,
        defaultTemplateField,
        userProfile,
        curStatus,
        type: "Checklist",
      });
      // Refresh data after creating approval request
      getData();
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error creating approval request",
        },
      });
      setMainLoader(false);
    }
  }

  useEffect(() => {
    getData();
    getObservationCount();
  }, [checklistId]);

  const removeDocuments = async (fileId) => {
    const file = formatedDocFiles.filter((s) => s?._id === fileId)[0];
    const filesGoingToRemove = formatedDocFiles.filter(
      (s) => s?.docId === file?.docId
    );
    setRemoveAbleFiles(filesGoingToRemove);
    setDocDeleteOpen(true);
  };

  const finalDocRemove = async (docId) => {
    setDeleteDocLoader(true);
    await removeDocFromChecklist({
      checklistId,
      docId,
    })
      .then((data) => {
        setSelectedDocs(selectedDocs.filter((s) => s?._id != docId));
        setRemoveAbleFiles([]);
        setDocDeleteOpen(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setDeleteDocLoader(false);
      });
  };

  useEffect(() => {
    let arrData = [];
    selectedDocs.map((docData) => {
      const docFile = docData?.files || [];
      docFile.map((fileData, index) => {
        if (fileData?.url) {
          arrData.push({
            ...fileData,
            numDocFile: docFile.length,
            documentName: docData?.title,
            name:
              docFile && docFile.length > 1
                ? `${docData?.title || ""}-(${index + 1})`
                : docData?.title || "",
            docId: docData?._id,
          });
        }
      });
    });
    setFormatedDocFiles(arrData);
  }, [selectedDocs]);

  useEffect(() => {
    if (checklist?.units?.length > 0) {
      const floorsByBlock = checklist.units.reduce((acc, unit) => {
        if (!unit.projectBlock || !unit.floor) return acc;

        if (!acc[unit.projectBlock]) {
          acc[unit.projectBlock] = new Set();
        }

        acc[unit.projectBlock].add(unit.floor);

        return acc;
      }, {});

      const formattedFloors = Object.keys(floorsByBlock).reduce(
        (acc, blockId) => {
          acc[blockId] = Array.from(floorsByBlock[blockId]).sort(
            (a, b) => a - b
          );
          return acc;
        },
        {}
      );

      setFloors(formattedFloors);
    } else {
      setFloors({});
    }
  }, [checklist]);

  const handleDocSelection = (docs) => {
    if (docs) {
      setSelectedDocs(docs);
    }
  };

  const getObservationCount = async () => {
    try {
      const response = await Api.post("site-observation/getAll", {
        parent: checklistId,
        parentModelName: "Checklist",
        tags: [{ data: checklistId, dataModel: "Checklist" }],
        skip: 0,
        limit: 1,
      });
      setObservationCount(response.data.totalCount);
    } catch (err) {
      console.error("Error fetching observation count:", err);
    }
  };

  if (mainLoader) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  //have to check
  const updateStatus = (nextStatus) => {
    if (!needToMakeStatusDisable) {
      setActionStatus(nextStatus);
      setOpenAction(true);
    }
  };

  return (
    <StandardContainer
      title="Checklist Details"
      showAppBar={true}
      appBarActions={
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {tab === "Documents" && (
            <Button
              variant="outlined"
              onClick={() => setShowDocPicker(true)}
              startIcon={<Add />}
              style={{ marginLeft: "10px" }}
              color="primary"
              disabled={needToMakeDisable}
            >
              Docs
            </Button>
          )}
          <Button
            id="demo-customized-button"
            variant="outlined"
            color="primary"
            size="small"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setOpenApprovalFlow(true);
            }}
            endIcon={<MdOutlineForkRight />}
          >
            Approval Flow
          </Button>
          <ApprovalAndActions
            openApprovalFlow={openApprovalFlow}
            setOpenApprovalFlow={setOpenApprovalFlow}
            loadingBool={loading}
            setLoadingBool={setLoading}
            parent={checklist?._id}
            parentModelName={"Checklist"}
            requestId={checklist?.approvalRequest?._id}
            needToMakeDisable={needToMakeStatusDisable}
            activeStage={activeStage}
            templateId={checklist?.approvalRequest?.approvalTemplate?._id}
            organizationId={checklist?.organization?._id}
            projectId={checklist?.project?._id}
            origin={"checklist"}
          />
          <div style={{ width: "10px" }}></div>
          {(checklist?.status === "Draft" ||
            checklist?.status === "Not Started") && (
            <ApprovalTemplateChange
              profileId={checklist?.approvalRequest?.profile}
              templateType={checklist?.approvalRequest?.type}
              parentId={checklist?._id}
              parentModelName={"Checklist"}
              previousReqId={checklist?.approvalRequest?._id}
              reloadData={getData}
            />
          )}
          <div style={{ width: "10px" }}></div>
          {needToMakeStatusDisable ? (
            <Button
              id="demo-customized-button"
              variant="outlined"
              size="small"
              color="primary"
              disabled
              endIcon={<LockIcon />}
            >
              {checklist?.status ? checklist?.status : "STATUS"}
            </Button>
          ) : (
            <>
              <MyPopOver
                closeOnClick={true}
                appearContent={
                  <Button
                    id="demo-customized-button"
                    variant="outlined"
                    size="small"
                    color="primary"
                    endIcon={<ArrowDropDown />}
                  >
                    {checklist?.status ? checklist?.status : "STATUS"}
                  </Button>
                }
                showContent={
                  <div className={classes.optionsCont}>
                    {allStatusOptions["Checklist"][checklist?.status] &&
                      allStatusOptions["Checklist"][checklist?.status].map(
                        (statusOption, i) => (
                          <div
                            key={i}
                            className={classes.typeSingleOptionSty}
                            onClick={() => {
                              updateStatus(statusOption);
                            }}
                          >
                            {statusOption}
                          </div>
                        )
                      )}
                  </div>
                }
              />
              <ApprovalActionDrawer
                openAction={openAction}
                setOpenAction={setOpenAction}
                loadingBool={loading}
                setLoadingBool={setLoading}
                actionType={"takeAction"}
                parent={checklist?._id}
                parentModelName={"Checklist"}
                origin={"Checklist"}
                statusField={"status"}
                statusValue={actionStatus}
                organizationId={checklist?.organization?._id}
                projectId={checklist?.project?._id}
                requestId={checklist?.approvalRequest?._id}
                templateId={checklist?.approvalRequest?.approvalTemplate?._id}
                activeStage={activeStage}
                actionCallBack={async (type, curStatues) => {
                  getData();
                }}
              />
              <ExportPDF
                apiUrl="/checklist/pdf"
                fileName={`checklist-${checklist?.customId}`}
                apiBody={{
                  checklistId: checklistId,
                }}
                enableSignatures={false}
                enableTermsAndConditions={false}
                libraryId={checklist?.project?.wallet?.defaultLibrary}
                profileId={checklist?.project?.profile}
              />
            </>
          )}
        </Box>
      }
      loading={mainLoader}
      appBarTitle={
        `#${checklist?.customId} | ${checklist?.title}` || "Untitled Checklist"
      }
    >
      <div className={classes.mainCont}>
        <div className={classes.header}>
          <Box
            sx={{
              p: 4,
              backgroundColor: "#f8f9fa",
              borderRadius: "12px",
              mb: 4,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item xs={12} md={8}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 600,
                        color: "#1a1a1a",
                        fontSize: "1.75rem",
                        mb: 2,
                        lineHeight: 1.3,
                      }}
                    >
                      {checklist?.title || "N/A"}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "#666",
                        fontSize: "1.1rem",
                        lineHeight: 1.7,
                        mb: { xs: 2, md: 0 },
                      }}
                    >
                      {checklist?.description || "No description available"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        height: "100%",
                        gap: "5px",
                      }}
                    >
                      <Chip
                        label={checklist?.status || "N/A"}
                        size="medium"
                        color="primary"
                        sx={{
                          borderRadius: "8px",
                          px: 2.5,
                          height: "36px",
                          fontSize: "0.95rem",
                          fontWeight: 500,
                        }}
                      />
                      <Chip
                        label={`ID: ${checklist?.customId || "N/A"}`}
                        size="medium"
                        variant="outlined"
                        sx={{
                          borderRadius: "8px",
                          px: 2.5,
                          height: "36px",
                          borderColor: "#ddd",
                          fontSize: "0.95rem",
                        }}
                      />
                      <ChecklistOptions
                        checklist={checklist}
                        setMainLoader={setMainLoader}
                        getData={getData}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 600,
                    color: "#444",
                    mb: 2.5,
                    fontSize: "1.1rem",
                  }}
                >
                  Units & Locations
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 10, mb: 2 }}>
                  {checklist?.columns?.map((column, index) => (
                    <Chip
                      key={`column-${index}`}
                      label={`Column ${column}`}
                      size="small"
                      variant="outlined"
                      sx={{
                        borderRadius: "6px",
                        backgroundColor: "#fff",
                        borderColor: "#e0e0e0",
                        height: "24px",
                      }}
                    />
                  ))}
                </Box>
                <Grid container>
                  {checklist?.projectBlocks?.map((block) => {
                    let filteredUnits = checklist?.units?.filter(
                      (unit) => unit.projectBlock === block._id
                    );
                    return (
                      <Grid item xs={12} key={block._id}>
                        <Box
                          sx={{
                            p: 2,
                            border: "1px solid #e0e0e0",
                            borderRadius: "8px",
                            mb: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                              mb: 1,
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              style={{
                                fontWeight: 600,
                                color: "#666",
                                marginRight: "10px",
                              }}
                            >
                              {block.name}
                            </Typography>
                            <div style={{ display: "flex", gap: "5px" }}>
                              {floors[block._id]?.map((floor) => (
                                <Chip
                                  key={floor}
                                  label={`${formatFloorLabel(floor)}`}
                                  size="small"
                                  variant="outlined"
                                  className={classes.floorChip}
                                />
                              ))}
                            </div>
                          </Box>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                  flexWrap: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                {filteredUnits.length === 0 ? (
                                  <div>No units assigned in this block</div>
                                ) : (
                                  filteredUnits
                                    .slice(0, 7)
                                    .map((unit) => (
                                      <Chip
                                        key={unit._id}
                                        label={unit.name}
                                        size="small"
                                        variant="outlined"
                                        className={classes.unitChip}
                                      />
                                    ))
                                )}
                                {filteredUnits.length > 7 && (
                                  <Button
                                    size="small"
                                    onClick={() => setShowUnitsDialog(true)}
                                    sx={{ minWidth: "auto", ml: 1 }}
                                  >
                                    +{filteredUnits.length - 5} more
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    );
                  })}
                  {checklist?.projectBlocks?.length === 0 && (
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{ color: "#666" }}>
                        No blocks assigned
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* Structures Section */}
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.detailLabel}
                    >
                      Structures
                    </Typography>
                    <Grid container spacing={1}>
                      {checklist?.structures?.length > 0 ? (
                        checklist.structures.map((structure) => (
                          <Grid item key={structure._id}>
                            <Chip
                              label={structure.name}
                              size="medium"
                              variant="outlined"
                              className={classes.tagChip}
                              style={{
                                backgroundColor: structure.colorCode,
                                color: structure.colorCode ? "#000" : "inherit",
                                border: "1px solid #e0e0e0",
                              }}
                            />
                          </Grid>
                        ))
                      ) : (
                        <Grid item>
                          <Typography variant="body2" color="textSecondary">
                            No structures assigned
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {/* Labels Section */}
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.detailLabel}
                    >
                      Labels
                    </Typography>
                    <Grid container spacing={1}>
                      {checklist?.labels?.length > 0 ? (
                        checklist.labels.map((label) => (
                          <Grid item key={label._id}>
                            <Chip
                              label={label.name}
                              size="medium"
                              variant="outlined"
                              className={classes.tagChip}
                              style={{
                                backgroundColor: label.colorCode,
                                color: label.colorCode ? "#000" : "inherit",
                                border: "1px solid #e0e0e0",
                              }}
                            />
                          </Grid>
                        ))
                      ) : (
                        <Grid item>
                          <Typography variant="body2" color="textSecondary">
                            No labels assigned
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.detailLabel}
                    >
                      Assigned to
                    </Typography>
                    <Grid container spacing={1}>
                      {checklist?.assigned?.length > 0 ? (
                        checklist.assigned.map((assignee) => (
                          <Grid item key={assignee._id}>
                            <Chip
                              avatar={
                                <Avatar
                                  src={
                                    assignee.parent?.displayPicture?.thumbUrl
                                  }
                                  alt={assignee.parent?.displayName}
                                />
                              }
                              label={assignee.parent?.displayName}
                              size="medium"
                              variant="outlined"
                              sx={{
                                borderRadius: "6px",
                                backgroundColor: "#fff",
                                borderColor: "#e0e0e0",
                                px: 1.5,
                                height: "32px",
                                maxWidth: "200px",
                                "& .MuiChip-label": {
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                },
                              }}
                            />
                          </Grid>
                        ))
                      ) : (
                        <Grid item>
                          <Typography variant="body2" color="textSecondary">
                            No users assigned
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="subtitle1"
                      className={classes.detailLabel}
                    >
                      Creator
                    </Typography>
                    <Grid container spacing={1}>
                      {checklist?.creator ? (
                        <Grid item>
                          <Chip
                            avatar={
                              <Avatar
                                src={
                                  checklist.creator?.parent?.displayPicture
                                    ?.thumbUrl ||
                                  checklist.creator?.parent?.displayPicture
                                    ?.url ||
                                  "default-avatar.png"
                                }
                                alt={checklist.creator?.parent?.displayName}
                              />
                            }
                            label={checklist.creator?.parent?.displayName}
                            size="medium"
                            variant="outlined"
                            sx={{
                              borderRadius: "6px",
                              backgroundColor: "#fff",
                              borderColor: "#e0e0e0",
                              px: 1.5,
                              height: "32px",
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item>
                          <Typography variant="body2" color="textSecondary">
                            Creator not available
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <LinkItems
              profileId={checklist?.project?.profile}
              parentId={checklist?._id}
              parentModel={"Checklist"}
              linkableItems={["task"]}
            />
          </Box>
          <div className={classes.tabsCont}>
            <TabComp
              Icon={MdCheckCircleOutline}
              title="Checkpoints"
              count={checklist?.checkpoints?.length}
              selected={tab}
              onClick={setTab}
            />
            <TabComp
              Icon={FaBinoculars}
              title="Observations"
              count={observationCount}
              selected={tab}
              onClick={setTab}
            />
            <TabComp
              Icon={AiOutlineFile}
              title="Documents"
              count={selectedDocs?.length}
              selected={tab}
              onClick={setTab}
            />
            <TabComp
              Icon={BiCommentDetail}
              title="Comments"
              count={0}
              selected={tab}
              onClick={setTab}
            />
          </div>
        </div>
        {addProjectOrgUser}
        <div className={classes.bodyCont}>
          {tab === "Checkpoints" && (
            <Box mt={3}>
              <CheckpointManager
                checklists={[checklist]}
                libraryId={checklist?.library}
                checkpointIds={checkpointIds}
                checkpointDict={checkpointDict}
                setCheckpointIds={setCheckpointIds}
                setCheckpointDict={setCheckpointDict}
                onUpdate={getData}
                profileId={checklist?.organization?.profile}
                needToMakeDisable={needToMakeDisable}
                needToMakeStatusDisable={needToMakeStatusDisable}
                activeStage={activeStage}
                parentId={checklist?._id}
                parentModelName={"Checklist"}
              />
            </Box>
          )}

          {tab === "Observations" && (
            <Box mt={3}>
              <ObservationList
                queryProps={{
                  parent: checklist?._id,
                  parentModelName: "Checklist",
                  tags: [{ data: checklist?._id, dataModel: "Checklist" }],
                }}
                drawerMode={false}
                profileId={
                  checklist?.project?.profile
                    ? checklist?.project?.profile
                    : checklist?.organization?.profile
                }
                onObservationCreate={handleCreateObservation}
                onCardClick={(observation) => {
                  console.log("Clicked observation:", observation);
                }}
                needToMakeDisable={needToMakeDisable}
              />
            </Box>
          )}

          {tab === "Documents" && (
            <Box mt={3}>
              <>
                {formatedDocFiles && formatedDocFiles.length > 0 ? (
                  <>
                    <AllKindOfDocViews
                      files={formatedDocFiles}
                      removeFun={removeDocuments}
                      isDeletable={true}
                    />
                    <DeleteConfirmBox
                      open={docDeleteOpen}
                      setOpen={setDocDeleteOpen}
                      loading={deleteDocLoader}
                      pageTitle={"Remove Document"}
                      cantent={
                        <div className={classes.deleteConfirm}>
                          <ReportProblemIcon />
                          <h3>Are you sure?</h3>
                          <p>Below files going to be remove form this task</p>
                          <ol>
                            {removeAbleFiles &&
                              removeAbleFiles.map((file, i) => (
                                <li>
                                  {i + 1}. {file?.name}
                                </li>
                              ))}
                          </ol>
                        </div>
                      }
                      cancleConfirmDelete={() => {
                        setDocDeleteOpen(false);
                        setRemoveAbleFiles([]);
                      }}
                      confirmDelete={() => {
                        finalDocRemove(removeAbleFiles[0]?.docId);
                      }}
                    />
                  </>
                ) : (
                  <div className={classes.emptyCont}>
                    <img src={emptyFile} alt="empty image" />
                    <p>
                      No attachment available. Click above button to add
                      attachment(s)
                    </p>
                  </div>
                )}
              </>
            </Box>
          )}

          {tab === "Comments" && (
            <Box mt={3}>
              <SmartCommentList
                parent={checklist?._id}
                parentModelName="Checklist"
              />
            </Box>
          )}
        </div>
      </div>

      {showEditDrawer && (
        <EditChecklistDrawer
          isDrawerOpen={showEditDrawer}
          setIsDrawerOpen={setShowEditDrawer}
          checklist={checklist}
          getData={getData}
        />
      )}
      <DocPickerDrawer
        open={showDocPicker}
        setOpen={setShowDocPicker}
        parentId={checklist?._id}
        parentModelName={"Checklist"}
        onClose={(docs) => {
          setShowDocPicker(false);
          handleDocSelection(docs);
        }}
        initialProfileId={checklist?.project?.profile}
        initialModelName={"Project"}
      />

      <Dialog
        open={showUnitsDialog}
        onClose={() => setShowUnitsDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">All Units</Typography>
            <IconButton onClick={() => setShowUnitsDialog(false)} size="small">
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {checklist?.projectBlocks?.map((block) => {
            let filteredUnits = checklist?.units?.filter(
              (unit) => unit.projectBlock === block._id
            );
            return (
              <Box
                key={block._id}
                sx={{
                  p: 2,
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  mb: 2,
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 2 }}>
                  {block.name}
                </Typography>
                <Grid container spacing={1}>
                  {filteredUnits?.map((unit) => (
                    <Grid item key={unit._id}>
                      <Chip
                        label={unit.name}
                        size="medium"
                        variant="outlined"
                        className={classes.unitChip}
                      />
                    </Grid>
                  ))}
                  {filteredUnits?.length === 0 && (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="textSecondary">
                        No units assigned in this block
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            );
          })}
        </DialogContent>
      </Dialog>
    </StandardContainer>
  );
}
