import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  CircularProgress,
  DialogContent,
  DialogActions,
  Stack,
  Popover,
  Card,
  CardContent,
  TextField,
  InputAdornment,
} from "@mui/material";
import { VariableSizeList as List } from "react-window";
import { makeStyles } from "@material-ui/core/styles";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Api from "../../../helpers/Api";
import moment from "moment";
import AddManyTasks from "./AddManyTasks";
import AddIcon from "@mui/icons-material/Add";
import DelayTracker from "./DelayTracker";
import TimerIcon from "@mui/icons-material/Timer";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CalculateIcon from "@mui/icons-material/Calculate";
import TimelineIcon from "@mui/icons-material/Timeline";
import CriticalPathDrawer from "./CriticalPathDrawer";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SortIcon from "@mui/icons-material/Sort";
import { SafetyCheck } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    position: "relative",
    border: "1px solid #e0e0e0",
    borderRadius: "8px",
    overflow: "hidden",
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
    position: "relative",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
    },
  },
  contentWrapper: {
    display: "inline-block",
    minWidth: "100%",
    position: "relative",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "12px 8px",
    borderBottom: "2px solid #e0e0e0",
    backgroundColor: "#f8f9fa",
    fontWeight: 600,
    position: "sticky",
    top: 0,
    zIndex: 2,
    "& > *": {
      borderRight: "1px solid #e0e0e0",
      padding: "0 12px",
      display: "flex",
      alignItems: "center",
    },
  },
  headerActions: {
    width: "100px",
    minWidth: "100px",
    position: "sticky",
    right: 0,
    backgroundColor: "#f8f9fa",
    borderLeft: "1px solid #e0e0e0",
    justifyContent: "center",
    zIndex: 3,
  },
  row: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
    borderBottom: "1px solid #e0e0e0",
    position: "relative",
    fontSize: "0.8em",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    "& > *": {
      borderRight: "1px solid #e0e0e0",
      padding: "0 12px",
      display: "flex",
      alignItems: "center",
    },
  },
  taskId: {
    width: "80px",
    minWidth: "80px",
  },
  taskName: {
    flex: 2,
    minWidth: "400px",
  },
  progress: {
    width: "100px",
    minWidth: "100px",
    justifyContent: "flex-end",
  },
  cumulativeProgress: {
    width: "120px",
    minWidth: "120px",
    justifyContent: "flex-end",
  },
  delay: {
    width: "120px",
    minWidth: "120px",
    justifyContent: "flex-end",
  },
  duration: {
    width: "120px",
    minWidth: "120px",
    justifyContent: "flex-end",
  },
  eseflsltf: {
    // New CSS class for the additional scheduling fields
    width: "150px",
    minWidth: "150px",
    justifyContent: "center",
  },
  date: {
    width: "120px",
    minWidth: "120px",
    justifyContent: "center",
  },
  predecessors: {
    width: "150px",
    minWidth: "150px",
  },
  actions: {
    width: "120px",
    minWidth: "120px",
    position: "sticky",
    right: 0,
    backgroundColor: "white",
    borderLeft: "1px solid #e0e0e0",
    justifyContent: "center",
    zIndex: 1,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    padding: "0 12px",
  },
  loadingMessage: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "16px",
    fontWeight: 500,
    "& .emoji": {
      fontSize: "20px",
    },
  },
  blueText: {
    color: "#2196f3",
  },
  orangeText: {
    color: "#ff9800",
  },
  greenText: {
    color: "#4caf50",
  },
  purpleText: {
    color: "#9c27b0",
  },
  negativeDelay: {
    color: "#d32f2f",
  },
  estimatedStartDelay: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  delayIndicator: {
    color: "#d32f2f",
    fontSize: "0.8em",
  },
  columnHeader: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  sortIcon: {
    fontSize: "1rem",
    marginLeft: "4px",
    opacity: 0.5,
    transition: "opacity 0.2s",
    "&.active": {
      opacity: 1,
    },
  },
}));

const TargetAnalytics = () => {
  const { targetId } = useParams();
  const classes = useStyles();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [expandedTasks, setExpandedTasks] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [addTasksDrawerOpen, setAddTasksDrawerOpen] = useState(false);
  const [selectedTaskForAdd, setSelectedTaskForAdd] = useState(null);
  const [delayTrackerOpen, setDelayTrackerOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    plannedStartAfter: null,
    plannedStartBefore: null,
    plannedFinishAfter: null,
    plannedFinishBefore: null,
    startDateAfter: null,
    startDateBefore: null,
    endDateAfter: null,
    endDateBefore: null,
    minProgress: "",
    maxProgress: "",
  });
  const [criticalPathDrawerOpen, setCriticalPathDrawerOpen] = useState(false);
  const [selectedTaskForCriticalPath, setSelectedTaskForCriticalPath] =
    useState(null);
  const [taskMap, setTaskMap] = useState({});
  const [leafTasks, setLeafTasks] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({
    id: true,
    taskName: true,
    progress: true,
    cumulative: true,
    delay: true,
    duration: true,
    plannedStart: true,
    plannedFinish: true,
    startDate: true,
    endDate: true,
    earlyStart: false,
    earlyFinish: false,
    latestStart: false,
    latestFinish: false,
    totalFloat: false,
    predecessors: false,
  });
  const [columnMenuAnchorEl, setColumnMenuAnchorEl] = useState(null);
  const [target, setTarget] = useState({
    _id: "",
    lastCPMCalculation: null,
  });
  const [calculatingCPM, setCalculatingCPM] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        setLoadingMessage("This takes about a minute for 2500 tasks");
        const messageTimer = setInterval(() => {
          setLoadingMessage((current) => {
            switch (current) {
              case "This takes about a minute for 2500 tasks":
                return "Getting tasks";
              case "Getting tasks":
                return "Getting parent child relationships";
              case "Getting parent child relationships":
                return "Calculating cumulative completion percentage";
              default:
                return current;
            }
          });
        }, 7000);

        const res = await Api.post("wbs/targetAnalytics", { targetId });
        
        // Sort tasks numerically by mspId (handles both string and number formats)
        const sortedTasks = res?.data?.sort((a, b) => 
          parseInt(a.mspId, 10) - parseInt(b.mspId, 10)
        ) || [];
        
        setTasks(sortedTasks);
        setTarget(res?.target);

        // Optional: Display last calculation time
        if (res?.target.lastCPMCalculation) {
          console.log(
            `Last CPM calculation: ${moment(
              res?.target?.lastCPMCalculation
            ).fromNow()}`
          );
        }

        // Create taskMap and identify leaf tasks
        const newTaskMap = {};
        const newLeafTasks = [];
        const roots = [];

        
        sortedTasks.forEach((task) => {
          task.children = [];
          newTaskMap[task._id] = task;
          if (task.parentTask && newTaskMap[task.parentTask]) {
            newTaskMap[task.parentTask].children.push(task);
          } else {
            roots.push(task);
          }
        });

        // Identify leaf tasks
        Object.values(newTaskMap).forEach((task) => {
          if (task.children.length === 0) {
            newLeafTasks.push(task._id);
          }
        });

        setTaskMap(newTaskMap);
        setLeafTasks(newLeafTasks);

        clearInterval(messageTimer);
        setLoadingMessage("");
      } catch (error) {
        console.error("Failed to fetch analytics:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTasks();
  }, [targetId]);

  const getLoadingMessageStyle = (message) => {
    switch (message) {
      case "This takes about a minute for 2500 tasks":
        return classes.blueText;
      case "Getting tasks":
        return classes.orangeText;
      case "Getting parent child relationships":
        return classes.greenText;
      case "Calculating cumulative completion percentage":
        return classes.purpleText;
      default:
        return "";
    }
  };

  const getLoadingMessageEmoji = (message) => {
    switch (message) {
      case "This takes about a minute for 2500 tasks":
        return "⏳";
      case "Getting tasks":
        return "📋";
      case "Getting parent child relationships":
        return "🔄";
      case "Calculating cumulative completion percentage":
        return "📊";
      default:
        return "";
    }
  };

  const toggleExpand = (taskId) => {
    setExpandedTasks((prev) => ({ ...prev, [taskId]: !prev[taskId] }));
  };

  // Build the flattened tree structure with depth information
  const items = useMemo(() => {
    // Use the taskMap from state
    const roots = [];
    Object.values(taskMap).forEach((task) => {
      if (!task.parentTask || !taskMap[task.parentTask]) {
        roots.push(task);
      }
    });

    const flatten = (nodes, depth = 0) => {
      let result = [];
      nodes.forEach((node) => {
        result.push({ ...node, depth });
        if (node.children.length > 0) {
          result = result.concat(flatten(node.children, depth + 1));
        }
      });
      return result;
    };

    return flatten(roots);
  }, [taskMap]);

  // Get project start date
  const projectStartDate = React.useMemo(() => {
    return (
      tasks.reduce((earliest, task) => {
        const taskDate = task.plannedStart || task.startDate;
        if (taskDate && (!earliest || taskDate < earliest)) {
          return taskDate;
        }
        return earliest;
      }, null) || new Date()
    );
  }, [tasks]);

  console.log(projectStartDate, " is the projectStartDate");

  const getAncestorIds = (taskId) => {
    const ancestors = [];
    let currentTask = taskMap[taskId];
    while (currentTask && currentTask.parentTask) {
      ancestors.push(currentTask.parentTask);
      currentTask = taskMap[currentTask.parentTask];
    }
    return ancestors;
  };

  const filteredItems = useMemo(() => {
    const matchingLeafTaskIds = leafTasks.filter((taskId) => {
      const task = taskMap[taskId];

      // Apply search term
      if (
        searchTerm &&
        !task.taskName.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return false;
      }

      // Updated date filtering logic
      if (
        filters.plannedStartAfter &&
        moment(task.plannedStart).isBefore(filters.plannedStartAfter, "day")
      )
        return false;
      if (
        filters.plannedStartBefore &&
        moment(task.plannedStart).isAfter(filters.plannedStartBefore, "day")
      )
        return false;

      if (
        filters.plannedFinishAfter &&
        moment(task.plannedFinish).isBefore(filters.plannedFinishAfter, "day")
      )
        return false;
      if (
        filters.plannedFinishBefore &&
        moment(task.plannedFinish).isAfter(filters.plannedFinishBefore, "day")
      )
        return false;

      if (
        filters.startDateAfter &&
        moment(task.startDate).isBefore(filters.startDateAfter, "day")
      )
        return false;
      if (
        filters.startDateBefore &&
        moment(task.startDate).isAfter(filters.startDateBefore, "day")
      )
        return false;

      if (
        filters.endDateAfter &&
        moment(task.endDate).isBefore(filters.endDateAfter, "day")
      )
        return false;
      if (
        filters.endDateBefore &&
        moment(task.endDate).isAfter(filters.endDateBefore, "day")
      )
        return false;

      if (filters.minProgress && task.progress < parseInt(filters.minProgress))
        return false;
      if (filters.maxProgress && task.progress > parseInt(filters.maxProgress))
        return false;

      return true;
    });

    const ancestorIds = new Set();
    matchingLeafTaskIds.forEach((taskId) => {
      const ancestors = getAncestorIds(taskId);
      ancestors.forEach((id) => ancestorIds.add(id));
    });

    const idsToInclude = new Set([...matchingLeafTaskIds, ...ancestorIds]);

    return items.filter((item) => idsToInclude.has(item._id));
  }, [items, searchTerm, filters, leafTasks, taskMap]);

  const sortItems = (items) => {
    if (!sortConfig.key) return items;

    return [...items].sort((a, b) => {
      let aValue = a[sortConfig.key];
      let bValue = b[sortConfig.key];

      // Special handling for dates
      if (
        ["plannedStart", "plannedFinish", "startDate", "endDate"].includes(
          sortConfig.key
        )
      ) {
        aValue = aValue ? new Date(aValue).getTime() : 0;
        bValue = bValue ? new Date(bValue).getTime() : 0;
      }

      // Special handling for progress (numeric)
      if (["progress"].includes(sortConfig.key)) {
        aValue = parseFloat(aValue) || 0;
        bValue = parseFloat(bValue) || 0;
      }

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const visibleItems = useMemo(() => {
    const filtered = filteredItems.filter((item) => {
      if (item.depth === 0) return true;
      let parent = taskMap[item.parentTask];
      while (parent) {
        if (!expandedTasks[parent._id]) return false;
        parent = taskMap[parent.parentTask];
      }
      return true;
    });

    return sortItems(filtered);
  }, [filteredItems, expandedTasks, taskMap, sortConfig]);

  const getItemSize = () => 50;

  const handleDialogOpen = (task) => {
    setSelectedTask(task);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedTask(null);
  };

  const handleDeleteTask = async (deleteChildren) => {
    try {
      await Api.post("wbs/deleteTask", {
        taskId: selectedTask._id,
        deleteChildren,
      });

      // Update local state instead of reloading
      if (deleteChildren) {
        // Remove the task and all its descendants
        setTasks((prevTasks) =>
          prevTasks.filter((task) => {
            const isDescendant = task.ancestors?.includes(selectedTask._id);
            const isSelectedTask = task._id === selectedTask._id;
            return !isDescendant && !isSelectedTask;
          })
        );
      } else {
        // Remove only the selected task and update its children
        setTasks((prevTasks) => {
          // First, get all tasks except the one being deleted
          const remainingTasks = prevTasks.filter(
            (task) => task._id !== selectedTask._id
          );

          // Then update the children of the deleted task
          return remainingTasks.map((task) => {
            if (task.parentTask === selectedTask._id) {
              return {
                ...task,
                parentTask: selectedTask.parentTask,
                ancestors: (task.ancestors || []).filter(
                  (id) => id !== selectedTask._id
                ),
              };
            }
            return task;
          });
        });
      }

      // Clear expanded state for deleted task
      setExpandedTasks((prev) => {
        const newState = { ...prev };
        delete newState[selectedTask._id];
        return newState;
      });
    } catch (error) {
      console.error("Failed to delete task:", error);
    }
    handleDialogClose();
  };

  const calculateDelay = (task, currentDate = new Date()) => {
    // If this is a parent task, get max child delay
    if (task.children && task.children.length > 0) {
      return Math.max(
        ...task.children.map((child) => calculateDelay(child, currentDate))
      );
    }

    // For leaf tasks:
    // 1) Safely parse plannedStart & plannedFinish
    const plannedStart = task.plannedStart ? new Date(task.plannedStart) : null;
    const plannedFinish = task.plannedFinish
      ? new Date(task.plannedFinish)
      : null;

    // 2) Return 0 if planned dates are invalid or missing
    if (!plannedStart || isNaN(plannedStart)) return 0;
    if (!plannedFinish || isNaN(plannedFinish)) return 0;

    // 3) Calculate delay
    // Hasn't started yet but today's date is past plannedStart
    if (task.progress === 0 && currentDate > plannedStart) {
      return Math.ceil((currentDate - plannedStart) / (1000 * 60 * 60 * 24));
    }

    // Completed
    if (task.progress === 100) {
      // No actual start/end tracking => no delay
      if (!task.startDate || !task.endDate || task.startDate === task.endDate) {
        return 0;
      }
      const actualEnd = task.endDate ? new Date(task.endDate) : currentDate;
      return Math.ceil((actualEnd - plannedFinish) / (1000 * 60 * 60 * 24));
    }

    // In progress
    if (task.progress > 0 && task.progress < 100) {
      // Use the outer function's currentDate
      const duration = task.duration || 1;
      const timeSpentDays = (duration * task.progress) / 100;
      const estStartDate = new Date(
        currentDate - timeSpentDays * 24 * 60 * 60 * 1000
      );
      return Math.ceil((estStartDate - plannedStart) / (1000 * 60 * 60 * 24));
    }

    return 0;
  };

  const calculateCumulativeProgress = (task) => {
    // For leaf nodes, return their own progress
    if (!task.children || task.children.length === 0) {
      return task.progress;
    }

    // For parent nodes, calculate average of all leaf nodes underneath
    const getAllLeafNodes = (node) => {
      if (!node.children || node.children.length === 0) {
        return [node];
      }
      return node.children.flatMap((child) => getAllLeafNodes(child));
    };

    const leafNodes = getAllLeafNodes(task);
    const totalProgress = leafNodes.reduce(
      (sum, node) => sum + node.progress,
      0
    );
    return Math.round(totalProgress / leafNodes.length);
  };

  const handleColumnVisibilityChange = (columnName) => {
    setColumnVisibility((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  const ColumnVisibilityMenu = () => (
    <Popover
      open={Boolean(columnMenuAnchorEl)}
      anchorEl={columnMenuAnchorEl}
      onClose={() => setColumnMenuAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card sx={{ p: 2, maxHeight: "400px", overflowY: "auto" }}>
        <Stack spacing={1}>
          <Typography variant="subtitle2">Show/Hide Columns</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.id}
                onChange={() => handleColumnVisibilityChange("id")}
              />
            }
            label="ID"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.taskName}
                onChange={() => handleColumnVisibilityChange("taskName")}
              />
            }
            label="Task Name"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.progress}
                onChange={() => handleColumnVisibilityChange("progress")}
              />
            }
            label="Progress"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.cumulative}
                onChange={() => handleColumnVisibilityChange("cumulative")}
              />
            }
            label="Cumulative Progress"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.delay}
                onChange={() => handleColumnVisibilityChange("delay")}
              />
            }
            label="Delay"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.duration}
                onChange={() => handleColumnVisibilityChange("duration")}
              />
            }
            label="Duration"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.plannedStart}
                onChange={() => handleColumnVisibilityChange("plannedStart")}
              />
            }
            label="Planned Start"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.plannedFinish}
                onChange={() => handleColumnVisibilityChange("plannedFinish")}
              />
            }
            label="Planned Finish"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.startDate}
                onChange={() => handleColumnVisibilityChange("startDate")}
              />
            }
            label="Start Date"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.endDate}
                onChange={() => handleColumnVisibilityChange("endDate")}
              />
            }
            label="End Date"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.earlyStart}
                onChange={() => handleColumnVisibilityChange("earlyStart")}
              />
            }
            label="Early Start (eS)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.earlyFinish}
                onChange={() => handleColumnVisibilityChange("earlyFinish")}
              />
            }
            label="Early Finish (eF)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.latestStart}
                onChange={() => handleColumnVisibilityChange("latestStart")}
              />
            }
            label="Latest Start (lS)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.latestFinish}
                onChange={() => handleColumnVisibilityChange("latestFinish")}
              />
            }
            label="Latest Finish (lF)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.totalFloat}
                onChange={() => handleColumnVisibilityChange("totalFloat")}
              />
            }
            label="Total Float (tF)"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={columnVisibility.predecessors}
                onChange={() => handleColumnVisibilityChange("predecessors")}
              />
            }
            label="Predecessors"
          />
        </Stack>
      </Card>
    </Popover>
  );

  const TableHeader = () => {
    const renderSortIcon = (key) => {
      if (sortConfig.key !== key) {
        return <SortIcon className={classes.sortIcon} />;
      }
      return sortConfig.direction === "asc" ? (
        <ArrowUpwardIcon className={`${classes.sortIcon} active`} />
      ) : (
        <ArrowDownwardIcon className={`${classes.sortIcon} active`} />
      );
    };

    const handleSort = (key) => {
      if (sortConfig.key === key && sortConfig.direction === "desc") {
        // Clear sort if already sorted desc
        setSortConfig({ key: null, direction: "asc" });
      } else {
        requestSort(key);
      }
    };

    return (
      <Box className={classes.header}>
        {columnVisibility.id && (
          <Box
            className={`${classes.taskId} ${classes.columnHeader}`}
            onClick={() => handleSort("mspId")}
          >
            <Typography>ID</Typography>
            {renderSortIcon("mspId")}
          </Box>
        )}
        {columnVisibility.taskName && (
          <Box
            className={`${classes.taskName} ${classes.columnHeader}`}
            onClick={() => handleSort("taskName")}
          >
            <Typography>Task Name</Typography>
            {renderSortIcon("taskName")}
          </Box>
        )}
        {columnVisibility.progress && (
          <Box
            className={`${classes.progress} ${classes.columnHeader}`}
            onClick={() => handleSort("progress")}
          >
            <Typography>Progress</Typography>
            {renderSortIcon("progress")}
          </Box>
        )}
        {columnVisibility.cumulative && (
          <Box
            className={`${classes.cumulativeProgress} ${classes.columnHeader}`}
            onClick={() => handleSort("cumulative")}
          >
            <Typography>Cumulative</Typography>
            {renderSortIcon("cumulative")}
          </Box>
        )}
        {columnVisibility.delay && (
          <Box
            className={`${classes.delay} ${classes.columnHeader}`}
            onClick={() => handleSort("delay")}
          >
            <Typography>Delay (days)</Typography>
            {renderSortIcon("delay")}
          </Box>
        )}
        {columnVisibility.duration && (
          <Box
            className={`${classes.duration} ${classes.columnHeader}`}
            onClick={() => handleSort("duration")}
          >
            <Typography>Duration (days)</Typography>
            {renderSortIcon("duration")}
          </Box>
        )}
        {columnVisibility.plannedStart && (
          <Box
            className={`${classes.date} ${classes.columnHeader}`}
            onClick={() => handleSort("plannedStart")}
          >
            <Typography>Planned Start</Typography>
            {renderSortIcon("plannedStart")}
          </Box>
        )}
        {columnVisibility.plannedFinish && (
          <Box
            className={`${classes.date} ${classes.columnHeader}`}
            onClick={() => handleSort("plannedFinish")}
          >
            <Typography>Planned Finish</Typography>
            {renderSortIcon("plannedFinish")}
          </Box>
        )}
        {columnVisibility.startDate && (
          <Box
            className={`${classes.date} ${classes.columnHeader}`}
            onClick={() => handleSort("startDate")}
          >
            <Typography>Start Date</Typography>
            {renderSortIcon("startDate")}
          </Box>
        )}
        {columnVisibility.endDate && (
          <Box
            className={`${classes.date} ${classes.columnHeader}`}
            onClick={() => handleSort("endDate")}
          >
            <Typography>End Date</Typography>
            {renderSortIcon("endDate")}
          </Box>
        )}
        {columnVisibility.earlyStart && (
          <Box
            className={`${classes.eseflsltf} ${classes.columnHeader}`}
            onClick={() => handleSort("earlyStart")}
          >
            <Typography>Early Start (eS)</Typography>
            {renderSortIcon("earlyStart")}
          </Box>
        )}
        {columnVisibility.earlyFinish && (
          <Box
            className={`${classes.eseflsltf} ${classes.columnHeader}`}
            onClick={() => handleSort("earlyFinish")}
          >
            <Typography>Early Finish (eF)</Typography>
            {renderSortIcon("earlyFinish")}
          </Box>
        )}
        {columnVisibility.latestStart && (
          <Box
            className={`${classes.eseflsltf} ${classes.columnHeader}`}
            onClick={() => handleSort("latestStart")}
          >
            <Typography>Latest Start (lS)</Typography>
            {renderSortIcon("latestStart")}
          </Box>
        )}
        {columnVisibility.latestFinish && (
          <Box
            className={`${classes.eseflsltf} ${classes.columnHeader}`}
            onClick={() => handleSort("latestFinish")}
          >
            <Typography>Latest Finish (lF)</Typography>
            {renderSortIcon("latestFinish")}
          </Box>
        )}
        {columnVisibility.totalFloat && (
          <Box
            className={`${classes.eseflsltf} ${classes.columnHeader}`}
            onClick={() => handleSort("totalFloat")}
          >
            <Typography>Total Float (tF)</Typography>
            {renderSortIcon("totalFloat")}
          </Box>
        )}
        {columnVisibility.predecessors && (
          <Box
            className={`${classes.predecessors} ${classes.columnHeader}`}
            onClick={() => handleSort("predecessors")}
          >
            <Typography>Predecessors</Typography>
            {renderSortIcon("predecessors")}
          </Box>
        )}
        <Box className={`${classes.headerActions} ${classes.columnHeader}`}>
          <Typography>Actions</Typography>
        </Box>
      </Box>
    );
  };

  const Row = ({ index, style }) => {
    const item = visibleItems[index];
    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedTasks[item._id];
    const delay = calculateDelay(item);
    const cumulativeProgress = calculateCumulativeProgress(item);

    // Determine effective start date
    const effectiveStartDate =
      item.startDate || (item.progress > 0 ? item.plannedStart : null);

    // Determine effective end date
    const effectiveEndDate =
      item.endDate || (item.progress === 100 ? item.plannedFinish : null);

    return (
      <Box style={style} className={classes.row}>
        {columnVisibility.id && (
          <Typography className={classes.taskId}>{item.mspId || ""}</Typography>
        )}
        {columnVisibility.taskName && (
          <Box
            className={classes.taskName}
            style={{ paddingLeft: item.depth * 20 }}
          >
            {hasChildren && (
              <IconButton size="small" onClick={() => toggleExpand(item._id)}>
                {isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              </IconButton>
            )}
            <Typography>{item.taskName}</Typography>
          </Box>
        )}
        {columnVisibility.progress && (
          <Typography className={classes.progress}>{item.progress}%</Typography>
        )}
        {columnVisibility.cumulative && (
          <Typography className={classes.cumulativeProgress}>
            {cumulativeProgress}%
          </Typography>
        )}
        {columnVisibility.delay && (
          <Typography
            className={`${classes.delay} ${
              delay > 0 ? classes.negativeDelay : ""
            }`}
          >
            {delay}
          </Typography>
        )}
        {columnVisibility.duration && (
          <Typography className={classes.duration}>{item.duration}</Typography>
        )}
        {columnVisibility.plannedStart && (
          <Typography className={classes.date}>
            {moment(item.plannedStart).format("DD/MM/YYYY")}
          </Typography>
        )}
        {columnVisibility.plannedFinish && (
          <Typography className={classes.date}>
            {moment(item.plannedFinish).format("DD/MM/YYYY")}
          </Typography>
        )}
        {columnVisibility.startDate && (
          <Typography className={classes.date}>
            {effectiveStartDate
              ? moment(effectiveStartDate).format("DD/MM/YYYY")
              : "-"}
          </Typography>
        )}
        {columnVisibility.endDate && (
          <Typography className={classes.date}>
            {effectiveEndDate
              ? moment(effectiveEndDate).format("DD/MM/YYYY")
              : "-"}
          </Typography>
        )}
        {columnVisibility.earlyStart && (
          <Typography className={classes.eseflsltf}>
            {item.eS !== undefined && item.eS !== null
              ? moment(projectStartDate)
                  .add(item.eS, "days")
                  .format("DD/MM/YYYY")
              : "-"}
          </Typography>
        )}
        {columnVisibility.earlyFinish && (
          <Typography className={classes.eseflsltf}>
            {item.eF !== undefined && item.eF !== null
              ? moment(projectStartDate)
                  .add(item.eF, "days")
                  .format("DD/MM/YYYY")
              : "-"}
          </Typography>
        )}
        {columnVisibility.latestStart && (
          <Typography className={classes.eseflsltf}>
            {item.lS !== undefined && item.lS !== null
              ? moment(projectStartDate)
                  .add(item.lS, "days")
                  .format("DD/MM/YYYY")
              : "-"}
          </Typography>
        )}
        {columnVisibility.latestFinish && (
          <Typography className={classes.eseflsltf}>
            {item.lF !== undefined && item.lF !== null
              ? moment(projectStartDate)
                  .add(item.lF, "days")
                  .format("DD/MM/YYYY")
              : "-"}
          </Typography>
        )}
        {columnVisibility.totalFloat && (
          <Typography className={classes.eseflsltf}>
            {item.tF !== undefined && item.tF !== null ? item.tF : "-"}
          </Typography>
        )}
        {columnVisibility.predecessors && (
          <Typography className={classes.predecessors}>
            {item.predecessorMap
              ?.map((pred) => {
                let str = pred.mspId;
                if (pred.relationType) str += pred.relationType;
                if (pred.lag) str += pred.lag;
                return str;
              })
              .join(", ") || ""}
          </Typography>
        )}
        <Box className={classes.actions}>
          <Typography style={{ display: "flex", alignItems: "center" }}>
            <SafetyCheck fontSize="small" />
            {item.checklistCount}
          </Typography>
          <IconButton
            size="small"
            onClick={() => window.open(`/planning/task/${item._id}`, "_blank")}
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
          {item.criticalPath && (
            <IconButton
              size="small"
              onClick={() => {
                setSelectedTaskForCriticalPath(item);
                setCriticalPathDrawerOpen(true);
              }}
            >
              <TimelineIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton size="small" onClick={() => handleDialogOpen(item)}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const clearFilters = () => {
    setSearchTerm("");
    setFilters({
      plannedStartAfter: null,
      plannedStartBefore: null,
      plannedFinishAfter: null,
      plannedFinishBefore: null,
      startDateAfter: null,
      startDateBefore: null,
      endDateAfter: null,
      endDateBefore: null,
      minProgress: "",
      maxProgress: "",
    });
  };

  const renderFilterPopover = () => (
    <Popover
      open={Boolean(filterAnchorEl)}
      anchorEl={filterAnchorEl}
      onClose={() => setFilterAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card sx={{ minWidth: 600 }}>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="subtitle2">Filters</Typography>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Stack direction="row" spacing={2}>
                <DatePicker
                  label="Planned Start After"
                  value={filters.plannedStartAfter}
                  onChange={(date) =>
                    setFilters((prev) => ({ ...prev, plannedStartAfter: date }))
                  }
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {filters.plannedStartAfter && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters((prev) => ({
                                      ...prev,
                                      plannedStartAfter: null,
                                    }));
                                  }}
                                  edge="end"
                                  aria-label="clear planned start date"
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    ),
                  }}
                />
                <DatePicker
                  label="Planned Start Before"
                  value={filters.plannedStartBefore}
                  onChange={(date) =>
                    setFilters((prev) => ({
                      ...prev,
                      plannedStartBefore: date,
                    }))
                  }
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {filters.plannedStartBefore && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters((prev) => ({
                                      ...prev,
                                      plannedStartBefore: null,
                                    }));
                                  }}
                                  edge="end"
                                  aria-label="clear planned start date"
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </Stack>
              {/* Planned Finish Row */}
              <Stack direction="row" spacing={2}>
                {/* Similar structure for Planned Finish After/Before */}
                {/* Copy the DatePicker structure above and update the labels and values */}

                <DatePicker
                  label="Planned Finish After"
                  value={filters.plannedFinishAfter}
                  onChange={(date) =>
                    setFilters((prev) => ({
                      ...prev,
                      plannedFinishAfter: date,
                    }))
                  }
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {filters.plannedFinishAfter && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters((prev) => ({
                                      ...prev,
                                      plannedFinishAfter: null,
                                    }));
                                  }}
                                  edge="end"
                                  aria-label="clear planned finish date"
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    ),
                  }}
                />

                <DatePicker
                  label="Planned Finish Before"
                  value={filters.plannedFinishBefore}
                  onChange={(date) =>
                    setFilters((prev) => ({
                      ...prev,
                      plannedFinishBefore: date,
                    }))
                  }
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {filters.plannedFinishBefore && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters((prev) => ({
                                      ...prev,
                                      plannedFinishBefore: null,
                                    }));
                                  }}
                                  edge="end"
                                  aria-label="clear planned finish date"
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </Stack>

              {/* Start Date Row */}
              <Stack direction="row" spacing={2}>
                {/* Similar structure for Start Date After/Before */}
                <DatePicker
                  label="Start Date After"
                  value={filters.startDateAfter}
                  onChange={(date) =>
                    setFilters((prev) => ({ ...prev, startDateAfter: date }))
                  }
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {filters.startDateAfter && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters((prev) => ({
                                      ...prev,
                                      startDateAfter: null,
                                    }));
                                  }}
                                  edge="end"
                                  aria-label="clear start date"
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    ),
                  }}
                />
                <DatePicker
                  label="Start Date Before"
                  value={filters.startDateBefore}
                  onChange={(date) =>
                    setFilters((prev) => ({ ...prev, startDateBefore: date }))
                  }
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {filters.startDateBefore && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters((prev) => ({
                                      ...prev,
                                      startDateBefore: null,
                                    }));
                                  }}
                                  edge="end"
                                  aria-label="clear start date"
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </Stack>

              {/* End Date Row */}
              <Stack direction="row" spacing={2}>
                {/* Similar structure for End Date After/Before */}

                {/* End Date Picker with Clear Button */}
                <DatePicker
                  label="End Date After"
                  value={filters.endDateAfter}
                  onChange={(date) =>
                    setFilters((prev) => ({ ...prev, endDateAfter: date }))
                  }
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {filters.endDateAfter && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters((prev) => ({
                                      ...prev,
                                      endDateAfter: null,
                                    }));
                                  }}
                                  edge="end"
                                  aria-label="clear end date"
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    ),
                  }}
                />
                {/* End Date Picker with Clear Button */}
                <DatePicker
                  label="End Date Before"
                  value={filters.endDateBefore}
                  onChange={(date) =>
                    setFilters((prev) => ({ ...prev, endDateBefore: date }))
                  }
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {filters.endDateBefore && (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFilters((prev) => ({
                                      ...prev,
                                      endDateBefore: null,
                                    }));
                                  }}
                                  edge="end"
                                  aria-label="clear end date"
                                  size="small"
                                >
                                  <ClearIcon fontSize="small" />
                                </IconButton>
                              )}
                              {params.InputProps?.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </Stack>

              {/* Start Date Picker with Clear Button */}
            </LocalizationProvider>
            {/* Minimum Progress Field with Clear Button */}
            <Stack direction="row" spacing={2}>
              <TextField
                label="Minimum Progress"
                value={filters.minProgress}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    minProgress: e.target.value,
                  }))
                }
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <>
                      {filters.minProgress && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setFilters((prev) => ({
                                ...prev,
                                minProgress: "",
                              }))
                            }
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                      <InputAdornment position="end">%</InputAdornment>
                    </>
                  ),
                }}
              />
              <TextField
                label="Maximum Progress"
                value={filters.maxProgress}
                onChange={(e) =>
                  setFilters((prev) => ({
                    ...prev,
                    maxProgress: e.target.value,
                  }))
                }
                type="number"
                size="small"
                InputProps={{
                  endAdornment: (
                    <>
                      {filters.maxProgress && (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setFilters((prev) => ({
                                ...prev,
                                maxProgress: "",
                              }))
                            }
                            edge="end"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                      <InputAdornment position="end">%</InputAdornment>
                    </>
                  ),
                }}
              />
            </Stack>
            <Button
              variant="outlined"
              onClick={clearFilters}
              startIcon={<ClearIcon />}
            >
              Clear Filters
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Popover>
  );

  const handleRecalculateCPM = async () => {
    try {
      setCalculatingCPM(true);
      const response = await Api.post("wbs/recalculate-cpm", { targetId });

      // Update tasks with new CPM data
      if (response?.data) {
        setTasks(response.data);
      }

      // Update target with new lastCPMCalculation time
      if (response?.lastCalculated) {
        setTarget((prev) => ({
          ...prev,
          lastCPMCalculation: response.lastCalculated,
        }));
      }
    } catch (error) {
      console.error("Failed to recalculate CPM:", error);
    } finally {
      setCalculatingCPM(false);
    }
  };

  return (
    <Box className={classes.container}>
      <Box sx={{ mb: 2, p: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <TextField
            placeholder="Search tasks..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            size="small"
            sx={{ width: 300 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchTerm && (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={() => setSearchTerm("")}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setAddTasksDrawerOpen(true)}
          >
            Add Multiple Tasks
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<TimerIcon />}
            onClick={() => setDelayTrackerOpen(true)}
          >
            Delay Tracker
          </Button>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={
                calculatingCPM ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <CalculateIcon />
                )
              }
              onClick={handleRecalculateCPM}
              disabled={calculatingCPM}
            >
              {calculatingCPM ? "Calculating..." : "CPM"}
            </Button>
            {target?.lastCPMCalculation && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ ml: 1 }}
              >
                Last calculated: {moment(target.lastCPMCalculation).fromNow()}
              </Typography>
            )}
          </Box>
          <IconButton onClick={(e) => setFilterAnchorEl(e.currentTarget)}>
            <FilterListIcon />
          </IconButton>
          <IconButton
            onClick={(e) => setColumnMenuAnchorEl(e.currentTarget)}
            title="Show/Hide Columns"
          >
            <ViewColumnIcon />
          </IconButton>
        </Stack>
      </Box>

      {loading ? (
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <CircularProgress />
          {loadingMessage && (
            <Typography
              className={`${classes.loadingMessage} ${getLoadingMessageStyle(
                loadingMessage
              )}`}
            >
              <span className="emoji">
                {getLoadingMessageEmoji(loadingMessage)}
              </span>
              {loadingMessage}
            </Typography>
          )}
        </Box>
      ) : (
        <Box className={classes.tableContainer}>
          <Box className={classes.contentWrapper}>
            <TableHeader />
            <List
              height={600}
              width="100%"
              itemCount={visibleItems.length}
              itemSize={getItemSize}
              overscanCount={5}
            >
              {Row}
            </List>
          </Box>
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>
          {selectedTask ? `Task: ${selectedTask.taskName}` : ""}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="subtitle1" color="text.secondary">
              Choose action:
            </Typography>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              onClick={() => {
                setAddTasksDrawerOpen(true);
                setSelectedTaskForAdd(selectedTask);
                handleDialogClose();
              }}
            >
              Add subtasks
            </Button>
            <Button
              startIcon={<DeleteOutlineIcon />}
              variant="outlined"
              color="error"
              onClick={() => handleDeleteTask(false)}
            >
              Delete this task only
            </Button>
            <Button
              startIcon={<DeleteForeverIcon />}
              variant="contained"
              color="error"
              onClick={() => handleDeleteTask(true)}
            >
              Delete this task and all children
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <AddManyTasks
        open={addTasksDrawerOpen}
        onClose={(saved) => {
          setAddTasksDrawerOpen(false);
          setSelectedTaskForAdd(null);
          if (saved) {
            // Refresh the task list
            // fetchTasks();
          }
        }}
        targetId={targetId}
        parentTask={selectedTaskForAdd?._id && taskMap[selectedTaskForAdd?._id]}
      />

      <DelayTracker
        open={delayTrackerOpen}
        onClose={() => setDelayTrackerOpen(false)}
        tasks={tasks}
        calculateDelay={calculateDelay}
      />
      <CriticalPathDrawer
        open={criticalPathDrawerOpen}
        onClose={() => {
          setCriticalPathDrawerOpen(false);
          setSelectedTaskForCriticalPath(null);
        }}
        task={selectedTaskForCriticalPath}
        tasks={tasks}
      />
      {renderFilterPopover()}
      <ColumnVisibilityMenu />
    </Box>
  );
};

export default TargetAnalytics;
