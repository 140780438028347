import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import emptyIcon from "../../../Assets/emptyData.svg"
import LessText from '../../styled/CommonComponents/LessText';
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflowX: "auto",
    },
    root: {
        width: "100%",
        minWidth: "1200px",
        height: "100%",
        overflowX: "auto",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        "& .MuiTablePagination-root": {
            border: "1px solid #d2d1d1",
            marginTop: "-3px"
        }
    },
    container: {
        width: '100%',
        height: "calc(100% - 52px)",
    },
    tbHeaderCell: {
        color: "#696969",
        fontSize: "15px",
        padding: "0px",
    },
    tbBodyCell: {
        padding: "8px 5px"
    },
    pageLoaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            width: "250px",
            height: "auto"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "400",
            marginTop: "10px"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    }
}));

export default function TransactionsTable({
    walletId, getDataApiHitFun, getDataObj, getNumberOfDataRowApiHit,
    getDataNumberObj, emptyText, setSelectedTx, setOpenDetails, isGenerator
}) {
    const history = useHistory();
    const classes = useStyles();

    const [allData, setAllData] = useState([{ data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }])
    const [totalPages, setTotalPages] = useState(0)
    const [pagesBool, setPagesBool] = useState([true])
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)


    const getDataForTable = async (allDatak) => {
        await getDataApiHitFun({
            ...getDataObj,
            curPage: (curPage - 1),
            limit: pageLimit
        })
            .then((allDataArray) => {
                console.log(allDataArray)
                if (allDataArray && allDataArray.length > 0) {
                    let pageStart = (curPage - 1) * pageLimit

                    let updatedDataArray = [...allDatak]

                    allDataArray.map((data) => {
                        updatedDataArray[pageStart] = data;
                        pageStart++;
                    })

                    setAllData(updatedDataArray)
                } else {
                    setAllData([])
                }
            })
            .catch((error) => {
                console.log(error)
                setAllData([])
            })
    }

    useEffect(() => {
        getNumberOfDataRowApiHit(getDataNumberObj)
            .then((data) => {
                let totalNumbers = data[0]?.numberOfDocs || 0
                let dataArr = [];

                if (totalNumbers && totalNumbers !== 0) {
                    for (let i = 0; i < totalNumbers; i++) {
                        dataArr.push({ data: null })
                    }
                    setAllData(dataArr)

                    let locTotalPage = Math.ceil(totalNumbers / pageLimit);
                    setTotalPages(locTotalPage)

                    let arr = []
                    for (let i = 0; i < locTotalPage; i++) {
                        if (i === 0) {
                            arr.push(true)
                        } else {
                            arr.push(false)
                        }
                    }
                    setPagesBool(arr)
                }

                getDataForTable(dataArr)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [walletId])

    useEffect(() => {
        if (!pagesBool[curPage - 1] && curPage !== 1) {
            getDataForTable(allData)
            let arr = [...pagesBool]
            arr[curPage - 1] = true
            setPagesBool(arr)
        }
    }, [curPage])

    const getFormatedData = (data) => {
        let formatedData = new Intl.NumberFormat('en-GB', { notation: "compact", compactDisplay: "short" }).format(data);
        return formatedData;
    }

    const showTransactionType = (tx) => {
        if (tx?.type === "Invoice" && walletId == tx?.firstPartyWallet) {
            return "Invoice";
        } else if (tx?.type === "Invoice") {
            return "Bill";
        } if (tx?.type === "Bill" && walletId == tx?.firstPartyWallet) {
            return "Expense";
        } else if (tx?.type === "Bill") {
            return "Receipt";
        } if (tx?.type === "DebitNote" && walletId == tx?.firstPartyWallet) {
            return "DebitNote";
        } else if (tx?.type === "DebitNote") {
            return "Bill";
        } if (tx?.type === "CreditNote" && walletId == tx?.firstPartyWallet) {
            return "CreditNote";
        } else if (tx?.type === "CreditNote") {
            return "Receipt";
        }
    }

    return (
        <div className={classes.mainCont} >
            {allData && allData.length > 0 ? (<Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "15px", width: "65px" }} >
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "95px" }} >
                                    Date
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "80px" }} >
                                    Type
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "70px" }} >
                                    No
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "150px" }} >
                                    Customer
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                                    Memo
                                </TableCell>
                                {isGenerator ? (<>
                                    <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "120px" }} >
                                        Start Date
                                    </TableCell>
                                    <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
                                        Next Date
                                    </TableCell>
                                    <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "90px" }} >
                                        Amount
                                    </TableCell>
                                    <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "15px", width: "110px" }} >
                                        Status
                                    </TableCell>
                                </>) : (<>
                                    {getDataObj?.type === "All" ? (
                                        <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                                            Accounting/Due Date
                                        </TableCell>
                                    ) : (<>{(getDataObj?.type === "Invoice" || getDataObj?.type === "Bill") ? (
                                        <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "120px" }} >
                                            Due Date
                                        </TableCell>
                                    ) : (
                                        <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                                            Accounting
                                        </TableCell>
                                    )}</>)}
                                    <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "90px" }} >
                                        Amount
                                    </TableCell>
                                    <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
                                        Amount Paid
                                    </TableCell>
                                    <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "15px", width: "110px" }} >
                                        Status
                                    </TableCell>
                                </>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allData && allData.length > 0 && allData.slice((curPage - 1) * pageLimit, (curPage - 1) * pageLimit + pageLimit).map((data) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={data._id}
                                        onClick={() => {
                                            if (data?._id) {
                                                setSelectedTx(data);
                                                setOpenDetails(true);
                                            }
                                        }}
                                        style={data?._id ? { cursor: "pointer" } : {}}
                                    >
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "15px", width: "65px" }} >
                                            {data?._id ? (
                                                <Avatar
                                                    alt={walletId === data?.firstPartyWallet ? data?.secondParty?.parent?.displayName : data?.firstParty?.parent?.displayName}
                                                    src={walletId === data?.firstPartyWallet ? data?.secondParty?.parent?.displayPicture?.url : data?.firstParty?.parent?.displayPicture?.url}
                                                    style={{ width: "30px", height: "30px" }}
                                                />
                                            ) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "90px" }} >
                                            {data?._id ? (<>
                                                {new Date(data?.createdAt).getMonth() + 1 + "/" + new Date(data?.createdAt).getDate() + "/" + new Date(data?.createdAt).getFullYear()}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "80px" }} >
                                            {data?._id ? (<>{showTransactionType(data)}</>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "70px" }} >
                                            {data?._id ? (<>
                                                {data?.txNo}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "150px" }} >
                                            {data?._id ? (<>
                                                <LessText
                                                    limit={15}
                                                    string={walletId === data?.firstPartyWallet ? data?.secondParty?.parent?.displayName : data?.firstParty?.parent?.displayName}
                                                />
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", fontSize: "12px", wordBreak: "break-all" }} >
                                            {data?._id ? (<>
                                                <LessText
                                                    limit={70}
                                                    string={data?.memo}
                                                />
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        {isGenerator ? (<>
                                            <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "120px" }} >
                                                {data?._id ? (<>
                                                    {data?.schedulingData?.startDate ? `${moment(data?.schedulingData?.startDate).format('DD MMM YYYY')}` : `none`}
                                                </>) : (
                                                    <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                )}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "90px" }} >
                                                {data?._id ? (<>
                                                    {data?.schedulingData?.nextDate ? `${moment(data?.schedulingData?.nextDate).format('DD MMM YYYY')}` : `none`}
                                                </>) : (
                                                    <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                )}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "110px" }} >
                                                {data?._id ? (<>
                                                    ₹{getFormatedData(data?.finalAmount || 0)}
                                                </>) : (
                                                    <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                )}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "15px", width: "110px" }} >
                                                {data?._id ? (<>
                                                    {data?.schedulingData?.scheduled ? (<span style={{ color: "green" }} >Running</span>) : (<span style={{ color: "red" }} >Stoped</span>)}
                                                </>) : (
                                                    <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                )}
                                            </TableCell>
                                        </>) : (<>
                                            {((getDataObj?.type === "All" && (data?.type === "Invoice" || data?.type === "Bill")) || (getDataObj?.type === "Invoice" || getDataObj?.type === "Bill")) ? (
                                                <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "120px" }} >
                                                    {data?._id ? (<>
                                                        {new Date(data?.dueDate).getMonth() + 1 + "/" + new Date(data?.dueDate).getDate() + "/" + new Date(data?.dueDate).getFullYear()}
                                                    </>) : (
                                                        <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                    )}
                                                </TableCell>
                                            ) : (
                                                <TableCell align="center" className={classes.tbBodyCell} style={{ padding: "0px", width: "90px" }} >
                                                    {data?._id ? (<>
                                                        <Checkbox
                                                            color="primary"
                                                            disabled={true}
                                                            checked={!data?.notIntregratedAccounting}
                                                        />
                                                    </>) : (
                                                        <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                    )}
                                                </TableCell>
                                            )}
                                            <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "90px" }} >
                                                {data?._id ? (<>
                                                    ₹{getFormatedData(data?.finalAmount || 0)}
                                                </>) : (
                                                    <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                )}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "110px" }} >
                                                {data?._id ? (<>
                                                    ₹{getFormatedData(data?.amountPaid || 0)}
                                                </>) : (
                                                    <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                )}
                                            </TableCell>
                                            <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "15px", width: "110px" }} >
                                                {data?._id ? (<>
                                                    {data?.status}
                                                </>) : (
                                                    <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                                )}
                                            </TableCell>
                                        </>)}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
            </Paper>) : (<div className={classes.pageLoaderCont} >
                <img src={emptyIcon} />
                <h3>{emptyText}</h3>
            </div>)}
        </div>
    );
}