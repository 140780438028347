import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { makeStyles } from "@material-ui/core/styles"
import { getIssueEventsByLead } from "../api.call";
import EventAddEditDrawer from "../../eventCalendar/EventAddEditDrawer";
import EventDetails from "../../eventCalendar/EventDetails";
moment.locale("en-GB");
const localizer = momentLocalizer(moment);

let formats = {
    timeGutterFormat: 'HH:mm',
}

const useStyles = makeStyles((theme) => ({
    bodyCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .rbc-header": {
            width: "auto !important",
        },
        "& .rbc-agenda-event-cell": {
            width: "auto !important",
        },
        "& tr": {
            height: "auto !important",
        },
        "& td": {
            border: "1px solid #ececec"
        }
    }
}));

export default function PSLinkedEvents({ finrelId }) {
    const classes = useStyles();

    const [eventsData, setEventsData] = useState([]);
    const [openEventAddEditDrawer, setOpenEventAddEditDrawer] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [openEventDetails, setOpenEventDetails] = useState(false)
    const [editEventData, setEditEventData] = useState(null)

    const getAllEvents = async () => {
        await getIssueEventsByLead({ finrelId })
            .then((data) => {
                const locEventsData = data.map((s) => {
                    return {
                        id: s?._id,
                        title: s?.title,
                        allDay: false,
                        start: new Date(s?.startDate),
                        end: new Date(s?.endDate),
                    }
                })
                setEventsData(locEventsData)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (finrelId) {
            getAllEvents()
        }
    }, [finrelId])

    return (
        <div className={classes.bodyCont} >
            <EventAddEditDrawer
                openEventAddEditDrawer={openEventAddEditDrawer}
                setOpenEventAddEditDrawer={setOpenEventAddEditDrawer}
                getAllEvents={getAllEvents}
                editEventData={editEventData}
                setEditEventData={setEditEventData}
            />
            <Calendar
                views={["day", "agenda", "week", "month"]}
                selectable
                formats={formats}
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="agenda"
                events={eventsData}
                style={{ height: "100%" }}
                onSelectEvent={(event) => {
                    setSelectedEvent(event)
                    setOpenEventDetails(true)
                }}
                onSelectSlot={({ start, end }) => {
                    console.log(start, end)
                }}
            />
            <EventDetails
                openEventDetails={openEventDetails}
                setOpenEventDetails={setOpenEventDetails}
                selectedEvent={selectedEvent}
                setEditEventData={setEditEventData}
                getAllEvents={getAllEvents}
                setOpenEventAddEditDrawer={setOpenEventAddEditDrawer}
            />
        </div>
    );
}
