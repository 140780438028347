import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  TextField,
  Select,
  MenuItem,
  Chip,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import {
  addChecklistToParent,
  addCheckpointToParent,
  removeCheckpointFromParent,
  updateCheckpoint
} from './Api.call';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormBox from "../styled/generic/FormBox";
import { useSelector } from "react-redux";
import arrayToReducer from '../../helpers/arrayToReducer';
import CheckpointTreeView from './CheckpointTreeView';
import { makeStyles } from "@material-ui/core/styles";
import { getFiles } from '../blog/api.call';

const useStyles = makeStyles((theme) => ({
  loaderCont: {
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: "0px",
    left: "0px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1000000"
  }
}));

const CheckpointManager = ({
  libraryId,
  parentId,
  profileId,
  parentModelName,
  checkpointIds,
  checkpointDict,
  setCheckpointIds,
  setCheckpointDict,
  checklists,
  isImageUploaderActive,
  onImageUploaderActivate,
  onImageUploaderDeactivate,
  needToMakeDisable
}) => {
  const classes = useStyles();
  console.log(libraryId,' is the libraryID')
  const { user } = useSelector((state) => state.auth);
  const [newCheckpointDescription, setNewCheckpointDescription] = useState('');
  const [selectedChecklists, setSelectedChecklists] = useState([]);
  const [loader, setLoader] = useState(false);
  const [importingCheckpoints, setImportingCheckpoints] = useState(false);

  const handleChecklistChange = (event) => {
    const selectedIds = event.target.value;
    const newSelectedChecklists = checklists.filter(checklist => selectedIds.includes(checklist._id));
    setSelectedChecklists(newSelectedChecklists);
  };

  const handleImportCheckpoints = async () => {
    if (selectedChecklists.length > 0 && parentId && parentModelName) {
      setImportingCheckpoints(true);
      try {
        const data = await addChecklistToParent({
          parentId,
          parentModelName,
          checklistIds: selectedChecklists.map(cl => cl._id)
        });
        if (data && Array.isArray(data.checkpoints)) {
          const { newDict, idArr } = arrayToReducer([...Object.values(checkpointDict), ...data.checkpoints]);
          setCheckpointDict(newDict);
          setCheckpointIds(idArr);
        }
      } catch (error) {
        console.error("Error importing checkpoints from checklists:", error);
      } finally {
        setImportingCheckpoints(false);
      }
    }
  };

  const handleCheckpointToggle = useCallback(async (checkpointId) => {
    console.log('Toggling checkpoint:', checkpointId);
    try {
      const checkpoint = checkpointDict[checkpointId];
      if (!checkpoint) {
        console.error('Checkpoint not found:', checkpointId);
        return;
      }

      const newIsCompleted = !checkpoint.isCompleted;
      console.log('Current isCompleted:', checkpoint.isCompleted);
      console.log('New isCompleted value:', newIsCompleted);
      if (checkpointId) {
        const newCheckpointDict = {
          ...checkpointDict,
          [checkpointId]: { ...checkpoint, isCompleted: newIsCompleted }
        };
        console.log(newCheckpointDict, 'newCheckpointDict')
        setCheckpointDict(newCheckpointDict);

      }

      const updatedCheckpoint = await updateCheckpoint({
        checkpointId: checkpointId,
        updateData: { isCompleted: newIsCompleted },
        userId: user?._id
      });

      console.log('Updated checkpoint:', updatedCheckpoint);


    } catch (error) {
      console.error("Error updating checkpoint:", error);
    }
  }, [checkpointDict]);

  const handleAddCheckpoint = async () => {
    if (newCheckpointDescription.trim() && parentModelName) {
      try {
        const data = await addCheckpointToParent({
          parentId,
          parentModelName,
          checkpointData: { description: newCheckpointDescription }
        });
        console.log(data, ' is the res')
        if (data) {
          const newCheckpoint = data.checkpoints[data.checkpoints.length - 1];
          const newCheckpointDict = { ...checkpointDict, [newCheckpoint._id]: newCheckpoint };
          const newCheckpointIds = [...checkpointIds, newCheckpoint._id];
          setCheckpointDict(newCheckpointDict);
          setCheckpointIds(newCheckpointIds);

          setNewCheckpointDescription('');
        }
      } catch (error) {
        console.error("Error adding checkpoint:", error);
      }
    }
  };

  const handleRemoveCheckpoint = async (checkpointId) => {
    if (parentId && parentModelName) {
      try {
        await removeCheckpointFromParent({
          parentId,
          parentModelName,
          checkpointId
        });
        delete checkpointDict[checkpointId];

        const newCheckpointIds = checkpointIds.filter(id => id !== checkpointId);
        // setCheckpointDict(newCheckpointDict);
        setCheckpointIds(newCheckpointIds);

      } catch (error) {
        console.error("Error removing checkpoint:", error);
      }
    }
  };

  return (<>
    <Box>
      {parentId && (
        <>
          <FormBox label="Import Checkpoints from Checklists">
            <Select
              fullWidth
              multiple
              disabled={needToMakeDisable}
              value={selectedChecklists.map(checklist => checklist._id)}
              onChange={handleChecklistChange}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {selected.map((value) => {
                    const selectedItem = checklists.find(checklist => checklist._id === value);
                    return (
                      <Chip key={value} label={selectedItem?.title || "Unknown"} />
                    );
                  })}
                </Box>
              )}
            >
              {checklists.map((checklist) => (
                <MenuItem key={checklist._id} value={checklist._id}>
                  {checklist.title}
                </MenuItem>
              ))}
            </Select>
            <Button 
              onClick={handleImportCheckpoints} 
              sx={{ mt: 1 }}
              disabled={importingCheckpoints || selectedChecklists.length === 0}
            >
              {importingCheckpoints ? (
                <>
                  <CircularProgress size={24} sx={{ mr: 1 }} />
                  Importing...
                </>
              ) : (
                'Import Checkpoints'
              )}
            </Button>
          </FormBox>
          <Typography variant="h6" sx={{ mt: 2 }}>Checkpoints</Typography>
          <CheckpointTreeView
            checkpointIds={checkpointIds}
            checkpointDict={checkpointDict}
            setCheckpointIds={setCheckpointIds}
            setCheckpointDict={setCheckpointDict}
            parentId={parentId}
            profileId={profileId}
            setLoader={setLoader}
            parentModelName={parentModelName}
            isImageUploaderActive={isImageUploaderActive}
            onImageUploaderActivate={onImageUploaderActivate}
            onImageUploaderDeactivate={onImageUploaderDeactivate}
            needToMakeDisable={needToMakeDisable}
          />

        </>
      )}
    </Box>
    {loader && (
      <div className={classes.loaderCont} >
        <CircularProgress />
      </div>
    )}
  </>);
};

export default CheckpointManager;