import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Button from "@mui/material/Button";
import { deleteEventApiCall, getEventDetailsById } from "./api.call";
import Divider from '@mui/material/Divider';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PeopleIcon from '@material-ui/icons/People';
import { TbCrane } from "react-icons/tb";
import ApartmentIcon from "@material-ui/icons/Apartment";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import moment from "moment";
import { useSelector } from 'react-redux';
import { Avatar } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import TitleIcon from '@material-ui/icons/Title';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const useStyles = makeStyles((theme) => ({
    header: {
        padding: "5px",
        background: theme.palette.primary.main,
        "& h3": {
            color: "white",
            fontSize: "15px",
            fontWeight: "500"
        }
    },
    body: {
        padding: "10px",
        "& hr": {
            margin: "2px 0px 4px"
        }
    },
    footer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    infocont: {
        width: "100%",
        padding: "10px",
        "& h3": {
            fontSize: "18px",
            fontWeight: "500",
            marginLeft: "10px",
            marginTop: "-3px",
            color: "gray"
        },
        "& p": {
            fontSize: "15px",
            fontWeight: "500"
        },
    },
    icontNameCont: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
        "& .MuiSvgIcon-root": {
            fontSize: "16px"
        }
    },
    imageNameCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingLeft: "27px",
        "& .nameSty": {
            width: "100%",
            paddingLeft: "15px",
        },
        "& h4": {
            fontSize: "15px",
            fontWeight: "500",
        },
        "& h5": {
            fontSize: "13px",
            color: "black"
        },
    },
    leftMargin: {
        paddingLeft: "27px"
    },
    deleteConfirm: {
        width: "350px",
        padding: "25px 0px 35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .MuiSvgIcon-root": {
            fontSize: "75px",
            color: "red"
        },
        "& h3": {
            fontSize: "20px"
        },
        "& p": {
            fontSize: "14px"
        }
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function convertMinutesToHoursAndMinutes(minutes) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} : ${remainingMinutes}`;
}

export default function EventDetails({
    openEventDetails, setOpenEventDetails, selectedEvent,
    setEditEventData, setOpenEventAddEditDrawer, getAllEvents
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [eventDetails, setEventDetails] = useState(null);
    const [loader, setLoader] = useState(true);
    const [eventDeleteOpen, setEventDeleteOpen] = useState(false);
    const [normalLoader, setNormalLoader] = useState(false);

    useEffect(() => {
        if (selectedEvent?.id && openEventDetails) {
            setLoader(true)
            getEventDetailsById({ eventId: selectedEvent?.id })
                .then((data) => {
                    console.log(data);
                    setEventDetails(data)
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    setLoader(false)
                })
        }
    }, [selectedEvent?.id, openEventDetails])

    const deleteWotkItems = () => {
        setNormalLoader(true)
        deleteEventApiCall({ _id: selectedEvent?.id })
            .then((data) => {
                setOpenEventDetails(false)
                setEventDeleteOpen(false)
                getAllEvents()
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setNormalLoader(false)
            })
    }

    return (
        <Dialog
            open={openEventDetails}
            onClose={() => {
                setOpenEventDetails(false)
            }}
            TransitionComponent={Transition}
            keepMounted
            PaperProps={{
                style: {
                    borderRadius: "0px"
                },
            }}
            disableEnforceFocus
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent style={{ padding: "0px", width: "600px" }} >
                <div className={classes.header} >
                    <h3>Event Details</h3>
                </div>
                <div className={classes.body} >
                    {loader ? (
                        <Skeleton animation="wave" variant="rect" style={{ width: "100%", height: "400px" }} />
                    ) : (<>
                        <div className={classes.infocont} >
                            <div className={classes.icontNameCont} >
                                <TitleIcon />
                                <h3>Title</h3>
                            </div>
                            <Divider variant="full" />
                            {eventDetails?.title ? (<>
                                <p className={classes.leftMargin} >{eventDetails?.title}</p>
                            </>) : (<p>--</p>)}
                        </div>
                        <div className={classes.infocont} >
                            <div className={classes.icontNameCont} >
                                <BorderColorIcon />
                                <h3>Description</h3>
                            </div>
                            <Divider variant="full" />
                            {eventDetails?.description ? (<>
                                <p className={classes.leftMargin} >{eventDetails?.description}</p>
                            </>) : (<p>--</p>)}
                        </div>
                        <div className={classes.infocont} >
                            <div className={classes.icontNameCont} >
                                <AccessAlarmIcon />
                                <h3>Duration</h3>
                            </div>
                            <Divider variant="full" />
                            {(eventDetails?.startDate && eventDetails?.endDate) ? (<>
                                <p className={classes.leftMargin} >{`${moment(eventDetails?.startDate).format('MMM Do YYYY, hh:mm a')} - ${moment(eventDetails?.endDate).format('MMM Do YYYY, hh:mm a')}`}</p>
                                <p className={classes.leftMargin} >{(convertMinutesToHoursAndMinutes(eventDetails?.durationMins))}</p>
                            </>) : (<p>--</p>)}
                        </div>
                        <div className={classes.infocont} >
                            <div className={classes.icontNameCont} >
                                <PeopleAltIcon />
                                <h3>Required Attendees</h3>
                            </div>
                            <Divider variant="full" />
                            {(eventDetails?.roles && (eventDetails?.roles.filter((s) => s?.role === "Required")).length > 0) ? (<>
                                <p className={classes.leftMargin} >{(eventDetails?.roles.filter((s) => s?.role === "Required")).map((s) => s?.user?.parent?.displayName).join(", ")}</p>
                            </>) : (<p>--</p>)}
                        </div>
                        <div className={classes.infocont} >
                            <div className={classes.icontNameCont} >
                                <PeopleIcon />
                                <h3>Optional Attendees</h3>
                            </div>
                            <Divider variant="full" />
                            {(eventDetails?.roles && (eventDetails?.roles.filter((s) => s?.role === "Optional")).length > 0) ? (<>
                                <p className={classes.leftMargin} >{(eventDetails?.roles.filter((s) => s?.role === "Optional")).map((s) => s?.user?.parent?.displayName).join(", ")}</p>
                            </>) : (<p>--</p>)}
                        </div>
                        <div className={classes.infocont} >
                            <div className={classes.icontNameCont} >
                                <TbCrane />
                                <h3>Project</h3>
                            </div>
                            <Divider variant="full" />
                            {eventDetails?.project?._id ? (<>
                                <div className={classes.imageNameCont} >
                                    <Avatar
                                        src={eventDetails?.project?.displayPicture?.url}
                                        alt={eventDetails?.project?.displayName}
                                        style={{ width: "25px", height: "25px" }}
                                    />
                                    <div className="nameSty" >
                                        <h4>{eventDetails?.project?.displayName}</h4>
                                        <h5>{eventDetails?.project?.username}</h5>
                                    </div>
                                </div>
                            </>) : (<p>--</p>)}
                        </div>
                        <div className={classes.infocont} >
                            <div className={classes.icontNameCont} >
                                <ApartmentIcon />
                                <h3>Organization</h3>
                            </div>
                            <Divider variant="full" />
                            {eventDetails?.organization?._id ? (<>
                                <div className={classes.imageNameCont} >
                                    <Avatar
                                        src={eventDetails?.organization?.displayPicture?.url}
                                        alt={eventDetails?.organization?.displayName}
                                        style={{ width: "25px", height: "25px" }}
                                    />
                                    <div className="nameSty" >
                                        <h4>{eventDetails?.organization?.displayName}</h4>
                                        <h5>{eventDetails?.organization?.username}</h5>
                                    </div>
                                </div>
                            </>) : (<p>--</p>)}
                        </div>
                        <div className={classes.infocont} >
                            <div className={classes.icontNameCont} >
                                <RecordVoiceOverIcon />
                                <h3>Organiser</h3>
                            </div>
                            <Divider variant="full" />
                            {eventDetails?.addedBy?._id ? (<>
                                <div className={classes.imageNameCont} >
                                    <Avatar
                                        src={eventDetails?.addedBy?.parent?.displayPicture?.url}
                                        alt={eventDetails?.addedBy?.parent?.displayName}
                                        style={{ width: "25px", height: "25px" }}
                                    />
                                    <div className="nameSty" >
                                        <h4>{eventDetails?.addedBy?.parent?.displayName}</h4>
                                        <h5>{eventDetails?.addedBy?.parent?.username}</h5>
                                    </div>
                                </div>
                            </>) : (<p>--</p>)}
                        </div>
                    </>)}
                </div>
                {(user?.profile == eventDetails?.addedBy?._id) && (
                    <div className={classes.footer} >
                        <Button
                            variant="outlined"
                            color="success"
                            startIcon={<EditIcon />}
                            style={{
                                width: "50%",
                                borderRadius: "0px",
                                textTransform: "capitalize"
                            }}
                            onClick={() => {
                                setEditEventData(eventDetails)
                                setOpenEventAddEditDrawer(true)
                                setOpenEventDetails(false)
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<CloseIcon />}
                            onClick={() => {
                                setEventDeleteOpen(true)
                            }}
                            style={{
                                width: "50%",
                                borderRadius: "0px",
                                textTransform: "capitalize"
                            }}
                        >
                            Delete
                        </Button>
                        <DeleteConfirmBox
                            open={eventDeleteOpen}
                            setOpen={setEventDeleteOpen}
                            loading={normalLoader}
                            pageTitle={`Delete Event`}
                            cantent={
                                <div className={classes.deleteConfirm} >
                                    <ReportProblemIcon />
                                    <h3>Are you sure?</h3>
                                    <p>You won't be able to revert this!</p>
                                </div>
                            }
                            cancleConfirmDelete={() => {
                                setEventDeleteOpen(false)
                            }}
                            confirmDelete={() => {
                                deleteWotkItems()
                            }}
                        />
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
}
