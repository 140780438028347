import {
  Drawer,
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  Chip,
  CircularProgress,
  styled,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrgPicker from "../../styled/generic/OrgPicker";
import ProjectPicker from "../../styled/generic/ProjectPicker";
import BlockUnitViewer from "../tasks/components/BlockUnitViewer";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import { Autocomplete, AvatarGroup, tooltipClasses } from "@mui/material";
import FormBox from "../../styled/generic/FormBox";
import CategoryAutocomplete from "../../OfferingsAndProducts/CategoryAutocomplete";
import Api from "../../../helpers/Api";
import { Add } from "@mui/icons-material";
import useShareAndAssign from "../../ResourceAccessControl/shareAndAssign";

const assignBtn = (
  <Button variant="outlined" startIcon={<Add />}>
    Click here to assign
  </Button>
);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function EditChecklistDrawer({
  isDrawerOpen,
  setIsDrawerOpen,
  checklist,
  getData,
}) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    customId: "",
    organization: null,
    project: null,
    projectBlocks: [],
    units: [],
    columns: [],
    structures: [],
    labels: [],
  });

  const { assignedProfiles, AssignButton, AssigneDrawer, AssignView } =
    useShareAndAssign({
      initAssigned: null,
      resourceId: checklist?._id,
      resourceName: checklist?._id ? "Checklist" : null,
      customShareBtn: null,
      customAssignBtn: assignBtn,
    });

  useEffect(() => {
    if (checklist) {
      setFormData({
        customId: checklist.customId || "",
        organization: checklist.organization || null,
        project: checklist.project || null,
        projectBlocks: checklist.projectBlocks || [],
        units: checklist.units || [],
        columns: checklist.columns || [],
        structures: checklist.structures || [],
        labels: checklist.labels || [],
      });
    }
  }, [checklist]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await Api.post("/checklists/update", {
        checklistId: checklist._id,
        updateData: formData,
        userId: user._id,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Checklist updated successfully",
        },
      });
      getData();
      setIsDrawerOpen(false);
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: error.message,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const formatColumnLabel = (columnNumber) => `Column-${columnNumber}`;

  return (
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      PaperProps={{ style: { width: "800px" } }}
    >
      <Box p={3}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            pb: 2,
            borderBottom: "1px solid #e0e0e0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Edit Checklist</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Update"}
          </Button>
        </Box>

        <Box mt={2}>
          <TextField
            fullWidth
            size="small"
            label="Checklist ID"
            variant="outlined"
            value={formData.customId}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, customId: e.target.value }))
            }
            style={{ marginBottom: "20px" }}
          />
        </Box>

        <Grid container spacing={2} mt={2}>
          <Grid item xs={6}>
            <OrgPicker
              selectedOrg={formData.organization}
              setSelectedOrg={(org) =>
                setFormData((prev) => ({ ...prev, organization: org }))
              }
            />
          </Grid>
          <Grid item xs={6}>
            <ProjectPicker
              defaultProjectId={formData?.project?._id}
              defaultProject={formData?.project}
              selectedProject={formData?.project}
              setSelectedProject={(project) =>
                setFormData((prev) => ({ ...prev, project: project }))
              }
            />
          </Grid>
        </Grid>

        <Box mt={2}>
          <BlockUnitViewer
            projectId={formData.project?._id}
            selectedBlocks={formData.projectBlocks}
            setSelectedBlocks={(projectBlocks) =>
              setFormData((prev) => ({ ...prev, projectBlocks }))
            }
            selectedUnits={formData.units}
            setSelectedUnits={(units) =>
              setFormData((prev) => ({ ...prev, units }))
            }
            fullWidth={true}
          />
        </Box>

        <FormBox label="Columns">
          <Autocomplete
            multiple
            freeSolo
            options={[]}
            value={formData.columns}
            onChange={(event, newValue) => {
              const numericValues = newValue
                ?.map((val) => {
                  const num = parseInt(val);
                  return isNaN(num) ? null : num;
                })
                ?.filter((val) => val !== null);
              setFormData((prev) => ({ ...prev, columns: numericValues }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter column numbers"
                size="small"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={formatColumnLabel(option)}
                  {...getTagProps({ index })}
                  size="small"
                />
              ))
            }
          />
        </FormBox>
        {/* Structure and Activity Selection */}
        <Box mt={2}>
          <FormBox label="Structure">
            <CategoryAutocomplete
              libraryId={checklist?.library}
              selectedCategories={formData.structures}
              setSelectedCategories={(structures) =>
                setFormData((prev) => ({ ...prev, structures }))
              }
              profileId={checklist?.organization?._id}
              isMultiple={true}
              type="Structure"
              isCreateNew={true}
            />
          </FormBox>
        </Box>

        <Box mt={2}>
          <FormBox label="Activities">
            <CategoryAutocomplete
              libraryId={checklist?.library}
              selectedCategories={formData.labels}
              setSelectedCategories={(labels) =>
                setFormData((prev) => ({ ...prev, labels }))
              }
              profileId={checklist?.organization?._id}
              isMultiple={true}
              type="Task"
              isCreateNew={true}
            />
          </FormBox>
        </Box>

        <FormBox label="Assignees">
          <Box sx={{ display: "flex", justifyContent: "start", my: 2 }}>
            <AvatarGroup max={10}>
              {assignedProfiles?.map(({ parent }) => (
                <BootstrapTooltip placement="top" title={parent?.displayName}>
                  <Avatar
                    alt={parent?.displayName}
                    src={
                      parent?.displayPicture?.thumbUrl ||
                      parent?.displayPicture?.url
                    }
                  />
                </BootstrapTooltip>
              ))}
            </AvatarGroup>
          </Box>
          {AssignButton} {AssigneDrawer}
          <div>{AssignView}</div>
        </FormBox>
      </Box>
    </Drawer>
  );
}
