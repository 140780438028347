import { useState, useEffect } from "react";
import {
  getAllChecklists,
  getCategoriesByLibrary,
} from "../OfferingsAndProducts/Api.call";
import { useParams } from "react-router-dom";
import { getProfileDataPopulate } from "../team/procurements/api.call";
import ChecklistDrawer from "./safety/ChecklistDrawer";
import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Box,
  Chip,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import Autocomplete from "@mui/lab/Autocomplete";
import { getProfileParent } from "../AssetsManagement/api.call";
import Api from "../../helpers/Api";
import ChecklistCards from "./safety/ChecklistCards";
import { Pagination } from "@material-ui/lab";
import { FaFilter } from "react-icons/fa";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import SearchField from "../styled/generic/SearchField";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { createDefaultTemplateHook } from "../approval/approvalHelper";
import { useSelector } from "react-redux";
import emptyPage from "../../Assets/EmptyPageSVG.svg";
import { useHistory } from "react-router-dom";
import { CHECKLIST_STATUS } from "../../helpers/constants";
import { useDebounce } from "react-use";
import Stack from "@mui/material/Stack";
import { Grid, Skeleton } from "@mui/material";
import ProjectStructureTree from "./ProjectStructureTree";
import AnalyticsSummary from "./safety/AnalyticsSummary";

const useStyles = makeStyles((theme) => ({
  bodyCont: {
    padding: "1rem",
  },
  profileLoaderCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.5)"
  },
  createChecklistBtn: {
    float: "right",
  },
  filterInputOp: {
    padding: "1rem",
    minWidth: "600px",
    "& > *": {
      marginBottom: "1rem",
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    fontSize: "1.25rem",
    fontWeight: 600,
    color: "#1a202c",
  },
  actionButtons: {
    display: "flex",
    gap: theme.spacing(2),
  },
  emptyLibrarayCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& div": {
      width: "100%",
      marginTop: "10px",
      padding: "0px 20px",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
      textAlign: "center",
    },
    "& a": {
      textDecoration: "none",
      fontSize: "14px",
      marginLeft: "5px",
      cursor: "pointer",
      color: "blue",
    },
  },
  contentLayout: {
    display: "flex",
    gap: "24px",
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  sidebarContainer: {
    width: "300px",
    flexShrink: 0,
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  mainContent: {
    flex: 1,
    minWidth: 0, // This prevents flex items from overflowing
  },
}));

const debouncedSearch = debounce((value, callback) => {
  callback(value);
}, 500);

// Define custom styles
const useChipStyles = makeStyles({
  filterChip: {
    backgroundColor: "#e0f7fa",
    color: "#00796b",
    fontSize: "0.7rem",
    padding: "2px 6px",
  },
  activityChip: {
    borderRadius: "6px",
    backgroundColor: "#e3f2fd",
    color: "#1976d2",
    border: "1px solid #90caf9",
    fontSize: "0.7rem",
    padding: "2px 6px",
  },
  structureChip: {
    borderRadius: "6px",
    backgroundColor: "#fff3e0",
    color: "#e65100",
    border: "1px solid #ffb74d",
    fontSize: "0.7rem",
    padding: "2px 6px",
  },
});

export default function SafetyChecklist() {
  const { profileId } = useParams();
  const classes = useStyles();
  const chipClasses = useChipStyles();

  const [checklists, setChecklists] = useState([]);
  const [profile, setProfile] = useState(null);
  const [libraryId, setLibraryId] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [profileParent, setProfileParent] = useState(null);
  const [safetyChecklist, setSafetyChecklist] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchStr, setSearchStr] = useState("");
  const [dateRanges, setDateRanges] = useState({
    createdAt: { from: null, to: null },
  });
  const [sortOrder, setSortOrder] = useState("Latest");
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [projects, setProjects] = useState([]);
  const [orgId, setOrgId] = useState(null);
  const [organization, setOrganization] = useState(false);
  const [activities, setActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [structures, setStructures] = useState([]);
  const [selectedStructures, setSelectedStructures] = useState([]);
  const [filterFloors, setFilterFloors] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [projectStructure, setProjectStructure] = useState(null);
  const [selectedTreeNodes, setSelectedTreeNodes] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [treeFilters, setTreeFilters] = useState({
    projects: [],
    blocks: [],
    floors: [],
    units: [],
  });

  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const [defaultCreateLoader, setDefaultCreateLoader] = useState(false);
  const [canCreateCost, setCanCreateCost] = useState(false);
  const [hierarchicalCounts, setHierarchicalCounts] = useState({
    projects: {},
    blocks: {},
    units: {},
    floors: {},
    projectStructures: {},
    blockStructures: {},
    floorStructures: {},
    unitStructures: {},
  });
  const [profileDataLoader, setProfileDataLoader] = useState(false)

  const getProjectData = async () => {
    setProfileDataLoader(true)
    await getProfileDataPopulate({ profileId })
      .then((data) => {
        setProfile(data);
        if (
          data?.parent?.wallet?.defaultLibrary?.defaultChecklistApproval
            ?.stages &&
          data.parent.wallet.defaultLibrary.defaultChecklistApproval.stages
            ?.length > 0
        ) {
          const initialStage =
            data?.parent?.wallet?.defaultLibrary?.defaultChecklistApproval
              ?.stages[0];
          const approversArr = initialStage?.approvers?.map(
            (approver) => approver?.profile
          );
          if (
            initialStage?.approvalType === "anybody" ||
            (approversArr &&
              approversArr?.length > 0 &&
              approversArr?.includes(user?.profile))
          ) {
            setCanCreateCost(true);
          }
        }
        setProfileDataLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setProfileDataLoader(false)
      })
  };

  useEffect(() => {
    if (profileId) {
      getProjectData();
      getActivities();
      getStructures();
    }
  }, [profileId, libraryId]);

  const getBlocks = async (projectIds) => {
    try {
      const { data } = await Api.post(
        "/public/project/block/get/against/multiple-projects",
        {
          projectIds: projectIds,
        }
      );
      if (data) {
        setBlocks(data);
        setUnits([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUnits = async (blockIds) => {
    try {
      const units = [];
      blockIds.map(async (blockId) => {
        const { data } = await Api.post("/project/unit/getUnitsByBlock", {
          blockId: blockId,
        });
        if (data) {
          units.push(...data);
        }
      });
      setUnits(units);
    } catch (err) {
      console.log(err);
    }
  };

  const getProjects = async () => {
    try {
      const response = await Api.post("/projects/by-org", {
        organizationId: orgId,
      });
      const projects = response.data?.projects || [];
      setProjects(projects);

      const selectedProjects = organization
        ? projects
        : [profileParent?.parent?._id];

      setSelectedProject(selectedProjects);

      const projectIds = selectedProjects.map((project) => project._id);
      getBlocks(projectIds);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    if (organization && orgId) {
      getProjects();
    } else if (!organization && profileParent?.parent?._id) {
      setSelectedProject([profileParent?.parent]);
      getBlocks([profileParent?.parent?._id]);
    }
  }, [organization, orgId]);

  const getData = async () => {
    try {
      setLoading(true);
      const profileParent = await getProfileParent({ profileId });
      setProfileParent(profileParent);

      let tempLibraryId = profile?.parent?.wallet?.defaultLibrary?._id;
      setLibraryId(tempLibraryId);
      if (tempLibraryId) {
        const res = await getAllChecklists({
          libraryId: tempLibraryId,
        });
        if (Array.isArray(res?.data)) {
   
          setChecklists(res.data);
        }
      }

      const organizationId =
        profile?.parent?.ownerProfile?.parentModelName == "Organization"
          ? profile?.parent?.ownerProfile?.parent
          : profile?.parent?._id;

      if (profileParent?.parent?.ownerProfile?._id == null) {
        setOrganization(true);
      }

      setOrgId(organizationId);
      const safetyCheckListData = await Api.post("/checklist/getAll", {
        library: tempLibraryId,
        organization: organizationId,
        project: organization
          ? selectedProject.map((project) => project._id)
          : [profileParent?.parent?._id],
        currentPage: page,
        status: filterStatus,
        createdAtFrom: dateRanges.createdAt.from,
        createdAtTo: dateRanges.createdAt.to,
        nameSearch: searchStr.trim(),
        sortOrder: sortOrder,
        selectedBlock: selectedBlock.map((block) => block._id),
        selectedUnit: selectedUnit.map((unit) => unit._id),
        selectedActivities: selectedActivities.map((activity) => activity._id),
        selectedStructures: selectedStructures.map(
          (structure) => structure._id
        ),
        floors: filterFloors,
        columns: filterColumns,
      });

      const { data, totalPages, currentPage, analytics } = safetyCheckListData;
      setSafetyChecklist(data);
      setAnalytics(analytics);
      setTotalPages(totalPages);
      setPage(currentPage);
    } catch (error) {
      console.error("Error fetching checklists:", error);
    } finally {
      setLoading(false);
    }
  };

  const getChecklistCounts = async () => {
    try {
      const { data } = await Api.post("/checklist/get-counts", {
        organization: orgId,
        library: libraryId,
      });
      if (data) {
        setHierarchicalCounts({
          projects: data.project,
          blocks: data.blocks,
          units: data.units,
          floors: data.floors,
          projectStructures: data.projectStructures,
          blockStructures: data.blockStructures,
          floorStructures: data.floorStructures,
          unitStructures: data.unitStructures,
        });
      }
    } catch (error) {
      console.error("Error fetching checklist counts:", error);
    }
  };

  useEffect(() => {
    if (orgId && libraryId) {
      getChecklistCounts();
    }
  }, [orgId, libraryId]);

  const getActivities = async () => {
    const data = await getCategoriesByLibrary({
      librayId: libraryId,
      type: "Task",
    });
    setActivities(data);
  };

  const getStructures = async () => {
    const data = await getCategoriesByLibrary({
      librayId: libraryId,
      type: "Structure",
    });
    setStructures(data);
  };

  useDebounce(
    () => {
      if (profile?._id) {
        getData();
      }
    },
    1000,
    [
      searchStr,
      filterStatus,
      dateRanges.createdAt.from,
      dateRanges.createdAt.to,
      page,
      sortOrder,
      selectedProject,
      selectedBlock,
      selectedUnit,
      selectedActivities,
      selectedStructures,
      filterFloors,
      filterColumns,
      profile,
      treeFilters,
    ]
  );

  const handleFilterChipClick = (filterType) => {
    const today = dayjs();
    const startOfMonth = today.startOf("month");
    const endOfMonth = today.endOf("month");

    switch (filterType) {
      case "thisMonth":
        setDateRanges((prev) => ({
          ...prev,
          createdAt: {
            from: startOfMonth.format("YYYY-MM-DD"),
            to: endOfMonth.format("YYYY-MM-DD"),
          },
        }));
        break;
      case "clear":
        setDateRanges({
          createdAt: { from: null, to: null },
        });
        setFilterStatus([]);
        setSearchStr("");
        setSortOrder("Latest");
        setFilterFloors([]);
        setFilterColumns([]);
        setSelectedProject([]);
        setSelectedBlock([]);
        setSelectedUnit([]);
        setSelectedActivities([]);
        setSelectedStructures([]);
        setTreeFilters({
          projects: [],
          blocks: [],
          floors: [],
          units: [],
        });
        break;
    }
  };

  const createDefaultsTemplates = async () => {
    setDefaultCreateLoader(true);
    const entity = profile?.parent?._id;
    const entityModelName = profile?.parentModelName;
    const entityParent = profile?.parent?.ownerProfile?.parent;
    const entityParentModelName =
      profile?.parent?.ownerProfile?.parentModelName;

    await createDefaultTemplateHook({
      profileId: profileId,
      entity,
      entityModelName,
      entityParent,
      userId: user?._id,
      entityParentModelName,
      ownerProfileId: profile?.parent?.ownerProfile?._id,
      types: ["Checklist"],
      libraryId: profile?.parent?.wallet?.defaultLibrary?._id,
    })
      .then(async (data) => {
        await getProjectData();
        setDefaultCreateLoader(false);
      })
      .catch(async (error) => {
        console.error(error);
        await getProjectData();
        setDefaultCreateLoader(false);
      });
  };

  const handleChange = (field, value) => {
    switch (field) {
      case "project":
        setSelectedProject(value);
        if (value && value?.length > 0) {
          setBlocks([]);
          setSelectedBlock([]);
          setUnits([]);
          setSelectedUnit([]);
          value?.forEach((project) => {
            getBlocks(project._id);
          });
        } else {
          setBlocks([]);
          setSelectedBlock([]);
          setUnits([]);
          setSelectedUnit([]);
        }
        break;
      case "block":
        setSelectedBlock(value);
        if (value && value?.length > 0) {
          setUnits([]);
          setSelectedUnit([]);
          getUnits(value.map((block) => block._id));
        } else {
          setUnits([]);
          setSelectedUnit([]);
        }
        break;
      case "unit":
        setSelectedUnit(value);
        break;
      case "activities":
        setSelectedActivities(value);
        break;
      case "structures":
        setSelectedStructures(value);
        break;
      default:
        break;
    }

    if (field !== "page") {
      setPage(1);
    }
  };

  const getProjectStructure = async () => {
    try {
      const response = await Api.post("/projects/structure/by-org", {
        organizationId: orgId,
        projectId: organization ? null : profileParent?.parent?._id,
      });
      setProjectStructure(response?.data);
    } catch (error) {
      console.error("Error fetching project structure:", error);
    }
  };

  useEffect(() => {
    if (
      (organization && orgId) ||
      (!organization && profileParent?.parent?._id)
    ) {
      getProjectStructure();
    }
  }, [organization, orgId, profileParent, libraryId]);

  const handleTreeNodeSelect = (filters) => {
    if (filters?.projects) {
      const filteredProjects = filters?.projects.map((project) => {
        return projects.find((p) => p._id === project);
      });
      setSelectedProject(filteredProjects);
    }
    if (filters?.blocks) {
      setSelectedBlock(filters?.blocks);
    }
    if (filters?.units) {
      setSelectedUnit(filters?.units);
    }
    if (filters?.floors) {
      setFilterFloors(filters?.floors);
    }

    setTreeFilters(filters);
  };

  return (
    <div className={classes.bodyCont}>
      {profileDataLoader ? (<div className={classes.profileLoaderCont} >
        <CircularProgress />
      </div>) : (<>
        {!profile?.parent?.wallet?.defaultLibrary?._id ? (
          <div className={classes.emptyLibrarayCont}>
            <img src={emptyPage} alt="Empty library" />
            <div>
              <p>
                No Library exists in the current directory or any library still
                didn't selected as default library.
                <br />
                Click{" "}
                <a
                  onClick={() => {
                    history.push(`/offerings-categories/management/${profileId}`);
                  }}
                >
                  here
                </a>{" "}
                to create and manage your library(s), category(s), product(s) and
                service(s)
              </p>
            </div>
          </div>
        ) : (
          <>
            {!profile?.parent?.wallet?.defaultLibrary
              ?.defaultChecklistApproval ? (
              <div className={classes.emptyLibrarayCont}>
                <img src={emptyPage} alt="Empty templates" />
                <div>
                  <p>
                    {defaultCreateLoader ? (
                      `Template Creating....`
                    ) : (
                      <>
                        Some template(s) are missing in the current directory or
                        some template(s) are still didn't selected as default.
                        <br />
                        Click <a onClick={createDefaultsTemplates}>here</a> to
                        create default template(s)
                      </>
                    )}
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className={classes.headerContainer}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Typography variant="h2" className={classes.headerTitle}>
                        Safety Checklists
                      </Typography>
                      <Chip
                        label="This Month"
                        onClick={() => handleFilterChipClick("thisMonth")}
                        color={
                          dateRanges.createdAt.from ===
                            dayjs().startOf("month").format("YYYY-MM-DD")
                            ? "primary"
                            : "default"
                        }
                        variant={
                          dateRanges.createdAt.from ===
                            dayjs().startOf("month").format("YYYY-MM-DD")
                            ? "filled"
                            : "outlined"
                        }
                      />
                      {(dateRanges.createdAt.from ||
                        dateRanges.createdAt.to ||
                        filterStatus?.length > 0 ||
                        filterFloors?.length > 0 ||
                        filterColumns?.length > 0 ||
                        selectedProject?.length > 0 ||
                        selectedBlock?.length > 0 ||
                        selectedUnit?.length > 0 ||
                        selectedActivities?.length > 0 ||
                        selectedStructures?.length > 0 ||
                        searchStr) && (
                          <Chip
                            label="Clear Filters"
                            onClick={() => handleFilterChipClick("clear")}
                            color="default"
                            variant="outlined"
                          />
                        )}
                    </div>
                  </div>

                  <div className={classes.actionButtons}>
                    <MyPopOver
                      closeOnClick={false}
                      appearContent={
                        <Button
                          startIcon={<FaFilter />}
                          variant="outlined"
                          color="primary"
                        >
                          Add Filter
                        </Button>
                      }
                      showContent={
                        <div className={classes.filterInputOp}>
                          <Box flex="1">
                            <SearchField
                              value={searchStr}
                              onChange={(e) => {
                                const value = e.target.value;
                                setSearchStr(value);
                              }}
                              sx={{
                                backgroundColor: "white",
                                boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.04)",
                              }}
                              fullWidth={true}
                              size="small"
                              placeholder="Search checklists..."
                            />
                          </Box>

                          <Box display="flex" mb={2}>
                            <Box flex={1} pr={2}>
                              <TextField
                                select
                                fullWidth
                                size="small"
                                label="Sort Order"
                                value={sortOrder}
                                onChange={(e) => setSortOrder(e.target.value)}
                              >
                                <MenuItem value="Latest">Latest</MenuItem>
                                <MenuItem value="Oldest">Oldest</MenuItem>
                              </TextField>
                            </Box>
                            <Box flex={1} pl={2}>
                              <TextField
                                select
                                fullWidth
                                size="small"
                                label="Status"
                                SelectProps={{
                                  multiple: true,
                                  value: filterStatus,
                                  onChange: (e) =>
                                    setFilterStatus(e.target.value),
                                  renderValue: (selected) => (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: 0.5,
                                      }}
                                    >
                                      {selected.map((value, index) => (
                                        <Chip
                                          key={index}
                                          label={value}
                                          size="small"
                                        />
                                      ))}
                                    </Box>
                                  ),
                                  MenuProps: {
                                    PaperProps: {
                                      style: { maxHeight: 250 },
                                    },
                                  },
                                }}
                              >
                                {Object.values(CHECKLIST_STATUS).map((status) => (
                                  <MenuItem value={status} key={status}>
                                    {status}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </Box>

                          <Box display="flex" mb={2}>
                            <Box flex={1} pr={2}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                label="Created From"
                                value={dateRanges.createdAt.from || ""}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    createdAt: {
                                      ...prev.createdAt,
                                      from: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Box>
                            <Box flex={1} pl={2}>
                              <TextField
                                type="date"
                                fullWidth
                                size="small"
                                label="Created To"
                                value={dateRanges.createdAt.to || ""}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) =>
                                  setDateRanges((prev) => ({
                                    ...prev,
                                    createdAt: {
                                      ...prev.createdAt,
                                      to: e.target.value,
                                    },
                                  }))
                                }
                              />
                            </Box>
                          </Box>

                          {organization && (
                            <Box flex="1">
                              <Autocomplete
                                multiple
                                value={selectedProject}
                                onChange={(event, newValue) => {
                                  handleChange("project", newValue);
                                }}
                                options={projects}
                                getOptionLabel={(option) =>
                                  option?.displayName || ""
                                }
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      key={index}
                                      label={option.displayName}
                                      size="small"
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Projects"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </Box>
                          )}

                          <Box display="flex" mb={2}>
                            <Box flex={1} pr={2}>
                              <Autocomplete
                                multiple
                                value={selectedBlock || []}
                                onChange={(event, newValue) => {
                                  handleChange("block", newValue);
                                }}
                                options={blocks || []}
                                getOptionLabel={(option) => option?.name || ""}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      key={index}
                                      label={option?.name || ""}
                                      size="small"
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Blocks"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </Box>
                            <Box flex={1} pl={2}>
                              <Autocomplete
                                multiple
                                value={selectedUnit || []}
                                onChange={(event, newValue) => {
                                  handleChange("unit", newValue);
                                }}
                                options={units || []}
                                getOptionLabel={(option) => option?.name || ""}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      key={index}
                                      label={option?.name || ""}
                                      size="small"
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Units"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </Box>
                          </Box>

                          <Box display="flex" mb={2}>
                            <Box flex={1} pr={2}>
                              <Autocomplete
                                multiple
                                value={selectedActivities || []}
                                onChange={(event, newValue) => {
                                  handleChange("activities", newValue);
                                }}
                                options={activities || []}
                                getOptionLabel={(option) => option?.name || ""}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      key={index}
                                      label={option?.name || ""}
                                      size="small"
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Activities"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </Box>
                            <Box flex={1} pl={2}>
                              <Autocomplete
                                multiple
                                value={selectedStructures || []}
                                onChange={(event, newValue) => {
                                  handleChange("structures", newValue);
                                }}
                                options={structures || []}
                                getOptionLabel={(option) => option?.name || ""}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      key={index}
                                      label={option?.name || ""}
                                      size="small"
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Structures"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </Box>
                          </Box>

                          <Box display="flex" mb={2}>
                            <Box flex={1} pr={2}>
                              <Autocomplete
                                multiple
                                freeSolo
                                options={[]}
                                value={filterFloors}
                                onChange={(event, newValue) => {
                                  const numericValues = newValue
                                    .map((val) => {
                                      const num = parseInt(val);
                                      return isNaN(num) ? null : num;
                                    })
                                    .filter((val) => val !== null);
                                  setFilterFloors(numericValues);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Floors"
                                    placeholder="Enter floor numbers"
                                    helperText="Enter floor numbers and press Enter"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                                renderTags={(value, getTagProps) =>
                                  value.map((floor, index) => (
                                    <Chip
                                      key={floor}
                                      label={floor}
                                      size="small"
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderOption={(props, option) => (
                                  <MenuItem {...props}>
                                    {option < 0
                                      ? `Basement ${Math.abs(option)}`
                                      : option === 0
                                        ? "Ground Floor"
                                        : `Floor ${option}`}
                                  </MenuItem>
                                )}
                              />
                            </Box>
                            <Box flex={1} pl={2}>
                              <Autocomplete
                                multiple
                                freeSolo
                                value={filterColumns}
                                options={[]}
                                onChange={(event, newValue) => {
                                  const numericValues = newValue
                                    .map((val) => {
                                      const num = parseInt(val);
                                      return isNaN(num) ? null : num;
                                    })
                                    .filter((val) => val !== null);
                                  setFilterColumns(numericValues);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Columns"
                                    placeholder="Enter column numbers"
                                    helperText="Enter column numbers and press Enter"
                                    size="small"
                                    fullWidth
                                  />
                                )}
                                renderTags={(value, getTagProps) =>
                                  value.map((column, index) => (
                                    <Chip
                                      key={index}
                                      label={column}
                                      size="small"
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderOption={(props, option) => (
                                  <MenuItem {...props}>Column {option}</MenuItem>
                                )}
                              />
                            </Box>
                          </Box>
                        </div>
                      }
                    />
                    {canCreateCost && (
                      <>
                        <Button
                          type="primary"
                          variant="contained"
                          color="primary"
                          onClick={() => setIsDrawerOpen(true)}
                          className={classes.createChecklistBtn}
                        >
                          Create checklist
                        </Button>
                        <ChecklistDrawer
                          isDrawerOpen={isDrawerOpen}
                          setIsDrawerOpen={setIsDrawerOpen}
                          checklists={checklists}
                          profile={profile}
                          libraryId={libraryId}
                          profileParent={profileParent}
                          getData={getData}
                        />
                      </>
                    )}
                  </div>
                </div>
                <Stack direction="row" spacing={1} flexWrap="wrap">
                  {filterStatus?.map((status, index) => (
                    <Chip
                      key={index}
                      label={status}
                      onDelete={() => {
                        setFilterStatus(filterStatus.filter((s) => s !== status));
                      }}
                      color="default"
                      variant="outlined"
                      size="small"
                      className={chipClasses.filterChip}
                    />
                  ))}
                  {dateRanges.createdAt.from && (
                    <Chip
                      label={`From: ${dayjs(dateRanges.createdAt.from).format(
                        "DD/MM/YYYY"
                      )}`}
                      onDelete={() =>
                        setDateRanges((prev) => ({
                          ...prev,
                          createdAt: { ...prev.createdAt, from: null },
                        }))
                      }
                      color="default"
                      variant="outlined"
                      size="small"
                      className={chipClasses.filterChip}
                    />
                  )}
                  {dateRanges.createdAt.to && (
                    <Chip
                      label={`To: ${dayjs(dateRanges.createdAt.to).format(
                        "DD/MM/YYYY"
                      )}`}
                      onDelete={() =>
                        setDateRanges((prev) => ({
                          ...prev,
                          createdAt: { ...prev.createdAt, to: null },
                        }))
                      }
                      color="default"
                      variant="outlined"
                      size="small"
                      className={chipClasses.filterChip}
                    />
                  )}
                  {searchStr && (
                    <Chip
                      label={`Search: ${searchStr}`}
                      onDelete={() => setSearchStr("")}
                      color="default"
                      variant="outlined"
                      size="small"
                      className={chipClasses.filterChip}
                    />
                  )}
                  {filterFloors?.map((floor, index) => (
                    <Chip
                      key={index}
                      label={`Floor: ${floor < 0
                        ? `Basement ${Math.abs(floor)}`
                        : floor === 0
                          ? "Ground Floor"
                          : `Floor ${floor}`
                        }`}
                      onDelete={() => {
                        setFilterFloors(filterFloors.filter((f) => f !== floor));
                      }}
                      size="small"
                      className={chipClasses.filterChip}
                    />
                  ))}
                  {filterColumns?.map((column, index) => (
                    <Chip
                      key={index}
                      label={`Column: ${column}`}
                      onDelete={() => {
                        setFilterColumns(
                          filterColumns.filter((c) => c !== column)
                        );
                      }}
                      size="small"
                      className={chipClasses.filterChip}
                    />
                  ))}
                </Stack>
                {(selectedActivities?.length > 0 ||
                  selectedStructures?.length > 0 ||
                  selectedBlock?.length > 0 ||
                  selectedUnit?.length > 0 ||
                  selectedProject?.length > 0) && (
                    <Box mt={1} display="flex" style={{ gap: "10px" }}>
                      {selectedActivities?.map((activity, index) => (
                        <Chip
                          key={index}
                          label={`Activity: ${activity.name}`}
                          onDelete={() => {
                            setSelectedActivities(
                              selectedActivities.filter(
                                (a) => a._id !== activity._id
                              )
                            );
                          }}
                          size="small"
                          className={chipClasses.activityChip}
                        />
                      ))}
                      {selectedStructures?.map((structure, index) => (
                        <Chip
                          key={index}
                          label={`Structure: ${structure.name}`}
                          onDelete={() => {
                            setSelectedStructures(
                              selectedStructures.filter(
                                (s) => s._id !== structure._id
                              )
                            );
                          }}
                          size="small"
                          className={chipClasses.structureChip}
                        />
                      ))}
                      {selectedBlock?.map((block, index) => (
                        <Chip
                          key={index}
                          label={`Block: ${block.name}`}
                          onDelete={() => {
                            setSelectedBlock(
                              selectedBlock.filter((b) => b._id !== block._id)
                            );
                          }}
                          size="small"
                          className={chipClasses.activityChip}
                        />
                      ))}
                      {selectedUnit?.map((unit, index) => (
                        <Chip
                          key={index}
                          label={`Unit: ${unit.name}`}
                          onDelete={() => {
                            setSelectedUnit(
                              selectedUnit.filter((u) => u._id !== unit._id)
                            );
                          }}
                          size="small"
                          className={chipClasses.activityChip}
                        />
                      ))}
                    </Box>
                  )}
                <div className={classes.contentLayout}>
                  <div className={classes.sidebarContainer}>
                    {projectStructure && (
                      <ProjectStructureTree
                        data={projectStructure}
                        onNodeSelect={handleTreeNodeSelect}
                        hierarchicalCounts={hierarchicalCounts}
                        selectedNodes={selectedTreeNodes}
                        setSelectedNodes={setSelectedTreeNodes}
                        selectedStructures={selectedStructures}
                        setSelectedStructures={setSelectedStructures}
                      />
                    )}
                  </div>

                  <div className={classes.mainContent}>
                    {loading ? (
                      <Grid container spacing={2}>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((item) => (
                          <Grid item xs={12} sm={6} md={4} key={item}>
                            <Skeleton
                              variant="rounded"
                              width="100%"
                              height={300}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <>
                        <AnalyticsSummary
                          analytics={analytics}
                          loading={loading}
                        />
                        <ChecklistCards checklists={safetyChecklist} />
                        {totalPages > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "2rem",
                            }}
                          >
                            <Pagination
                              count={totalPages}
                              page={page}
                              onChange={(event, value) => setPage(value)}
                              color="primary"
                              variant="outlined"
                              shape="rounded"
                              showFirstButton={page > 1}
                              showLastButton={page < totalPages}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>)}
    </div>
  );
}
