import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  deleteOfferingAndRelation,
  getOfferingsByLibraryPagination,
} from "./Api.call";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import AddIcon from "@material-ui/icons/Add";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import {
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import OfferingCreateDrawer from "./Offering.Create.Drawer";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { useDebounce } from "react-use";
import OfferingCopyDrawer from "./Offering.copy.library";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
    "& table": {
      tableLayout: "auto",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "0px 10px",
    },
  },
  searchAndAddBtnCont: {
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0px 5px",
    justifyContent: "space-between",
  },
  tableContainer: {
    width: "100%",
    height: "calc(100% - 106px)",
    "& tr": {
      height: "45px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& table": {
      tableLayout: "auto",
    },
  },
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #ececec",
  },
  singleOption: {
    padding: "4px 15px",
    fontSize: "15px",
    fontWeight: "500",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  iconBtn: {
    "& .MuiSvgIcon-root": {
      color: "black",
    },
  },
  deleteConfirm: {
    width: "350px",
    padding: "25px 0px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      fontSize: "75px",
      color: "red",
    },
    "& h3": {
      fontSize: "20px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
}));

export default function OfferingsByLibrary({
  libraryId,
  profileId,
  wallet,
  updateWalletApiCall,
  setResponseStatusAndMsg,
  setSanckBarBool,
  currentLibrary,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [createOfferingDrawer, setCreateOfferingDrawer] = useState(false);
  const [cloneOfferingDrawer, setCloneOfferingDrawer] = useState(false);

  const [offeringDeleteLoader, setOfferingDeleteLoader] = useState(false);
  const [deleteConfirmBox, setDeleteConfirmBox] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [offerings, setOfferings] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(20);
  const [curEditDeleteOffering, setCurEditDeleteOffering] = useState(null);

  const getOfferingPagination = async () => {
    setLoader(true);
    await getOfferingsByLibraryPagination({
      libraryId,
      profileId,
      type: "both",
      limit: pageLimit,
      curPage: curPage - 1,
      nameSearch: searchStr,
    })
      .then((data) => {
        console.log(data);
        const dataArr = data?.data || [];
        const dataCount = data?.count || 0;
        setOfferings(dataArr);
        let locTotalPage = Math.ceil(dataCount / pageLimit);
        setTotalPages(locTotalPage);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useDebounce(() => {
    getOfferingPagination();
  }, 1000, [curPage, changeState, currentLibrary]);

  useEffect(() => {
    setCurPage(1);
    setChangeState(!changeState);
  }, [searchStr]);

  //offering actions
  const offeringAction = async (type, offering) => {
    setCreateOfferingDrawer(false);
    setCurEditDeleteOffering(null);
    setDeleteConfirmBox(false);
    switch (type) {
      case "create":
        setCreateOfferingDrawer(true);
        break;
      case "edit":
        setCurEditDeleteOffering(offering);
        setCreateOfferingDrawer(true);
        break;
      case "delete":
        setCurEditDeleteOffering(offering);
        setDeleteConfirmBox(true);
        break;
      case "setDefaultIncome":
        await updateWalletApiCall({
          walletId: wallet?._id,
          defaultIncomeOfferingRelation: offering?.offeringRelation?._id,
        });
        break;
      case "setDefaultExpense":
        await updateWalletApiCall({
          walletId: wallet?._id,
          defaultExpansesOfferingRelation: offering?.offeringRelation?._id,
        });
        break;
    }
  };

  const afterOfferingCreateOrEdit = async (data, isEdit) => {
    if (!isEdit) {
      setChangeState(!changeState);
      setCurPage(1);
      setResponseStatusAndMsg({
        status: "success",
        message: `${data?.model} successfully added`,
      });
      setSanckBarBool(true);
    } else {
      setChangeState(!changeState);
      setResponseStatusAndMsg({
        status: "success",
        message: `${data?.model} successfully modified`,
      });
      setSanckBarBool(true);
    }
    setCurEditDeleteOffering(null);
  };

  const deleteOffering = async () => {
    if (
      curEditDeleteOffering?.offeringRelation?._id &&
      curEditDeleteOffering?._id
    ) {
      setOfferingDeleteLoader(true);
      await deleteOfferingAndRelation({
        offeringId: curEditDeleteOffering?._id,
        offeringRelationId: curEditDeleteOffering?.offeringRelation?._id,
      })
        .then((data) => {
          setChangeState(!changeState);
          setOfferingDeleteLoader(false);
          setDeleteConfirmBox(false);
          setResponseStatusAndMsg({
            status: "success",
            message: `${curEditDeleteOffering?.model} successfully deleted`,
          });
          setSanckBarBool(true);
          setCurEditDeleteOffering(null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.searchAndAddBtnCont}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          size="small"
          placeholder={"Enter service or product name"}
          autoComplete="off"
          value={searchStr}
          style={{ width: "350px" }}
          onChange={(e) => {
            setSearchStr(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <div style={{ display: "flex", alignItems: "center" }} >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setCloneOfferingDrawer(true)
            }}
            style={{ marginRight: "10px" }}
          >
            Clone Public Library
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              setCurEditDeleteOffering(null);
              offeringAction("create", null);
            }}
          >
            New
          </Button>
        </div>
      </div>
      <div style={{ width: "100%", height: "5px" }}>
        {loader && <LinearProgress />}
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ minWidth: "230px" }}>
                Name
              </TableCell>
              <TableCell align="center" style={{ minWidth: "120px" }}>
                Code
              </TableCell>
              <TableCell align="center" style={{ minWidth: "170px" }}>
                Price
              </TableCell>
              <TableCell align="center" style={{ minWidth: "70px" }}>
                Unit
              </TableCell>
              <TableCell align="center" style={{ minWidth: "220px" }}>
                Income Chart Account
              </TableCell>
              <TableCell align="center" style={{ minWidth: "100px" }}>
                Tax
              </TableCell>
              <TableCell align="center" style={{ minWidth: "170px" }}>
                Purchase price
              </TableCell>
              <TableCell align="center" style={{ minWidth: "70px" }}>
                Unit
              </TableCell>
              <TableCell align="center" style={{ minWidth: "220px" }}>
                Expense Chart Account
              </TableCell>
              <TableCell align="center" style={{ minWidth: "80px" }}>
                Type
              </TableCell>
              <TableCell align="center" style={{ minWidth: "150px" }}>
                Category
              </TableCell>
              <TableCell align="center" style={{ minWidth: "150px" }}>
                Add By
              </TableCell>
              <TableCell align="center" style={{ minWidth: "50px" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offerings &&
              offerings.length > 0 &&
              offerings.map((data) => {
                return (
                  <TableRow>
                    <TableCell align="left">{data?.name}</TableCell>
                    <TableCell align="center">{data?.code}</TableCell>
                    <TableCell align="center">{`₹${data?.price}`}</TableCell>
                    <TableCell align="center">{data?.unit}</TableCell>
                    <TableCell align="center">
                      {data?.incomeChartAccount?.name}
                    </TableCell>
                    <TableCell align="center">
                      {data?.taxType === "%"
                        ? `${data?.tax}%`
                        : `₹${data?.tax}`}
                    </TableCell>
                    <TableCell align="center">
                      {`₹${data?.purchasePrice}`}
                    </TableCell>
                    <TableCell align="center">{data?.purchaseUnit}</TableCell>
                    <TableCell align="center">
                      {data?.expenseChartAccount?.name}
                    </TableCell>
                    <TableCell align="center">{data?.model}</TableCell>
                    <TableCell align="center">{data?.category?.name}</TableCell>
                    <TableCell align="center">
                      {data?.isPublic ? "RealList" : data?.user?.displayName}
                    </TableCell>
                    <TableCell align="center" style={{ minWidth: "50px" }}>
                      <MyPopOver
                        closeOnClick={true}
                        appearContent={
                          <span className={classes.iconBtn}>
                            <IconButton>
                              <MoreVertIcon />
                            </IconButton>
                          </span>
                        }
                        showContent={
                          <div style={{ padding: "5px 0px" }}>
                            <div
                              className={classes.singleOption}
                              onClick={() => {
                                offeringAction("edit", data);
                              }}
                            >
                              Edit
                            </div>
                            {data?.doSell &&
                              data?.incomeChartAccount?._id &&
                              wallet?.defaultIncomeOfferingRelation !=
                              data?.offeringRelation?._id && (
                                <div
                                  className={classes.singleOption}
                                  onClick={() => {
                                    offeringAction("setDefaultIncome", data);
                                  }}
                                >
                                  Default Income Offering
                                </div>
                              )}
                            {data?.doPurchase &&
                              data?.expenseChartAccount?._id &&
                              wallet?.defaultExpansesOfferingRelation !=
                              data?.offeringRelation?._id && (
                                <div
                                  className={classes.singleOption}
                                  onClick={() => {
                                    offeringAction("setDefaultExpense", data);
                                  }}
                                >
                                  Default Expense Offering
                                </div>
                              )}
                            <div
                              className={classes.singleOption}
                              onClick={() => {
                                offeringAction("delete", data);
                              }}
                            >
                              Delete
                            </div>
                          </div>
                        }
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.paginationCont}>
        <Pagination
          count={totalPages}
          page={curPage}
          color="primary"
          siblingCount={0}
          onChange={(event, value) => {
            setCurPage(value);
          }}
        />
      </div>

      {/* after create  offering relation drawer */}
      <OfferingCreateDrawer
        createOfferingDawer={createOfferingDrawer}
        setCreateOfferingDrawer={setCreateOfferingDrawer}
        profileId={profileId}
        purchase={true}
        selling={false}
        library={{ _id: libraryId }}
        afterOfferingCreateOrEdit={afterOfferingCreateOrEdit}
        curEditDeleteOffering={curEditDeleteOffering}
      />
      {/* copy library */}
      <OfferingCopyDrawer
        cloneOfferingDrawer={cloneOfferingDrawer}
        setCloneOfferingDrawer={setCloneOfferingDrawer}
        library={libraryId}
        setLoader={setLoader}
        loader={loader}
        afterClone={()=>{
          setCurPage(1);
          setChangeState(!changeState);
        }}
      />
      {/* confirm box to delete offering and offering relation */}
      <DeleteConfirmBox
        open={deleteConfirmBox}
        setOpen={setDeleteConfirmBox}
        loading={offeringDeleteLoader}
        pageTitle={`Delete ${curEditDeleteOffering?.offering?.name}`}
        cantent={
          <div className={classes.deleteConfirm}>
            <ReportProblemIcon />
            <h3>Are you sure?</h3>
            <p>You won't be able to revert this!</p>
          </div>
        }
        cancleConfirmDelete={() => {
          setDeleteConfirmBox(false);
        }}
        confirmDelete={deleteOffering}
      />
      {/* confirm box to delete offering and offering relation */}
    </div>
  );
}
