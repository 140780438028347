import { Add, Clear, Print } from "@material-ui/icons";
import { Box, IconButton, MenuItem } from "@mui/material";
import axios from "axios";
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import configObject from "../../../config";
import { useDispatch } from "react-redux";
import Dialog from "./Dialog";
import FormBox from "./FormBox";
import HorizBox from "./HorizBox";
import TextField from "./TextField";
import DuoButtonGroup from "./DuoButtonGroup";
import FormHeaderWithActions from "./FormHeaderWithActions";
import { getTermsAndConditions } from "../../OfferingsAndProducts/Api.call";
import Autocomplete from "./Autocomplete";
import Api from "../../../helpers/Api";
import Select from "./Select";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TotalBillList from "../../finance2o/expenseAndBills/bills/BillList/total.billlist";

const ExportPDF = forwardRef(
  (
    {
      printButton,
      apiUrl = "",
      fileName = "",
      apiBody = {},
      tx = {},
      enableSignatures = false,
      enableTermsAndConditions = false,
      libraryId,
      profileId,
    },
    ref
  ) => {
    console.log("8", `${configObject.apiUrl}${apiUrl}`);
    const dispatch = useDispatch();
    const history = useHistory();
    const [showPrintDialog, setShowPrintDialog] = useState(false);
    const [signatureFields, setSignatureFields] = useState([]);
    const [loader, setLoader] = useState(false);
    const [termsAndConditions, setTermsAndConditions] = useState([]);

    const [loading, setLoading] = useState(false);
    const [signatures, setSignatures] = useState([]);
    console.log(signatures);
    useEffect(() => {
      console.log("Library ID:", libraryId);
    }, [libraryId]);

    useEffect(() => {
      console.log("Profile ID:", profileId);
    }, [profileId]);

    const exportPDF = async () => {
      try {
        setLoading(true);

        const response = await axios.post(
          `${configObject.apiUrl}${apiUrl}`,
          {
            ...apiBody,
            signatureFields,

            termsAndConditions: selectedTermsAndConditions,
          },
          { responseType: "blob" }
        );

        if (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName
            ? fileName + ".pdf"
            : response.headers.filename + ".pdf" || "file.pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();

          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: "Order exported successfully",
            },
          });
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Unable to download the file",
            },
          });
        }
      } catch (error) {
        console.log(error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Unable to download the file",
          },
        });
      } finally {
        setLoading(false);
        setShowPrintDialog(false);
      }
    };

    const getSignatures = async () => {
      const { data } = await Api.post("/signature/get/", {
        libraryId,
      });

      console.log("Signatures:", data);
      setSignatures(data);
    };

    const getTC = async () => {
      setLoader(true);
      await getTermsAndConditions({
        libraryId: libraryId,
      })
        .then((data) => {
          console.log("T&C:", data?.data);
          setTermsAndConditions(data?.data || []);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(() => {
      if (enableTermsAndConditions) {
        getTC();
      }
    }, [libraryId]);

    useEffect(() => {
      if (enableSignatures) {
        getSignatures();
      }
    }, [libraryId]);

    const [selectedSignatures, setSelectedSignatures] = useState([]);

    const [selectedTermsAndConditions, setSelectedTermsAndConditions] =
      useState([]);

    // Expose the exportPDF method through the ref
    useImperativeHandle(ref, () => ({
      exportPDF,
    }));

    return (
      <>
        <Box sx={{ mx: 1 }}>
          {printButton ? (
            printButton
          ) : (
            <IconButton
              color="primary"
              onClick={() =>
                !enableSignatures && !enableTermsAndConditions
                  ? exportPDF()
                  : setShowPrintDialog(true)
              }
            >
              <Print />
            </IconButton>
          )}
        </Box>
        <Dialog
          open={showPrintDialog}
          setOpen={setShowPrintDialog}
          title="Export PDF"
        >
          {enableSignatures && (
            <FormHeaderWithActions
              label="Enter Signature Fields"
              actions={
                <IconButton
                  onClick={() => {
                    history.push(
                      `/offerings-categories/management/${profileId._id}`
                    );
                  }}
                >
                  <Add />
                </IconButton>
              }
            >
              <Select
                options={signatures}
                value={signatureFields}
                onChange={(event) => setSignatureFields(event.target.value)}
                multiple
                fullWidth={true}
              >
                {signatures.map((signature) => (
                  <MenuItem value={signature._id}>
                    {signature?.title}{" "}
                    {signature?.fields?.length > 0 &&
                      "[" +
                        signature.fields.map((field) => field.name).join(", ") +
                        "]"}
                  </MenuItem>
                ))}
              </Select>
            </FormHeaderWithActions>
          )}
          {enableTermsAndConditions && (
            <FormHeaderWithActions
              label="Select Terms & Conditions"
              actions={
                <IconButton
                  onClick={() => {
                    history.push(
                      `/offerings-categories/management/${profileId._id}`
                    );
                  }}
                >
                  <Add />
                </IconButton>
              }
            >
              <Select
                options={termsAndConditions}
                value={selectedTermsAndConditions}
                onChange={(event) =>
                  setSelectedTermsAndConditions(event.target.value)
                }
                multiple
                fullWidth={true}
              >
                {termsAndConditions.map((tc) => (
                  <MenuItem value={tc?._id}>{tc?.title}</MenuItem>
                ))}
              </Select>
            </FormHeaderWithActions>
          )}
          <DuoButtonGroup
            primaryButtonText="Export"
            primaryButtonListener={exportPDF}
            secondaryButtonText="Cancel"
            secondaryButtonListener={() => setShowPrintDialog(false)}
            loadingPrimary={loading}
          />
        </Dialog>
      </>
    );
  }
);

export default ExportPDF;
