import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import LibraryCreateDrawer from "./Library.Create.Drawer";
import { getLibraryCategoriesBasicData, deleteLibrarayById } from "./Api.call";
import LessText from "../styled/CommonComponents/LessText";
import moment from "moment";
import LinearProgress from "@material-ui/core/LinearProgress";
import OfferingsByLibrary from "./OfferingsByLibrary";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import emptyPage from "../../Assets/EmptyPageSVG.svg";
import productLibrary from "../../Assets/productLibrary.svg";
import { BiImport } from "react-icons/bi";
import LibrarySkeleton from "../styled/skeletons/Library.Skeleton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CategoriesByLibrary from "./CategoriesByLibrary";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LibraryImport from "./Library.Import";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { updateWallet } from "../finance2o/accounts/paymentMethods/dwolla/api";
import TermsAndConditionsByLibrary from "./TermsAndConditionsByLibrary";
import DiscountByLibrary from "./DiscountByLibray";
import TaxesByLibrary from "./TaxesByLibrary";
import ApprovalTemplatesByLibrary from "./ApprovalTemplatesByLibrary";
import { getProfileData } from "../team/procurements/api.call";
import SignaturesByLibrary from "./SignaturesByLibrary";
import LibraryEditDrawer from "./Library.Edit.Dialog";
import { Box } from "@mui/material";
import SOPsByLibrary from "./SOPsByLibrary";
import ChecklistsByLibrary from "./ChecklistsByLibrary";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  mainCont: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
  },
  bodyCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    padding: "5px",
  },
  libraryAndCategoryHeaderCont: {
    width: "100%",
    height: "290px",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "5px 10px 5px",
  },
  librayHeader: {
    width: "100%",
    paddingBottom: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ececec",
  },
  offeringCategoryHeader: {
    width: "100%",
    height: "40px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderBottom: "1px solid #ececec",
    "& .MuiTab-root": {
      minWidth: "auto !important",
    },
  },
  librariesCont: {
    width: "100%",
    height: "calc(100% - 95px)",
    padding: "15px",
    gap: "10px",
    flexwrap: "wrap",
    overflowY: "hidden",
    overflowX: "auto",
    display: "flex",
    alignItems: "flex-start",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  searchAndTableCont: {
    width: "100%",
    height: "calc(100% - 300px)",
    marginTop: "10px",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "white",
    "& .MuiTreeItem-label:hover": {
      backgroundColor: "white",
    },
  },
  emptyDivSty: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
      textAlign: "center",
      margin: "10px 0px 25px",
    },
  },
  emptyBtnCont: {
    display: "flex",
    alignItems: "center",
  },
  backAndTitleCont: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500",
      marginLeft: "15px",
    },
  },
  libraryCard: {
    minWidth: "250px",
    width: "250px",
    height: "150px",
    padding: "7px",
    borderRadius: "5px",
    cursor: "pointer",
    position: "relative",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    transition: "transform ease-in-out 0.1s",
    "&:hover": {
      transform: "scale(1.03)",
    },
  },
  penSty: {
    position: "absolute",
    top: "5px",
    right: "5px",
    "& .MuiIconButton-root": {
      padding: "5px",
    },
    "& .MuiSvgIcon-root": {
      color: "black",
      fontSize: "22px",
    },
  },
  libraryImageCont: {
    width: "100%",
    height: "80px",
    paddingBottom: "10px",
    overflow: "hidden",
    borderRadius: "5px",
    backgroundColor: "#ececec",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      height: "100%",
      width: "auto",
      transform: "rotate(-15deg)",
    },
  },
  libraryDetailsCont: {
    width: "100%",
    paddingTop: "5px",
    "& h3": {
      fontSize: "14px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      fontWeight: "400",
      marginTop: "3px",
    },
  },
  deleteConfirm: {
    width: "350px",
    padding: "25px 0px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      fontSize: "75px",
      color: "red",
    },
    "& h3": {
      fontSize: "20px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  singleOption: {
    padding: "4px 15px",
    fontSize: "15px",
    fontWeight: "500",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
}));

const OfferingsAndProductsManagement = ({}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { profileId } = useParams();

  const [importLibraryOpen, setImportLibraryOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [curTab, setCurTab] = useState(0);
  const [profileData, setProfileData] = useState(null);
  const [createLibraryDawer, setCreateLibraryDrawer] = useState(false);
  const [libraiesArr, setLibraiesArr] = useState([]);
  const [currentLibrary, setCurrentLibrary] = useState({});
  const [wallet, setWallet] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [editData, setEditData] = useState({});

  //edit
  const [curEditDeleteLibrary, setCurEditDeleteLibrary] = useState(null);
  const [libraryDeleteConfirmBox, setLibraryDeleteConfirmBox] = useState(false);
  const [libraryDeleteLoader, setLibraryDeleteLoader] = useState(false);

  //snackBar
  const [responseStatusAndMsg, setResponseStatusAndMsg] = useState(null);
  const [sanckBarBool, setSanckBarBool] = useState(null);

  useEffect(() => {
    if (profileId) {
      getProfileData({ profileId })
        .then((data) => {
          console.log(data);
          setProfileData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileId]);

  const buildHierarchy = (items, parent = null) => {
    const result = [];
    for (const item of items) {
      if (item.parent == parent) {
        const children = buildHierarchy(items, item._id);
        if (children.length > 0) {
          item.children = children;
        }
        result.push(item);
      }
    }
    return result;
  };

  const getLibrariesAndCategories = async (isInitial) => {
    if (isInitial) {
      setLoader(true);
    }
    await getLibraryCategoriesBasicData({
      curProfileId: profileId,
      profileIds: [profileId],
    })
      .then(async (data) => {
        const allLibraries = data?.allLibraries || [];
        const locWallet = data?.wallet;
        if (allLibraries && allLibraries.length > 0) {
          setCurrentLibrary(allLibraries[0]);
        }
        setLibraiesArr(allLibraries);
        console.log("API CALL FOR LIB CAT: ", data);
        setWallet(locWallet);
        console.log("WALLET: ", locWallet);
        if (
          allLibraries &&
          allLibraries.length > 0 &&
          locWallet?._id &&
          !locWallet.defaultLibrary
        ) {
          await updateWalletApiCall({
            defaultLibrary: allLibraries[0]?._id,
            walletId: locWallet?._id,
          })
            .then((data) => {
              console.log(data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (isInitial) {
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getLibrariesAndCategories(true);
  }, []);

  //wallet update--------------------------------------------------------------
  const updateWalletApiCall = async (updateObj) => {
    setUpdateLoader(true);
    let walletUpdateObj = {
      ...updateObj,
      myProfile: profileId
    }
    await updateWallet(walletUpdateObj)
      .then((data) => {
        setWallet(data);
        setUpdateLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Library--------------------------------------------------------------------
  const librarayAction = (type, library = null) => {
    setCreateLibraryDrawer(false);
    setCurEditDeleteLibrary(null);

    switch (type) {
      case "create":
        setCreateLibraryDrawer(true);
        break;
      case "edit":
        setCurEditDeleteLibrary(library);
        setCreateLibraryDrawer(true);
        break;
      case "delete":
        setCurEditDeleteLibrary(library);
        setLibraryDeleteConfirmBox(true);
        break;
    }
  };

  const afterCreateOrEditNewLibrary = async (data, isEdit) => {
    let arr = [];
    if (isEdit) {
      libraiesArr.map((libraray) => {
        if (libraray?._id == data?._id) {
          arr.push(data);
        } else {
          arr.push(libraray);
        }
      });
      setResponseStatusAndMsg({
        status: "success",
        message: `Library successfully updated`,
      });
      setCurrentLibrary(data);
      setSanckBarBool(true);
      setCurEditDeleteLibrary(null);
    } else {
      arr = [...libraiesArr, data];
      if (libraiesArr.length == 0) {
        setCurrentLibrary(data);
        await updateWalletApiCall({
          defaultLibrary: data?._id,
          walletId: wallet?._id,
        });
      }
      setResponseStatusAndMsg({
        status: "success",
        message: `Library successfully added`,
      });
      setSanckBarBool(true);
    }
    setLibraiesArr(arr);
  };

  const deleteLibrary = async () => {
    setLibraryDeleteLoader(true);
    await deleteLibrarayById({
      libraryId: curEditDeleteLibrary?._id,
    })
      .then((data) => {
        if (data === null) {
          setResponseStatusAndMsg({
            status: "warning",
            message:
              "To delete library first you have to delete all categories, products and services, under the library",
          });
          setSanckBarBool(true);
        } else {
          const arr = libraiesArr.filter((s) => s?._id != data?._id);
          setLibraiesArr(arr);
          setResponseStatusAndMsg({
            status: "success",
            message: "Library successfully deleted",
          });
          setSanckBarBool(true);
        }
        setCurEditDeleteLibrary(null);
        setLibraryDeleteLoader(false);
        setLibraryDeleteConfirmBox(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSanckBarBool(false);
  };

  return (
    <div className={classes.mainCont}>
      {loader ? (
        <LibrarySkeleton />
      ) : (
        <>
          {libraiesArr && libraiesArr.length > 0 ? (
            <>
              <div className={classes.bodyCont}>
                <div className={classes.libraryAndCategoryHeaderCont}>
                  <div className={classes.librayHeader}>
                    <div className={classes.backAndTitleCont}>
                      <IconButton
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        <KeyboardBackspaceIcon />
                      </IconButton>
                      <h3>Library Management</h3>
                    </div>

                    <div
                      className={classes.backAndTitleCont}
                      style={{
                        gap: "10px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          librarayAction("create");
                        }}
                      >
                        New Library
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<BiImport />}
                        onClick={() => {
                          setImportLibraryOpen(true);
                        }}
                      >
                        Import Library
                      </Button>
                    </div>
                  </div>
                  <div style={{ width: "5px", width: "100%" }}>
                    {updateLoader && <LinearProgress />}
                  </div>
                  <div className={classes.librariesCont}>
                    {libraiesArr.map((libraryCategory) => (
                      <div
                        className={classes.libraryCard}
                        onClick={() => {
                          setCurrentLibrary(libraryCategory);
                        }}
                        style={{
                          border:
                            libraryCategory?._id == currentLibrary?._id
                              ? `2px solid ${theme.palette.primary.main}`
                              : "",
                        }}
                      >
                        <div className={classes.libraryImageCont}>
                          <img src={productLibrary} />
                        </div>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "end",
                          }}
                        >
                          <div className={classes.libraryDetailsCont}>
                            <h3>
                              <LessText
                                limit={30}
                                string={libraryCategory?.name}
                              />
                            </h3>
                            {libraryCategory?.createdAt && (
                              <p>
                                Created:{" "}
                                {moment(libraryCategory?.createdAt).format(
                                  "Do MMMM YYYY"
                                )}
                              </p>
                            )}
                          </div>
                          {libraryCategory._id === wallet?.defaultLibrary && (
                            <p
                              style={{
                                fontSize: "10px",
                                color: "green",
                              }}
                            >
                              Default
                            </p>
                          )}
                        </Box>
                        <span className={classes.penSty}>
                          <MyPopOver
                            closeOnClick={true}
                            appearContent={
                              <IconButton>
                                <MoreVertIcon />
                              </IconButton>
                            }
                            showContent={
                              <div style={{ padding: "5px 0px" }}>
                                <div
                                  className={classes.singleOption}
                                  onClick={() => {
                                    setEditOpen(true);
                                    setEditData({
                                      name: libraryCategory.name,
                                      _id: libraryCategory._id,
                                    });
                                    setCurEditDeleteLibrary(libraryCategory);
                                  }}
                                >
                                  Edit
                                </div>
                                {wallet?.defaultLibrary ==
                                libraryCategory?.library?._id ? null : (
                                  <div
                                    className={classes.singleOption}
                                    onClick={async () => {
                                      await updateWalletApiCall({
                                        defaultLibrary: libraryCategory?._id,
                                        walletId: wallet?._id,
                                      });
                                    }}
                                  >
                                    Mark As Default
                                  </div>
                                )}

                                <div
                                  className={classes.singleOption}
                                  onClick={() => {
                                    librarayAction(
                                      "delete",
                                      libraryCategory?.library
                                    );
                                  }}
                                >
                                  Delete
                                </div>
                              </div>
                            }
                          />
                        </span>
                      </div>
                    ))}
                  </div>
                  <div
                    className={classes.offeringCategoryHeader}
                    style={{ border: "none" }}
                  >
                    <Tabs
                      value={curTab}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={(e, value) => {
                        setCurTab(value);
                      }}
                      aria-label="disabled tabs example"
                    >
                      <Tab label="Products & Services" />
                      <Tab label="Categories" />
                      <Tab label="Taxes " />
                      <Tab label="Discounts" />
                      <Tab label="Terms & Conditions" />
                      <Tab label="Approval Templates" />
                      <Tab label="Signatures" />
                      <Tab label="SOPs" />
                      <Tab label="Checklists" />
                    </Tabs>
                    <div></div>
                  </div>
                </div>
                <div className={classes.searchAndTableCont}>
                  {curTab === 0 && (
                    <OfferingsByLibrary
                      libraryId={currentLibrary?._id}
                      setResponseStatusAndMsg={setResponseStatusAndMsg}
                      setSanckBarBool={setSanckBarBool}
                      updateWalletApiCall={updateWalletApiCall}
                      profileId={profileId}
                      wallet={wallet}
                      currentLibrary={currentLibrary}
                    />
                  )}
                  {curTab === 1 && (
                    <CategoriesByLibrary
                      libraryId={currentLibrary?._id}
                      setResponseStatusAndMsg={setResponseStatusAndMsg}
                      setSanckBarBool={setSanckBarBool}
                    />
                  )}
                  {curTab === 2 && (
                    <TaxesByLibrary
                      libraryId={currentLibrary?._id}
                      setResponseStatusAndMsg={setResponseStatusAndMsg}
                      setSanckBarBool={setSanckBarBool}
                      profileId={profileId}
                      wallet={wallet}
                    />
                  )}
                  {curTab === 3 && (
                    <DiscountByLibrary
                      libraryId={currentLibrary?._id}
                      setResponseStatusAndMsg={setResponseStatusAndMsg}
                      setSanckBarBool={setSanckBarBool}
                      profileId={profileId}
                      wallet={wallet}
                    />
                  )}
                  {curTab === 4 && (
                    <TermsAndConditionsByLibrary
                      libraryId={currentLibrary?._id}
                      setResponseStatusAndMsg={setResponseStatusAndMsg}
                      setSanckBarBool={setSanckBarBool}
                      profileId={profileId}
                    />
                  )}
                  {curTab === 5 && (
                    <ApprovalTemplatesByLibrary
                      libraryId={currentLibrary?._id}
                      setResponseStatusAndMsg={setResponseStatusAndMsg}
                      setSanckBarBool={setSanckBarBool}
                      profileId={profileId}
                      afterCreateOrEditNewLibrary={afterCreateOrEditNewLibrary}
                      profileData={profileData}
                      currentLibrary={currentLibrary}
                    />
                  )}
                  {curTab === 6 && (
                    <SignaturesByLibrary
                      libraryId={currentLibrary?._id}
                      profileId={profileId}
                    />
                  )}
                   {curTab === 7 && (
                    <SOPsByLibrary
                      libraryId={currentLibrary?._id}
                      setResponseStatusAndMsg={setResponseStatusAndMsg}
                      setSanckBarBool={setSanckBarBool}
                      profileId={profileId}
                    />
                  )}
                  {curTab === 8 && (
                    <ChecklistsByLibrary
                      libraryId={currentLibrary?._id}
                      setResponseStatusAndMsg={setResponseStatusAndMsg}
                      setSanckBarBool={setSanckBarBool}
                      profileId={profileId}
                    />
                  )}
                </div>
                <div></div>
              </div>
            </>
          ) : (
            <div className={classes.emptyDivSty}>
              <img src={emptyPage} />
              <p>
                Create new library or import library, <br />
                In order to create your own library you have to first create
                library <br />
                then category,and after that you will be able to create new
                product or service.
              </p>
              <div className={classes.emptyBtnCont}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    librarayAction("create");
                  }}
                  style={{ marginRight: "20px" }}
                >
                  New Library
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<BiImport />}
                  onClick={() => {
                    setImportLibraryOpen(true);
                  }}
                >
                  Import Library
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <LibraryEditDrawer
        open={editOpen}
        setOpen={setEditOpen}
        data={editData}
        afterCreateOrEditNewLibrary={afterCreateOrEditNewLibrary}
      />
      <LibraryImport
        importLibraryOpen={importLibraryOpen}
        setImportLibraryOpen={setImportLibraryOpen}
        profileId={profileId}
        wallet={wallet}
        getLibrariesAndCategories={getLibrariesAndCategories}
      />
      <LibraryCreateDrawer
        createLibraryDawer={createLibraryDawer}
        setCreateLibraryDrawer={setCreateLibraryDrawer}
        profileId={profileId}
        afterCreateOrEditNewLibrary={afterCreateOrEditNewLibrary}
        curEditDeleteLibrary={curEditDeleteLibrary}
      />
      <DeleteConfirmBox
        open={libraryDeleteConfirmBox}
        setOpen={setLibraryDeleteConfirmBox}
        loading={libraryDeleteLoader}
        pageTitle={`Delete ${curEditDeleteLibrary?.name}`}
        cantent={
          <div className={classes.deleteConfirm}>
            <ReportProblemIcon />
            <h3>Are you sure?</h3>
            <p>You won't be able to revert this!</p>
          </div>
        }
        cancleConfirmDelete={() => {
          setLibraryDeleteConfirmBox(false);
        }}
        confirmDelete={deleteLibrary}
      />
      <Snackbar
        open={sanckBarBool}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={responseStatusAndMsg?.status}
        >
          {responseStatusAndMsg?.message}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default OfferingsAndProductsManagement;
