const { default: Api } = require('../../helpers/Api');

export const addObservationToParent = async (obj) => {
    try {
        const resData = await Api.post("site-observation/addToParent", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const getDetailsObservation = async (obj) => {
    try {
        const resData = await Api.post("site-observation/get-detail", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const updateObservation = async (obj) => {
    try {
        const resData = await Api.post("site-observation/update-many", obj);
        const resultData = resData?.data || {};
        return resultData;
    }
    catch (error) {
        console.log(error)
    }
};

export const createReportSettingApiCall = async (obj) => {
    try {
        const resResult = await Api.post("report-setting/create", obj);
        const data = resResult?.data || [];
        return data;
    }
    catch (err) {
        console.log(err);
        throw err;
    }
};

export const updateReportSettingApiCall = async (obj) => {
    try {
        const resResult = await Api.post("report-setting/update", obj);
        const data = resResult?.data || [];
        return data;
    }
    catch (err) {
        console.log(err);
        throw err;
    }
};

export const deleteReportSettingApiCall = async (obj) => {
        try {
            const resResult = await Api.post("report-setting/delete", obj);
            const data = resResult?.data || [];
            return data;
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    };
    
    export const getReportSettingsApiCall = async (obj) => {
        try {
            const resResult = await Api.post("report-setting/get", obj);
            const data = resResult?.data || [];
            return data;
        }
        catch (err) {
            console.log(err);
            throw err;
        }
    };