import React, {useEffect} from 'react';
import {
  Drawer,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
} from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const CriticalPathDrawer = ({ open, onClose, task, tasks }) => {

    useEffect(() => {
        // console.log(task,' task in criticalpathdrawer');
        console.log(JSON.stringify(task),' task in criticalpathdrawer')
    }, [task?._id])
    
  // Create a task map for quick lookups
  const taskMap = React.useMemo(() => {
    const map = {};
    tasks.forEach(task => {
      map[task._id.toString()] = task;
    });
    return map;
  }, [tasks]);

  // Get ancestor names for a task
  const getAncestorNames = (task) => {
    const names = [];
    let currentTask = task;
    
    while (currentTask.parentTask && taskMap[currentTask.parentTask]) {
      currentTask = taskMap[currentTask.parentTask];
      names.unshift(currentTask.taskName);
    }
    
    return names;
  };

  // Get project start date
  const projectStartDate = React.useMemo(() => {
    return tasks.reduce((earliest, task) => {
      const taskDate = task.plannedStart || task.startDate;
      if (taskDate && (!earliest || new Date(taskDate) < new Date(earliest))) {
        return taskDate;
      }
      return earliest;
    }, null) || new Date();
  }, [tasks]);

  /**
   * Parses a lag string and returns the lag in days.
   *
   * @param {String} lagStr - The lag string (e.g., "2 days", "+3 hours").
   * @returns {Number} - The lag in days.
   */
  const parseLag = (lagStr) => {
    if (!lagStr) return 0;
    lagStr = lagStr.trim();
    const regex = /^([+-]?\d+)(?:\s*(\w+))?$/;
    const match = lagStr.match(regex);
    if (!match) return 0;
    const value = parseInt(match[1], 10);
    const unit = match[2] ? match[2].toLowerCase() : 'days';
    let multiplier = 1;
    switch (unit) {
      case 'minute':
      case 'minutes':
        multiplier = 1 / (8 * 60); // Assuming 8-hour workday
        break;
      case 'hour':
      case 'hours':
        multiplier = 1 / 8; // Assuming 8-hour workday
        break;
      case 'day':
      case 'days':
        multiplier = 1;
        break;
      case 'week':
      case 'weeks':
        multiplier = 5; // Assuming 5-day workweek
        break;
      default:
        multiplier = 1; // Default to days
    }
    return value * multiplier;
  };

  /**
   * Retrieves all tasks influencing the eS of the given task.
   *
   * @param {Object} task - The selected task.
   * @param {Object} taskMap - Map of all tasks keyed by task ID.
   * @returns {Array} - Array of influencing task objects.
   */
  const getInfluencingTasks = (task, taskMap) => {
    const influencingTasks = new Set();
    const stack = [task._id.toString()];

    while (stack.length > 0) {
      const currentTaskId = stack.pop();
      const currentTask = taskMap[currentTaskId];

      if (!currentTask || !currentTask.predecessorMap) continue;

      currentTask.predecessorMap.forEach(pred => {
        const predTaskId = pred.wbs.toString();
        const predTask = taskMap[predTaskId];

        if (!predTask) return;

        // Calculate pred.eF + lag
        const predEF = predTask.eF || 0;
        const lag = parseLag(pred.lag) || 0;
        const predEFPlusLag = predEF + lag;

        // If predEFPlusLag equals task.eS, predTask influences task's eS
        if (Math.abs(predEFPlusLag - (task.eS || 0)) <= 0.01) { // Allowing small float tolerance
          if (!influencingTasks.has(predTaskId)) {
            influencingTasks.add(predTaskId);
            stack.push(predTaskId); // Continue to find influencing tasks of this predecessor
          }
        }
      });
    }

    // Convert Set to Array of task objects
    return Array.from(influencingTasks).map(id => taskMap[id]).filter(Boolean);
  };

  // Identify influencing tasks
  const influencingTasks = React.useMemo(() => {
    if (!task) return [];
    return getInfluencingTasks(task, taskMap);
  }, [task, taskMap]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '600px',
          p: 2
        }
      }}
    >
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
          <Typography variant="h6">Critical Path</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        
        {task && (
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle1">{task.taskName}</Typography>
            <Typography variant="caption" color="text.secondary">
              {getAncestorNames(task).join(' > ')}
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                // Define your icon button action here
              }}
            >
              <OpenInNewIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>

      <List>
        {task?.criticalPath?.map((taskId) => {
          const criticalTask = taskMap[taskId.toString()];

          if (!criticalTask) return null;
        
          return (
            <ListItem
              key={taskId}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                textDecoration: criticalTask.progress === 100 ? 'line-through' : 'none',
              }}
              secondaryAction={
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => window.open(`/planning/task/${taskId}`, '_blank')}
                >
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            >
              <ListItemText
                primary={
                  <Box sx={{ pr: 4 }}>
                    {criticalTask.taskName}
                  </Box>
                }
                secondary={
                  <>
                    <Typography 
                      variant="caption" 
                      display="block" 
                      color="text.secondary"
                      sx={{ 
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        pr: 4 
                      }}
                    >
                      {getAncestorNames(criticalTask).join(' > ')}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      display="block"
                      color="text.secondary"
                    >
                      Progress: {criticalTask.progress || 0}%
                      {criticalTask.progress === 0 && criticalTask.eS && (
                        <Box component="span" sx={{ ml: 2 }}>
                          Earliest Start: {moment(projectStartDate).add(criticalTask.eS, 'days').format('MMM D, YYYY')}
                        </Box>
                      )}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          );
        })}
        {(!task?.criticalPath || task.criticalPath.length === 0) && (
          <ListItem>
            <ListItemText
              primary="No critical path found"
              secondary="This task may not be part of a critical path"
            />
          </ListItem>
        )}
      </List>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ mb: 1 }}>
        <Typography variant="subtitle1">Influencing Tasks for eS</Typography>
      </Box>

      <List>
        {influencingTasks.length > 0 ? (
          influencingTasks.map((influencingTask) => (
            <ListItem
              key={influencingTask._id}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                textDecoration: influencingTask.progress === 100 ? 'line-through' : 'none',
              }}
              secondaryAction={
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() => window.open(`/planning/task/${influencingTask._id}`, '_blank')}
                >
                  <OpenInNewIcon fontSize="small" />
                </IconButton>
              }
            >
              <ListItemText
                primary={
                  <Box sx={{ pr: 4 }}>
                    {influencingTask.taskName}
                  </Box>
                }
                secondary={
                  <>
                    <Typography 
                      variant="caption" 
                      display="block" 
                      color="text.secondary"
                      sx={{ 
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        pr: 4 
                      }}
                    >
                      {getAncestorNames(influencingTask).join(' > ')}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      display="block"
                      color="text.secondary"
                    >
                      Progress: {influencingTask.progress || 0}%
                      {influencingTask.eS && (
                        <Box component="span" sx={{ ml: 2 }}>
                          Earliest Start: {moment(projectStartDate).add(influencingTask.eS, 'days').format('MMM D, YYYY')}
                        </Box>
                      )}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText
              primary="No influencing tasks found"
              secondary="This task's eS is not influenced by any other tasks"
            />
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};

export default CriticalPathDrawer;

