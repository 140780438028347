import {
    Box,
    Card,
    Typography,
    CardContent,
    Chip,
    Stack,
    Checkbox,
    IconButton
} from "@mui/material";
import React from "react";
import {
    Email,
    Phone,
    AccountBalance,
    Business,
} from "@mui/icons-material";
import CancelIcon from '@material-ui/icons/Cancel';
import LessText from "../LessText";

const InfoRow = ({ icon: Icon, label, value }) => (
    <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
            py: 1.5,
            borderBottom: '1px solid',
            borderColor: 'divider',
            '&:last-child': {
                borderBottom: 'none'
            }
        }}
    >
        <Icon sx={{ color: 'primary.light' }} />
        <Box>
            <Typography variant="caption" color="text.secondary">
                {label}
            </Typography>
            <Typography variant="body2">
                {value}
            </Typography>
        </Box>
    </Stack>
);

const BillingAccountCard = ({
    billingAccount, removeFun, isForRemove = false,
    loader, needToMakeDisable, curSelectedBillingAcc, 
    setCurSelectedBillingAcc
}) => {
    const getDisplayValue = (value, defaultValue = 'N/A') => {
        return value || defaultValue;
    };

    return (
        <Card
            sx={{
                width: '100%',
                bgcolor: 'background.paper',
                borderRadius: 2,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    zIndex: 1,
                }}
            >
                {!needToMakeDisable && (<>
                    {isForRemove ? (
                        <IconButton
                            onClick={() => {
                                removeFun(billingAccount)
                            }}
                            disabled={loader}
                        >
                            <CancelIcon />
                        </IconButton>
                    ) : (
                        <Checkbox
                            checked={curSelectedBillingAcc?._id == billingAccount?._id}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    setCurSelectedBillingAcc(billingAccount)
                                } else {
                                    setCurSelectedBillingAcc(null)
                                }
                            }}
                            disabled={loader}
                            color="primary"
                        />
                    )}
                </>)}
            </Box>

            <CardContent sx={{ p: 3 }}>
                <Typography
                    variant="h6"
                    sx={{
                        mb: 3,
                        color: 'black',
                        fontWeight: 600
                    }}
                >
                    <LessText
                        limit={20}
                        string={billingAccount.name}
                    />
                </Typography>

                <Stack spacing={0}>
                    <InfoRow
                        icon={Email}
                        label="Email Address"
                        value={getDisplayValue(billingAccount.email)}
                    />
                    <InfoRow
                        icon={Phone}
                        label="Phone Number"
                        value={getDisplayValue(billingAccount.phone)}
                    />
                    <InfoRow
                        icon={Business}
                        label="Location"
                        value={getDisplayValue(
                            billingAccount.address
                                ? `${billingAccount.address.city}, ${billingAccount.address.country}`
                                : null
                        )}
                    />
                    <InfoRow
                        icon={AccountBalance}
                        label="Bank Details"
                        value={getDisplayValue(
                            billingAccount.bankAccount
                                ? `${billingAccount.bankAccount.bankName} (${billingAccount.bankAccount.accountNumber})`
                                : null
                        )}
                    />
                </Stack>

                {billingAccount.providers?.length > 0 && (
                    <Box sx={{ mt: 3 }}>
                        <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{ display: 'block', mb: 1 }}
                        >
                            Providers
                        </Typography>
                        <Stack direction="row" flexWrap="wrap" gap={1}>
                            {billingAccount.providers.map((provider, index) => (
                                <Chip
                                    key={index}
                                    label={provider}
                                    size="small"
                                    sx={{
                                        bgcolor: 'primary.50',
                                        color: 'primary.main',
                                        fontWeight: 500,
                                        '&:hover': {
                                            bgcolor: 'primary.100',
                                        }
                                    }}
                                />
                            ))}
                        </Stack>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default BillingAccountCard;