import React, { useEffect, useState } from "react";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { Box, Slider, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import DocumentUploader from "../../../styled/generic/DocumentUploader";
import DocViewer from "../../../styled/generic/DocViewer";
import Api from "../../../../helpers/Api";
import StandardAppContainerRounded from "../../../styled/generic/StandardAppContainerRounded";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import { getProgressColor } from "../../../../helpers/colorHelper";

import dayjs from "dayjs";
import { Typography } from "@material-ui/core";
import Dialog from "../../../styled/generic/Dialog";

const TaskProgressUpdateDrawer = ({
  open,
  setOpen,
  setTasks,
  setTask,
  taskToUpdate,
  isInDetail,
}) => {
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const [workDoneSoFar, setWorkDoneSoFar] = useState(0);
  const [workDoneToday, setWorkDoneToday] = useState(0);
  const [comment, setComment] = useState("");
  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [workLeft, setWorkLeft] = useState(0);
  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);
  const [taskProccessValues, setTaskProccessValues] = useState([0, 0]);
  const [removeFile, setRemoveFile] = useState({
    taskId: taskToUpdate?._id,
    removeFilesIds: [],
  });

  const handleUpdateTask = (task) => {
    setWorkDoneSoFar(task?.progress || 0);
    let _workLeft = 100 - (task?.progress || 0);
    if (_workLeft < 0) _workLeft = 0;
    if (_workLeft > 100) _workLeft = 100;
    setWorkLeft(_workLeft);
    setComment(task?.comment || "");
  };

  const handleTaskUpdate = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    setTaskProccessValues([taskToUpdate?.progress, newValue[1]]);
    setWorkDoneSoFar(newValue[1]);
    setWorkDoneToday(newValue[1] - taskToUpdate?.progress);
    setWorkLeft(100 - newValue[1]);
  };

  useEffect(() => {
    setTaskProccessValues([taskToUpdate?.progress, taskToUpdate?.progress]);
    if (taskToUpdate?._id) {
      handleUpdateTask(taskToUpdate);
    }
  }, [taskToUpdate]);

  const trackProgress = async () => {
    try {
      setLoadingPrimary(true);
      const { data } = await Api.post("/wbs/track-progress", {
        wbsId: taskToUpdate?._id,
        progress: workDoneSoFar,
        files: filesArr,
        removeFile: removeFile,
        previousProgress: workDoneToday,
        comment: comment,
        creator: user?.profile,
      });
      setWorkDoneToday(0);

      if (data) {
        if (setTasks && !isInDetail) {
          setTasks((prev) => {
            let _prev = [...prev];
            let index = _prev.findIndex((m) => m._id === taskToUpdate?._id);
            _prev[index].progress = workDoneSoFar;
            _prev[index].comment = comment;
            _prev[index].files = filesArr;
            return _prev;
          });
        }
        if (setTask) {
          console.log("wbsinprogress ", data?.wbs);
          setTask(data?.wbs);
        }
        setLoadingPrimary(false);
        setOpen(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task updated successfully",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while updating progress",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while updating progress",
        },
      });
    }
  };

  console.log("filesArr", filesArr);

  return (
    <DrawerContainer
      open={open}
      setOpen={setOpen}
      title="Update Task"
      sx={{
        "& .MuiDrawer-paper": {
          width: "800px",
        },
      }}
    >
      <Box sx={{ width: "100%" }}>
        {/* Work Progress Section */}
        <Box sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Progress Tracking
          </Typography>

          <SpaceBetween
            left={
              <FormBox
                label={
                  <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                    Work Done Today
                  </Typography>
                }
              >
                <TextField
                  placeholder="Enter"
                  type="number"
                  value={workDoneToday}
                  readOnly={true}
                  fullWidth={true}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </FormBox>
            }
            right={
              <FormBox
                label={
                  <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                    Work Left
                  </Typography>
                }
              >
                <TextField
                  placeholder="Enter"
                  type="number"
                  value={workLeft}
                  readOnly={true}
                  fullWidth={true}
                  sx={{
                    backgroundColor: "#f5f5f5",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                    },
                  }}
                />
              </FormBox>
            }
          />

          <Box sx={{ mt: 1 }}>
            <Slider
              sx={{
                height: "0.6em",
                color: "success.main",
                "& .MuiSlider-thumb": {
                  width: "1.3rem",
                  height: "1.3rem",
                },
                "& .MuiSlider-track": {
                  border: "none",
                },
              }}
              value={taskProccessValues}
              onChange={handleTaskUpdate}
              valueLabelDisplay="auto"
              min={0}
              max={100}
              disableSwap
            />
          </Box>
        </Box>

        {/* Comments Section */}
        <Box sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Comments
          </Typography>
          <TextField
            placeholder="Add your comments here..."
            multiline
            rows={2}
            fullWidth
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "8px",
              },
            }}
          />
        </Box>

        {/* Attachments Section */}
        <Box sx={{ mb: 1 }}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Attachments
          </Typography>
          <Box
            sx={{
              border: "2px dashed #e0e0e0",
              borderRadius: "8px",
              p: 1,
              backgroundColor: "#fafafa",
            }}
          >
            <DocumentUploader
              documents={filesArr}
              isImageUploaderActive={true}
              onUpload={() => {
                setFileIds(createdFileIds);
                let data = [];
                for (let i = 0; i < createdFileIds?.length; i++) {
                  data.push(fileDictionary[createdFileIds[i]]);
                }
                setFilesArr(data);
              }}
              onDelete={(dataId) => {
                setFileIds(fileIds.filter((m) => m !== dataId));
                setFilesArr(filesArr.filter((m) => m._id !== dataId));
                setRemoveFile((prev) => ({
                  ...prev,
                  removeFilesIds: [...prev.removeFilesIds, dataId],
                }));
              }}
              parentId={taskToUpdate?._id}
              parentType="WBS"
            />
          </Box>

          {/* Preview of uploaded files */}
          {filesArr.length > 0 && (
            <Box>
              {filesArr.length > 0 ? (
                <Box>
                  <DocViewer
                    data={filesArr}
                    isDeletable={true}
                    disableEditMetaData={true}
                    disableShowMetaData={true}
                    onMetadataChange={() => {}}
                    deleteFunction={(delId) => {
                      setFilesArr(
                        filesArr.filter((file) => file._id !== delId)
                      );
                      setRemoveFile((prev) => ({
                        ...prev,
                        removeFilesIds: [...prev.removeFilesIds, delId],
                      }));
                      setFileIds(fileIds.filter((id) => id !== delId));
                    }}
                  />
                </Box>
              ) : (
                <Box>No documents found</Box>
              )}
            </Box>
          )}
        </Box>
        {/* Task History Section */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Progress History
          </Typography>

          <Box>
            {taskToUpdate?.progressHistory?.length > 0 ? (
              <Box>
                {taskToUpdate?.progressHistory
                  ?.filter((item) => item !== null)
                  .map((progress, index) => (
                    <Box
                      key={index}
                      sx={{
                        backgroundColor: "#f8f9fa",
                        borderRadius: "4px",
                        p: 1,
                        mb: 1,
                        "&:hover": { backgroundColor: "#f3f4f6" },
                      }}
                    >
                      <BoxSpaceBetween>
                        <Box sx={{ flex: 1, mr: 1 }}>
                          <BoxSpaceBetween sx={{ alignItems: "flex-start" }}>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "0.9rem",
                                fontWeight: 600,
                                color: "#2d3748",
                              }}
                            >
                              PROGRESS UPDATE
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontSize: "0.8rem",
                                color: "#718096",
                              }}
                            >
                              {dayjs(progress?.createdAt).format(
                                "DD MMM YY, HH:mm"
                              )}
                            </Typography>
                          </BoxSpaceBetween>
                          <Typography
                            variant="body2"
                            sx={{ fontSize: "0.85rem", color: "#4a5568" }}
                          >
                            {progress?.comments || "No comments"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            minWidth: "80px",
                            backgroundColor: getProgressColor(
                              progress?.progress
                            )?.backgroundColor,
                            color: getProgressColor(progress?.progress)?.color,
                            borderRadius: "4px",
                            p: 0.75,
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{ fontSize: "0.7rem" }}
                          >
                            Work Done
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600, fontSize: "0.85rem" }}
                          >
                            {progress?.progress}/100
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: "0.7rem",
                              mt: 0.25,
                              display: "block",
                            }}
                          >
                            Work Left: {100 - progress?.progress}
                          </Typography>
                        </Box>
                      </BoxSpaceBetween>

                      {progress?.docs?.length > 0 && (
                        <Box sx={{ mt: 0.5 }}>
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontSize: "0.75rem",
                              mb: 0.25,
                              color: "#718096",
                            }}
                          >
                            Documents
                          </Typography>
                          <DocViewer
                            closeIcon={false}
                            data={progress?.docs?.flatMap(
                              (doc) => doc?.files || []
                            )}
                            isDeletable={false}
                            disableEditMetaData={true}
                            disableShowMetaData={true}
                            onMetadataChange={() => {}}
                            deleteFunction={() => {}}
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
              </Box>
            ) : (
              <Box sx={{ p: 0.5, textAlign: "center", color: "#718096" }}>
                No progress history found
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {/* Action Buttons */}
      <Box
        sx={{
          borderTop: "1px solid #e0e0e0",
          p: 1,
          backgroundColor: "#fff",
        }}
      >
        <DuoButtonGroup
          primaryButtonText="Update"
          primaryButtonListener={() => trackProgress()}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setOpen(false)}
          secondaryButtonColor="error"
          hideSecondary={!taskToUpdate?._id}
          loadingPrimary={loadingPrimary}
          loadingSecondary={loadingSecondary}
        />
      </Box>
    </DrawerContainer>
  );
};

export default TaskProgressUpdateDrawer;
