import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { Button, LinearProgress } from "@material-ui/core";
import NormalDialog from "./NormalDialog";
import ProjectPicker from "../generic/ProjectPicker";
import OrgPicker from "../generic/OrgPicker";
import CustomSelectAutocomplete from "./CustomSelectAutocomplete";
import NotifyAlert from "./NotifyAlert";
import Api from "../../../helpers/Api";

const useStyles = makeStyles((theme) => ({
    inputsCont: {
        width: "450px",
        height: "500px",
        padding: "10px"
    }
}))

function useOrgProjectUserConnector({
    linkedEntityApi, projectField, organizationField,
    _id, afterLinkEntity
}) {
    const classes = useStyles();

    const [openConnectorDialog, setOpenConnectorDialog] = useState(false)
    const [connectorLoader, setConnectorLoader] = useState(false)
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [selectedProject, setSelectedProject] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [alertObj, setAlertObj] = useState({})

    const { user } = useSelector((state) => state.auth);

    const linkeEntitySubmit = async (e) => {
        e.preventDefault()
        try {
            if (selectedOrg?._id || selectedProject?._id) {
                setConnectorLoader(true)
                let updateObj = {}
                if (selectedOrg?._id) {
                    updateObj[organizationField] = selectedOrg?._id
                }
                if (selectedProject?._id) {
                    updateObj[projectField] = selectedProject?._id
                }
                console.log("update with project organization")
                await Api.post(linkedEntityApi, {
                    updateObj: updateObj,
                    _id: _id
                })
                    .then((data) => {
                        if (selectedProject?.profile?._id) {
                            afterLinkEntity(selectedProject?.profile?._id)
                            setOpenConnectorDialog(false)
                        } else if (selectedOrg?.profile?._id) {
                            afterLinkEntity(selectedOrg?.profile?._id)
                            setOpenConnectorDialog(false)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else if (selectedUser?._id) {
                console.log("update with user")
                afterLinkEntity(selectedUser?.profile)
                setOpenConnectorDialog(false)
            } else {
                setAlertObj({
                    show: true,
                    message: "Please select any entity!",
                    status: "error"
                })
            }
        } catch (err) {
            console.log(err);
        } finally {
            setConnectorLoader(false)
        }
    }

    const addProjectOrgUser = (<>
        <NormalDialog
            openDialog={openConnectorDialog}
            loading={connectorLoader}
            handleCloseShare={() => {
                setAlertObj({
                    show: true,
                    message: "Link any entity first, from the below inputs!",
                    status: "error"
                })
            }}
            pageTitle={"Link Entity"}
            content={<form onSubmit={linkeEntitySubmit} >
                <div className={classes.inputsCont} >
                    <p>Project</p>
                    <ProjectPicker
                        selectedProject={selectedProject}
                        setSelectedProject={setSelectedProject}
                        fullWidth={true}
                    />
                    <div style={{ height: "10px" }} ></div>

                    <p>Organization</p>
                    <OrgPicker
                        selectedOrg={selectedOrg}
                        setSelectedOrg={setSelectedOrg}
                        fullWidth={true}
                    />
                    <div style={{ height: "10px" }} ></div>

                    <p>User</p>
                    <CustomSelectAutocomplete
                        value={selectedUser}
                        onChange={(value) => {
                            setSelectedUser(value);
                        }}
                        options={[user]}
                        fullWidth={true}
                        style={{ width: "100%" }}
                        placeholder={"Select User"}
                        getLabel={(s) => s?.displayName}
                        getIdentity={(s) => s?._id}
                        getImage={(s) => s?.displayPicture?.url}
                        getDisplayName={(s) => s?.displayName}
                        getUsername={(s) => s?.username}
                    />
                    <div><span style={{ color: "red" }} >*</span>Try to avoid select user</div>
                </div>
                <div style={{ width: "100%", height: "4px" }} >
                    {connectorLoader && (<LinearProgress />)}
                </div>
                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "15px" }} >
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="small"
                        disabled={connectorLoader}
                    >
                        Save & Close
                    </Button>
                </div>
            </form>}
        />
        <NotifyAlert
            alertObj={alertObj}
            setAlertObj={setAlertObj}
        />
    </>);

    return {
        addProjectOrgUser,
        setOpenConnectorDialog
    };
}

export default useOrgProjectUserConnector;