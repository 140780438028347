import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getBookingsByCustomer } from "../api.call";
import BookingCard from "../../booking/booking/BookingCard";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "calc(100% - 5px)",
        overflowY: "auto"
    }
}));

export const calculateBookingFinancials = (booking) => {
    const agreementValue = booking?.paymentDetails?.reduce((total, component) => {
        if (component?.componentRef?.aggrementBased) {
            return total + component?.amountReceived
                ? parseInt(component?.amountReceived)
                : 0;
        }
        return total;
    }, 0);

    const totalReceivable = booking?.paymentDetails?.reduce((acc, detail) => {
        return acc + (detail.amountReceivable || 0);
    }, 0);
    const totalPaid = booking?.paymentDetails?.reduce((acc, detail) => {
        return acc + (detail?.amountReceived || 0);
    }, 0);
    const totalDue = totalReceivable - totalPaid;
    const paymentProgress = booking?.aggrement?.paymentProgress || 0;

    return {
        agreementValue,
        totalReceivable,
        totalPaid,
        totalDue,
        paymentProgress,
    };
};

const CSLinkedBookings = ({ curEntityId, customerProfileId, entity }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [bookings, setBookings] = useState([]);
    const [pageLoading, setPageLoading] = useState(true);

    const getBookings = async (page = 1, limit = 12) => {
        setPageLoading(true)
        await getBookingsByCustomer({
            curEntityId,
            entity,
            customerProfileId
        })
            .then((data) => {
                setBookings(data);
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: "AddApiAlert",
                    payload: {
                        success: false,
                        message: "An unknown error occurred",
                    },
                });
            })
            .finally(() => {
                setPageLoading(false)
            })

    };

    useEffect(() => {
        if (curEntityId && customerProfileId) {
            getBookings()
        }
    }, [curEntityId, customerProfileId]);

    return (<>
        <div className={classes.progressCont} >
            {pageLoading && (<LinearProgress />)}
        </div>
        <div className={classes.mainCont} >
            <Grid container spacing={2} >
                {bookings?.map((booking) => (
                    <Grid item lg={4} md={6} xs={12}>
                        <BookingCard booking={booking} />
                    </Grid>
                ))}
            </Grid>
        </div>
    </>);
};

export default CSLinkedBookings;