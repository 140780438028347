
import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Paper, Checkbox, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import LessText from '../LessText';
import { FaSquare } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
    paperMainCont: {
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
        padding: "10px",
        "&:hover": {
			transform: "scale(1.02)"
		},
    },
    detailsCont: {
        width: "calc(100% - 60px)",
        "& h3": {
            fontSize: "18px",
            fontWeight: "500",
            marginBottom: "-3px"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "500",
            color: "gray"
        },
        "& h4": {
            fontSize: "14px",
            color: "gray"
        },
        "& h5": {
            fontSize: "14px",
            color: "black",
            marginLeft: "10px"
        },
    },
    avatarNameCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "10px"
    }
}));

const UnitCard = ({
    cardData, linkedUnits, setLinkedUnits,
    isForRemove = false, removeFun, loader,
    needToMakeDisable
}) => {
    const classes = useStyles();
    const [selectedIds, setSelectedIds] = useState([])
    useEffect(() => {
        setSelectedIds(linkedUnits.map((s) => s?._id))
    }, [linkedUnits])

    return (
        <Paper
            elevation={2}
            className={classes.paperMainCont}
        >
            <div className={classes.detailsCont} >
                <div className={classes.avatarNameCont} >
                    <Avatar style={{ width: "38px", height: "38px" }} >
                        <FaSquare />
                    </Avatar>
                    <div style={{ marginLeft: "10px" }} >
                        <h3>
                            <LessText
                                limit={28}
                                string={cardData?.name}
                            />
                        </h3>
                        <p>{`Floor:- ${cardData?.floor} | Type:- ${cardData?.unitType} | Area:- ${cardData?.area}`}</p>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                    <h4>Block:-</h4>
                    <h5>{cardData?.projectBlock?.name}</h5>
                </div>
            </div>
            {!needToMakeDisable && (<>
                {isForRemove ? (
                    <IconButton
                        onClick={() => {
                            removeFun(cardData)
                        }}
                        disabled={loader}
                    >
                        <CancelIcon />
                    </IconButton>
                ) : (
                    <Checkbox
                        checked={selectedIds.includes(cardData?._id)}
                        onChange={(event) => {
                            if (event.target.checked) {
                                setLinkedUnits([...linkedUnits, cardData])
                            } else {
                                setLinkedUnits(linkedUnits.filter((s) => s?._id != cardData?._id))
                            }
                        }}
                        disabled={loader}
                        color="primary"
                    />
                )}
            </>)}
        </Paper>
    );
};

export default UnitCard;