import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import NormalDrawer from "../NormalDrawer";
import { getBillingAccountsByFinrel } from "./api.call";
import LinearProgress from "@material-ui/core/LinearProgress";
import BillingAccountCard from "./BillingAccountCard";

const useStyles = makeStyles((theme) => ({
    drawerContent: {
        width: "100%",
        height: "100%",
        overflow: "hidden"
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        padding: "20px",
        overflowY: "auto"
    },
    bottomCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #d2d1d1",
    }
}));

const SecondPartyBillingAccountSelectDrawer = ({
    finrelId, openSecondPartyBillingAccountSelect,
    setOpenSecondPartyBillingAccountSelect,
    linkedSecondPartyBillingAccount, onSave
}) => {
    const classes = useStyles();

    const [loader, setLoader] = useState(true)
    const [options, setOptions] = useState([])
    const [curSelectedBillingAcc, setCurSelectedBillingAcc] = useState(null)

    useEffect(() => {
        if (openSecondPartyBillingAccountSelect) {
            setCurSelectedBillingAcc(linkedSecondPartyBillingAccount)
        }
    }, [linkedSecondPartyBillingAccount, openSecondPartyBillingAccountSelect])

    const getBillingAccount = async () => {
        try {
            setLoader(true)
            await getBillingAccountsByFinrel({ finRelId: finrelId })
                .then((data) => {
                    console.log(data)
                    setOptions(data?.data || [])
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    setLoader(false)
                })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (finrelId && openSecondPartyBillingAccountSelect) {
            getBillingAccount()
        }
    }, [finrelId, openSecondPartyBillingAccountSelect])

    return (
        <NormalDrawer
            openDrawer={openSecondPartyBillingAccountSelect}
            setOpenDrawer={setOpenSecondPartyBillingAccountSelect}
            anchor={"right"}
            width={"800px"}
            title={"Select Billing Account"}
            content={<div className={classes.drawerContent}>
                <div style={{ width: "100%", height: "5px" }} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.bodyCont} >
                    <Grid container spacing={2}>
                        {options.map((cardData) => (
                            <Grid item xs={6} key={cardData?._id} >
                                <BillingAccountCard
                                    billingAccount={cardData}
                                    curSelectedBillingAcc={curSelectedBillingAcc}
                                    setCurSelectedBillingAcc={setCurSelectedBillingAcc}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div className={classes.bottomCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            onSave(curSelectedBillingAcc)
                        }}
                        size="small"
                    >
                        Save & Close
                    </Button>
                </div>
            </div>}
        />
    );
};

export default SecondPartyBillingAccountSelectDrawer;
