import React, { useState, useEffect } from 'react';
import {
  getTxByWalletBySpecificSecondParty,
  getTxCountByWalletBySpecificSecondParty
} from '../../finance2o/commonComponent/transaction/api';
import TransactionsTable from '../../finance2o/commonComponent/Transactions.Table';
import TxDetailsDialog from '../../finance2o/commonComponent/TxDetails.Dialog';

export default function CSLinkedTransactions({
  walletId, secondPartyId
}) {

  const [openDetails, setOpenDetails] = useState(false)
  const [selectedTx, setSelectedTx] = useState(null)

  const getEditClickUrl = (tx) => {
    switch (tx?.type) {
      case "Invoice":
        return `/finance/${walletId}/invoice/edit/`
      case "Bill":
        return `/finance/${walletId}/bill/edit/`
      case "DebitNote":
        return `/finance/${walletId}/debit-note/edit/`
      case "CreditNote":
        return `/finance/${walletId}/credit-note/edit/`
    }
  }

  const getViewClickUrl = (tx) => {
    switch (tx?.type) {
      case "Invoice":
        return `/finance/${walletId}/invoice/view/`
      case "Bill":
        return `/finance/${walletId}/bill/view/`
      case "DebitNote":
        return `/finance/${walletId}/debit-note/view/`
      case "CreditNote":
        return `/finance/${walletId}/credit-note/view/`
    }
  }

  return (<>
    <TransactionsTable
      walletId={walletId}
      getDataApiHitFun={getTxByWalletBySpecificSecondParty}
      getDataObj={{
        walletId: walletId,
        secondPartyId,
        type: "All"
      }}
      getNumberOfDataRowApiHit={getTxCountByWalletBySpecificSecondParty}
      getDataNumberObj={{
        walletId: walletId,
        secondPartyId,
        type: "All"
      }}
      setSelectedTx={setSelectedTx}
      setOpenDetails={setOpenDetails}
      emptyText={"No transaction is available"}
    />

    {selectedTx && (
      <TxDetailsDialog
        walletId={walletId}
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        selectedTx={selectedTx}
        setSelectedTx={setSelectedTx}
        editClickUrl={getEditClickUrl(selectedTx)}
        viewClickUrl={getViewClickUrl(selectedTx)}
      />
    )}
  </>);
}