import { makeStyles } from "@material-ui/core/styles";
import { Typography, Chip, Avatar, Tooltip } from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { useHistory } from "react-router-dom";
import { memo, useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  cardsGrid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "12px",
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.up("lg")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  card: {
    backgroundColor: "white",
    border: "1px solid #e0e0e0",
    borderRadius: "12px",
    transition: "all 0.2s ease",
    cursor: "pointer",
    height: "100%",
    "&:hover": {
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.08)",
      transform: "translateY(-2px)",
    },
  },
  cardContent: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  headerSection: {
    borderBottom: "1px solid #f0f0f0",
    paddingBottom: "12px",
    marginBottom: "12px",
  },
  headerFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#1a202c",
    marginBottom: "8px",
    lineHeight: 1.4,
    maxWidth: "310px",
  },
  description: {
    fontSize: "14px",
    color: "#696969",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  dateText: {
    fontSize: "12px",
    color: "#696969",
    marginTop: "4px",
  },
  badgesContainer: {
    display: "flex",
    gap: "6px",
    flexWrap: "wrap",
    marginTop: "8px",
  },
  infoItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "4px",
  },
  infoValue: {
    fontSize: "14px",
    color: "#193B56",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "200px",
  },
  infoLabel: {
    width: "60px",
    fontSize: "12px",
    fontWeight: 500,
    color: "#696969",
    marginRight: "8px",
  },
  creatorSection: {
    marginTop: "auto",
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  creatorAvatar: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "2px solid white",
  },
  creatorInfo: {
    display: "flex",
    flexDirection: "column",
  },
  creatorName: {
    fontSize: "12px",
    fontWeight: 500,
    color: "#193B56",
  },
  creatorRole: {
    fontSize: "11px",
    color: "#696969",
  },
  flexJustifyBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  statusChip: {
    height: "24px",
    fontSize: "12px",
    fontWeight: 500,
  },
}));

const MemoizedCard = memo(({ checklist, onCardClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.card} onClick={onCardClick}>
      <div className={classes.cardContent}>
        <div className={classes.headerSection}>
          <div className={classes.headerFlex}>
            <Typography className={classes.title}>{checklist.title}</Typography>
            <div className={classes.badgesContainer}>
              {checklist.status && (
                <Chip
                  size="small"
                  label={checklist.status}
                  className={classes.statusChip}
                  style={{
                    backgroundColor:
                      checklist.status === "Draft" ? "#f1f5f9" : "#e8f5e9",
                    color: checklist.status === "Draft" ? "#475569" : "#2e7d32",
                  }}
                />
              )}
              {checklist.completionCount && (
                <Chip
                  size="small"
                  label={`${checklist.completionCount.completed}/${checklist.completionCount.total}`}
                  className={classes.statusChip}
                  style={{
                    backgroundColor: "#fef3c7",
                    color: "#92400e",
                  }}
                />
              )}
              {checklist.isTemplate && (
                <Chip
                  size="small"
                  label="Template"
                  style={{
                    backgroundColor: "#e3f2fd",
                    color: "#1976d2",
                  }}
                />
              )}
              {checklist.isPublic && (
                <Chip
                  size="small"
                  label="Public"
                  style={{
                    backgroundColor: "#e8f5e9",
                    color: "#2e7d32",
                  }}
                />
              )}
            </div>
          </div>
          <div className={classes.flexJustifyBetween}>
            <span className={classes.infoValue}>ID: {checklist.customId}</span>
            <Typography className={classes.dateText}>
              {new Date(checklist.date).toLocaleDateString()}
            </Typography>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginTop: "8px",
            }}
          >
            <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              {checklist.structures?.map((structure) => (
                <Chip
                  key={structure._id}
                  label={structure.name}
                  size="small"
                  style={{
                    backgroundColor: structure.colorCode || "#fff3e0",
                    color: "#000",
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                    height: "20px",
                    fontSize: "11px",
                  }}
                />
              ))}
            </div>
            {checklist.structures?.length > 0 &&
              checklist.labels?.length > 0 && (
                <div
                  style={{
                    minHeight: "20px",
                    width: "1px",
                    backgroundColor: "#e0e0e0",
                    margin: "0 4px",
                  }}
                />
              )}
            <div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              {checklist.labels?.map((label) => (
                <Chip
                  key={label._id}
                  label={label.name}
                  size="small"
                  style={{
                    backgroundColor: label.colorCode || "#e3f2fd",
                    color: "#000",
                    border: "1px solid #e0e0e0",
                    borderRadius: "4px",
                    height: "20px",
                    fontSize: "11px",
                  }}
                />
              ))}
            </div>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#f8fafc",
            borderRadius: "8px",
            border: "1px solid #f1f5f9",
            padding: "8px",
            marginTop: "8px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          {(checklist.projectBlocks?.length > 0 ||
            checklist.units?.length > 0) && (
            <div className={classes.locationInfo}>
              {checklist.projectBlocks?.length > 0 && (
                <div className={classes.infoItem}>
                  <span className={classes.infoLabel}>Blocks</span>
                  <Tooltip
                    title={checklist.projectBlocks
                      ?.map((block) => block.name)
                      .join(", ")}
                  >
                    <span className={classes.infoValue}>
                      {checklist.projectBlocks
                        ?.map((block) => block.name)
                        .join(", ")}
                    </span>
                  </Tooltip>
                </div>
              )}
              {checklist.units?.length > 0 && (
                <div className={classes.infoItem}>
                  <span className={classes.infoLabel}>Units</span>
                  <Tooltip
                    title={checklist.units?.map((unit) => unit.name).join(", ")}
                  >
                    <span className={classes.infoValue}>
                      {checklist.units?.map((unit) => unit.name).join(", ")}
                    </span>
                  </Tooltip>
                </div>
              )}
              <div className={classes.flexJustifyBetween}>
                {checklist.columns?.length > 0 && (
                  <div className={classes.infoItem}>
                    <span className={classes.infoLabel}>Columns</span>
                    <span className={classes.infoValue}>
                      {checklist.columns.join(", ")}
                    </span>
                  </div>
                )}
                {checklist.floors?.length > 0 && (
                  <div className={classes.infoItem}>
                    <span className={classes.infoLabel}>Floors</span>
                    <span className={classes.infoValue}>
                      {checklist.floors.join(", ")}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

          {(checklist.creator || checklist.assigned?.length > 0) && (
            <div className={classes.creatorSection}>
              {checklist.creator && (
                <>
                  <img
                    src={
                      checklist.creator?.parent?.displayPicture?.thumbUrl ||
                      checklist.creator?.parent?.displayPicture?.url ||
                      "default-avatar.png"
                    }
                    alt={checklist.creator?.parent?.displayName}
                    className={classes.creatorAvatar}
                  />
                  <div className={classes.creatorInfo}>
                    <span className={classes.creatorName}>
                      {checklist.creator?.parent?.displayName || "Unknown User"}
                    </span>
                    <span className={classes.creatorRole}>Creator</span>
                  </div>
                </>
              )}
              {checklist.assigned?.length > 0 && (
                <div
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <AvatarGroup max={4}>
                    {checklist.assigned?.slice(0, 3).map((assignee, idx) => (
                      <Tooltip
                        key={idx}
                        title={assignee?.parent?.displayName || "Unknown User"}
                        arrow
                      >
                        <Avatar
                          alt={assignee?.parent?.displayName}
                          src={
                            assignee?.parent?.displayPicture?.thumbUrl ||
                            assignee?.parent?.displayPicture?.url
                          }
                          style={{
                            width: "28px",
                            height: "28px",
                          }}
                        />
                      </Tooltip>
                    ))}
                    {checklist.assigned.length > 3 && (
                      <Avatar
                        style={{
                          width: "28px",
                          height: "28px",
                          backgroundColor: "#dedede",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            color: "#2c2c2c",
                          }}
                        >
                          + {checklist.assigned.length - 3}
                        </Typography>
                      </Avatar>
                    )}
                  </AvatarGroup>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

const ChecklistCards = ({ checklists, loading = false }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleCardClick = useCallback(
    (checklistId) => {
      history.push(`/planning/checklist/${checklistId}`);
    },
    [history]
  );

  return (
    <div className={classes.cardsGrid}>
      {checklists?.map((checklist) => (
        <MemoizedCard
          key={checklist._id}
          checklist={checklist}
          onCardClick={() => handleCardClick(checklist._id)}
        />
      ))}
    </div>
  );
};

export default ChecklistCards;
