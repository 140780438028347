import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, IconButton, Avatar } from "@mui/material";
import { AvatarGroup } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import ConfirmationDialog from "../global/ConfirmationDialog";
import moment from "moment";
import { Paper } from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import { makeStyles } from "@material-ui/core/styles";
import LessText from "../styled/CommonComponents/LessText";
import { deleteIssueById } from "./api.call";

const useStyles = makeStyles((theme) => ({
  cardMainCont: {
    padding: "15px",
  },
  optionsCont: {
    padding: "8px",
    maxHeight: "60vh",
    overflowY: "auto",
  },
  singleOption: {
    padding: "5px 10px",
    textAlign: "left",
    cursor: "pointer",
    fontSize: "15px",
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  topBarCard: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "5px",
  },
  descSty: {
    fontSize: "12px",
    color: "gray",
    marginBottom: "20px",
  },
  avatarTitleCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
    },
  },
  valueLabelCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1px",
    "& h3": {
      fontSize: "14px",
      color: "#1b48a5",
      width: "100px",
    },
    "& p": {
      fontSize: "14px",
      color: "black",
      width: "calc(100% - 100px)",
      textAlign: "right",
    },
    "& span": {
      fontSize: "13px",
      color: "gray",
      width: "calc(100% - 100px)",
      textAlign: "right",
      opacity: "0.6",
    },
  },
  bottomBar: {
    borderTop: "1px solid #ececec",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 15px",
    "& h3": {
      fontSize: "12px",
      color: "gray",
    },
    "& p": {
      fontSize: "13px",
      color: "black",
      textAlign: "right",
    },
  },
}));

const SimpleCard = ({ issue, onIssueDelete, xs, md, lg }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const deleteIssue = async () => {
    await deleteIssueById({
      issueId: issue?._id,
    })
      .then((res) => {
        if (res) {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: "Issue deleted successfully",
            },
          });
          onIssueDelete(issue?._id);
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: res?.message || "Something went wrong",
            },
          });
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Something went wrong",
          },
        });
      });
  };

  const getPriority = (priority) => {
    let priority_text = "low";
    switch (priority) {
      case 1:
        priority_text = "Very Low";
        break;
      case 2:
        priority_text = "Low";
        break;
      case 3:
        priority_text = "Medium";
        break;
      case 4:
        priority_text = "High";
        break;
      case 5:
        priority_text = "Urgent";
        break;
    }
    return priority_text;
  };

  return (
    <>
      <Grid
        item
        xs={xs}
        md={md}
        lg={lg}
        style={{ cursor: "pointer" }}
        onClick={() => {
          history.push(`/issue/view/${issue?._id}`);
        }}
      >
        <Paper elevation={2}>
          <div className={classes.cardMainCont}>
            <div className={classes.topBarCard}>
              <div className={classes.avatarTitleCont}>
                <Avatar style={{ backgroundColor: "orange" }}>
                  <BugReportIcon />
                </Avatar>
                <div style={{ marginLeft: "10px" }}>
                  <h3>{issue?.title || "Ticket Title"}</h3>
                  <p>
                    Updated:{" "}
                    {moment(issue?.updatedAt).format("MMM Do YYYY, hh:mm a")}
                  </p>
                </div>
              </div>
              {/* <MyPopOver
                closeOnClick={true}
                appearContent={
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                }
                showContent={
                  <div className={classes.optionsCont}>
                    <div
                      className={classes.singleOption}
                      onClick={() => {
                        history.push(`/issue/edit/${issue?._id}`);
                      }}
                    >
                      Edit
                    </div>
                    <div
                      className={classes.singleOption}
                      onClick={() => {
                        setShowConfirmationDialog(true);
                      }}
                    >
                      Delete
                    </div>
                  </div>
                }
              /> */}
            </div>

            <p className={classes.descSty}>
              <LessText limit={100} string={issue?.description || ""} />
            </p>

            <div className={classes.valueLabelCont}>
              <h3>Priority</h3>
              {issue?.priority ? (
                <p>{getPriority(issue?.priority)}</p>
              ) : (
                <span>Edit Priority</span>
              )}
            </div>
            <div className={classes.valueLabelCont}>
              <h3>Status</h3>
              {issue?.status?.text ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "8px",
                    backgroundColor: issue?.status?.color || "#E48900",
                    color: "white",
                    fontSize: "10px",
                    padding: "2px 8px",
                  }}
                >
                  {issue?.status?.text}
                </Box>
              ) : (
                <span>Edit Status</span>
              )}
            </div>
            <div className={classes.valueLabelCont}>
              <h3>Project</h3>
              {issue?.project?.displayName ? (
                <p>{issue?.project?.displayName}</p>
              ) : (
                <span>Edit Project</span>
              )}
            </div>
            <div className={classes.valueLabelCont}>
              <h3>Organization</h3>
              {issue?.organization?.displayName ? (
                <p>{issue?.organization?.displayName}</p>
              ) : (
                <span>Edit Organization</span>
              )}
            </div>
            <div className={classes.valueLabelCont}>
              <h3>Label</h3>
              <div>
                {issue?.labels?.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      backgroundColor: item?.colorCode + "33",
                      color: item?.colorCode,
                      padding: "4px 8px",
                      borderRadius: "10px",
                      fontSize: "0.875rem",
                      marginRight: "4px",
                      marginBottom: "4px",
                    }}
                  >
                    {item?.name}
                  </Box>
                ))}
              </div>
            </div>
            <div className={classes.valueLabelCont}>
              <h3>Assigned</h3>
              <AvatarGroup max={4}>
                {issue?.assigned &&
                  issue?.assigned.map((participant, idx) => (
                    <Avatar
                      key={idx}
                      style={{ width: "25px", height: "25px" }}
                      title={participant?.parent?.displayName}
                      alt={participant?.parent?.displayName}
                      src={participant?.parent?.displayPicture?.url}
                    />
                  ))}
              </AvatarGroup>
            </div>
          </div>
          <div className={classes.bottomBar}>
            <div>
              <h3>Created By</h3>
              <h3>Created At</h3>
            </div>
            <div>
              <p>{issue?.user?.displayName}</p>
              <p>{moment(issue?.createdAt).format("MMM Do YYYY, hh:mm a")}</p>
            </div>
          </div>
        </Paper>
      </Grid>
      <ConfirmationDialog
        title="Delete Ticket"
        message="Are you sure you want to delete this ticket? This action cannot be undone."
        successButtonText="Yes"
        cancelButtonText="Cancel"
        successListener={() => {
          deleteIssue();
          setShowConfirmationDialog(false);
        }}
        cancelListener={() => {
          setShowConfirmationDialog(false);
        }}
        open={showConfirmationDialog}
        setOpen={setShowConfirmationDialog}
      />
    </>
  );
};

export default SimpleCard;
