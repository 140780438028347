import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { useDebounce } from "react-use";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FaInbox } from "react-icons/fa";
import CRMCard from "../../issue/issue.crm.card";
import SimpleCard from "../../issue/issue.simple.card";
import { getIssuesByBookingLeads } from "../api.call";
import "../../../App.css";

const useStyles = makeStyles((theme) => ({
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #d2d1d1",
  },
  sectionTop: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "17px",
      fontWeight: "500",
      color: "#193B56"
    }
  },
  progressCont: {
    width: "100%",
    height: "5px"
  },
  filterCont: {
    display: "block",
    position: "absolute",
    width: "500px",
    top: "45px",
    right: "0px",
    padding: "15px",
    backgroundColor: "white",
    borderRadius: "5px",
    zIndex: "999",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    transition: "all 0.3s",
  },
  hideFilterCont: {
    display: "none",
    position: "absolute",
    width: "500px",
    top: "45px",
    right: "0px",
    padding: "15px",
    backgroundColor: "white",
    borderRadius: "5px",
    zIndex: "999",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    transition: "all 0.3s",
  },
  emptyCont: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "50px",
    "& p": {
      fontSize: "16px",
      fontWeight: "500",
      color: "gray"
    }
  },
  cardsCont: {
    padding: "10px 15px 15px",
    width: "100%",
    height: "calc(100% - 5px)",
    overflowY: "auto"
  }
}));

const CSLinkedTickets = ({
  curEntityId, entity, customerProfileId
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [issues, setIssues] = useState([])
  const [changeState, setChangeState] = useState()
  const [loader, setLoader] = useState(true)

  const getTickets = async () => {
    setLoader(true)
    await getIssuesByBookingLeads({
      curEntityId,
      entity,
      customerProfileId
    })
      .then((data) => {
        console.log(data)
        setIssues(data || [])
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoader(false)
      })
  }

  useDebounce(() => {
    if (curEntityId && entity) {
      getTickets()
    }
  }, 1000, [changeState, entity, curEntityId])

  return (
    <>
      <div className={classes.progressCont} >
        {loader && (<LinearProgress />)}
      </div>
      {(issues && issues.length > 0) ? (
        <div className={classes.cardsCont} >
          <Grid container spacing={1}>
            {issues.map((issue) => {
              if (!issue?.template?._id) return null;
              const isCRM = issue.template?.isCRM;
              let IssueCard = isCRM ? CRMCard : SimpleCard;
              return (
                <IssueCard
                  key={issue._id}
                  issue={issue}
                  xs={12}
                  md={6}
                  lg={4}
                  onIssueDelete={(deletedIssueId) => {
                    setChangeState(!changeState)
                  }}
                />
              );
            })}
          </Grid>
        </div>
      ) : (<div className={classes.emptyCont} >
        <FaInbox style={{ fontSize: "80px", color: "gray" }} />
        <p>No tickets is available!</p>
      </div>)}
    </>
  );
};

export default CSLinkedTickets;