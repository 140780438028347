import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  getChecklistPagination,
  deleteChecklist,
  cloneDefaultChecklist,
  clearDefaultChecklist
} from "./Api.call";
import LinearProgress from "@material-ui/core/LinearProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import AddIcon from "@material-ui/icons/Add";
import TableRow from "@material-ui/core/TableRow";
import Pagination from "@material-ui/lab/Pagination";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import {
  Button,
  IconButton,
  TextField,
  InputAdornment,
  Typography,
  Chip,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { useDebounce } from "react-use";
import ChecklistCreateDrawer from "./Checklist.create.drawer";
import { library } from "@fortawesome/fontawesome-svg-core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  searchAndAddBtnCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  tableContainer: {
    maxHeight: "calc(100% - 120px)",
    overflowY: "auto",
  },
  paginationCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  deleteConfirm: {
    width: "350px",
    padding: "25px 0px 35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& .MuiSvgIcon-root": {
      fontSize: "75px",
      color: "red",
    },
    "& h3": {
      fontSize: "20px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
  singleOption: {
    padding: "4px 15px",
    fontSize: "15px",
    fontWeight: "500",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ececec",
    },
  },
  cloneDialog: {
    textAlign: "center",
  },
  cloneLoader: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function ChecklistsByLibrary({
  libraryId,
  setResponseStatusAndMsg,
  setSanckBarBool,
  profileId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const [loader, setLoader] = useState(false);
  const [deleteConfirmBox, setDeleteConfirmBox] = useState(false);
  const [changeState, setChangeState] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [createChecklistDrawer, setCreateChecklistDrawer] = useState(false);
  const [checklists, setChecklists] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [curPage, setCurPage] = useState(1);
  const [pageLimit, setPageLimit] = useState(30);
  const [curEditDeleteChecklist, setCurEditDeleteChecklist] = useState(null);
  const [cloneDialogOpen, setCloneDialogOpen] = useState(false);
  const [cloning, setCloning] = useState(false);
  const [clearing, setClearing] = useState(false);

  const getChecklistsPagination = async () => {
    setLoader(true);
    await getChecklistPagination({
      libraryId,
      limit: pageLimit,
      curPage: curPage - 1,
      nameSearch: searchStr,
      isTemplate: true,
    })
      .then((data) => {
        console.log(data);
        const dataArr = data?.data || [];
        const dataCount = data?.count || 0;
        setChecklists(dataArr);
        let locTotalPage = Math.ceil(dataCount / pageLimit);
        setTotalPages(locTotalPage);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  useDebounce(
    () => {
      getChecklistsPagination();
    },
    1000,
    [curPage, changeState]
  );

  useEffect(() => {
    setCurPage(1);
    setChangeState(!changeState);
  }, [searchStr]);

  const checklistAction = async (type, checklist) => {
    setCreateChecklistDrawer(false);
    setCurEditDeleteChecklist(null);
    setDeleteConfirmBox(false);
    switch (type) {
      case "create":
        setCreateChecklistDrawer(true);
        break;
      case "edit":
        setCurEditDeleteChecklist(checklist);
        setCreateChecklistDrawer(true);
        break;
      case "delete":
        setCurEditDeleteChecklist(checklist);
        setDeleteConfirmBox(true);
        break;
    }
  };

  const afterChecklistCreateOrEdit = async () => {
    if (curEditDeleteChecklist?._id) {
      setChangeState(!changeState);
      setResponseStatusAndMsg({
        status: "success",
        message: `Checklist successfully modified`,
      });
      setSanckBarBool(true);
    } else {
      setChangeState(!changeState);
      setCurPage(1);
      setResponseStatusAndMsg({
        status: "success",
        message: `Checklist successfully added`,
      });
      setSanckBarBool(true);
    }
    setCurEditDeleteChecklist(null);
  };

  const deleteChecklistConfirm = async () => {
    if (curEditDeleteChecklist?._id) {
      setLoader(true);
      await deleteChecklist({
        checklistId: curEditDeleteChecklist?._id,
        userId: user?._id,
      })
        .then((data) => {
          setChangeState(!changeState);
          setLoader(false);
          setDeleteConfirmBox(false);
          setResponseStatusAndMsg({
            status: "success",
            message: `${curEditDeleteChecklist?.title} successfully deleted`,
          });
          setSanckBarBool(true);
          setCurEditDeleteChecklist(null);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const handleClearDefaultChecklist = async () => {
    setClearing(true);
    await clearDefaultChecklist({ libraryId })
      .then((data) => {
        setChangeState(!changeState);
        setResponseStatusAndMsg({
          status: "success",
          message: "Default checklists cleared successfully",
        });
        setSanckBarBool(true);
      })
      .catch((error) => {
        setResponseStatusAndMsg({
          status: "error",
          message: "Failed to clear default checklists",
        });
        setSanckBarBool(true);
      })
      .finally(() => {
        setClearing(false);
      });
  };

  const handleCloneDefaultChecklist = () => {
    setCloneDialogOpen(true);
    setCloning(true);

    cloneDefaultChecklist({
      libId: libraryId,
    })
      .then((data) => {
        setChangeState(!changeState);
        setResponseStatusAndMsg({
          status: "success",
          message: "Default checklist cloned successfully",
        });
        setSanckBarBool(true);
      })
      .catch((error) => {
        setResponseStatusAndMsg({
          status: "error",
          message: "Failed to clone default checklist",
        });
        setSanckBarBool(true);
      })
      .finally(() => {
        setCloning(false);
      });
  };

  const handleCloseCloneDialog = () => {
    setCloneDialogOpen(false);
  };

  return (
    <div className={classes.mainCont}>
      <div className={classes.searchAndAddBtnCont}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search checklists"
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCloneDefaultChecklist}
          disabled={cloning}
        >
          {cloning ? "Cloning..." : "Clone Default Checklist"}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={handleClearDefaultChecklist}
          disabled={clearing}
        >
          {clearing ? "Clearing..." : "Clear Default Checklist"}
        </Button>

        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => checklistAction("create")}
        >
          New Checklist
        </Button>
      </div>
      <div style={{ width: "100%", height: "5px" }}>
        {loader && <LinearProgress />}
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Labels</TableCell>
              <TableCell>Checkpoints</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {checklists.map((checklist) => (
              <TableRow key={checklist._id}>
                <TableCell>{checklist.title}</TableCell>
                <TableCell>
                  {checklist.labels && checklist.labels.length > 0
                    ? checklist.labels.map((label) => (
                        <Chip
                          key={label._id}
                          label={label.name}
                          size="small"
                          className={classes.labelChip}
                        />
                      ))
                    : "No labels"}
                </TableCell>
                <TableCell>{checklist.checkpoints.length}</TableCell>
                <TableCell>{checklist.creator?.displayName || "N/A"}</TableCell>
                <TableCell align="center">
                  <MyPopOver
                    closeOnClick={true}
                    appearContent={
                      <IconButton size="small">
                        <MoreVertIcon />
                      </IconButton>
                    }
                    showContent={
                      <div>
                        <div
                          className={classes.singleOption}
                          onClick={() => checklistAction("edit", checklist)}
                        >
                          Edit
                        </div>
                        <div
                          className={classes.singleOption}
                          onClick={() => checklistAction("delete", checklist)}
                        >
                          Delete
                        </div>
                      </div>
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.paginationCont}>
        <Pagination
          count={totalPages}
          page={curPage}
          onChange={(event, value) => setCurPage(value)}
          color="primary"
        />
      </div>

      <ChecklistCreateDrawer
        createChecklistDrawer={createChecklistDrawer}
        setCreateChecklistDrawer={setCreateChecklistDrawer}
        profileId={profileId}
        afterChecklistCreateOrEdit={afterChecklistCreateOrEdit}
        libraryId={libraryId}
        checklistForEditDelete={curEditDeleteChecklist}
        isTemplate={true}
      />

      <DeleteConfirmBox
        open={deleteConfirmBox}
        setOpen={setDeleteConfirmBox}
        loading={loader}
        pageTitle={`Delete ${curEditDeleteChecklist?.title}`}
        cantent={
          <div className={classes.deleteConfirm}>
            <ReportProblemIcon />
            <Typography variant="h3">Are you sure?</Typography>
            <Typography>You won't be able to revert this!</Typography>
          </div>
        }
        cancleConfirmDelete={() => setDeleteConfirmBox(false)}
        confirmDelete={deleteChecklistConfirm}
      />

      <Dialog
        open={cloneDialogOpen}
        onClose={handleCloseCloneDialog}
        aria-labelledby="clone-dialog-title"
        aria-describedby="clone-dialog-description"
      >
        <DialogTitle id="clone-dialog-title">
          Cloning Default Checklist
        </DialogTitle>
        <DialogContent className={classes.cloneDialog}>
          <DialogContentText id="clone-dialog-description">
            This process will take about a minute. You can close this dialog,
            and the cloning will continue in the background.
          </DialogContentText>
          {cloning && <CircularProgress className={classes.cloneLoader} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCloneDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
