import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PurchaseOrder from "./PurchaseOrder";
import WorkOrder from "./WorkOrder";
import Transactions from "./Transactions";
import Certificate from "./Certificate";
import ContactAnalysisDashboard from "./Contact.Analysis.Dashboard";

const useStyles = makeStyles((theme) => ({
    tabMainCont: {
        width: "100%",
        height: "calc(100% - 50px)",
    }
}));

export default function VendorDataAnalysis({
    curEntityId, curEntityProfileId, entity, contactData,
    walletId
}) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [curOption, setCurOption] = useState(0)

    return (
        <>
            <div>
                <Tabs
                    value={curOption}
                    onChange={(event, newValue) => { setCurOption(newValue); }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                >
                    <Tab label="Analysis" />
                    <Tab label="Purchase Orders" />
                    <Tab label="Work Orders" />
                    <Tab label="Transactions" />
                    <Tab label="Certificate" />
                </Tabs>
            </div>
            <Paper elevation={2} className={classes.tabMainCont}  >
                {curOption === 0 && (
                    <ContactAnalysisDashboard
                        entity={entity}
                        curEntityId={curEntityId}
                        curEntityProfileId={curEntityProfileId}
                        secondPartyId={contactData?.profile?._id}
                    />
                )}
                {curOption === 1 && (
                    <PurchaseOrder
                        curEntityId={curEntityId}
                        secondPartyId={contactData?.profile?._id}
                    />
                )}
                {curOption === 2 && (
                    <WorkOrder
                        curEntityId={curEntityId}
                        secondPartyId={contactData?.profile?._id}
                    />
                )}
                {curOption === 3 && (
                    <Transactions
                        secondPartyId={contactData?.profile?._id}
                        curEntityProfileId={curEntityProfileId}
                    />
                )}
                {curOption === 4 && (
                    <Certificate
                        secondPartyId={contactData?.profile?._id}
                        walletId={walletId}
                    />
                )}
            </Paper>
        </>
    );
}