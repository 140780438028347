import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { FaBinoculars } from "react-icons/fa";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { BiTask } from "react-icons/bi";
import { GiArrowScope } from "react-icons/gi";
import TargetsList from "./TargetsList";
import TasksList from "./TasksList";
import ObservationsList from "./ObservationsList";
import { getPlanningCount } from "./api.call";
import ActivitiesList from "../activity/ActivitiesList";
import { FaFilter } from "react-icons/fa";
import { SafetyCheck } from "@mui/icons-material";
import SafetyChecklist from "./SafetyChecklist";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "white",
  },
  header: {
    width: "100%",
    padding: "20px 20px 0px",
    borderBottom: "1px solid #ececec",
    "& h3": {
      fontSize: "25px",
      fontWeight: "600",
      marginBottom: "15px",
      color: "black",
    },
  },
  tabsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
  },
  selectedTab: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    opacity: 1,
    padding: "15px 5px",
    marginRight: "20px",
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    "& p": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
    },
    "& svg": {
      color: theme.palette.primary.main,
      fontSize: "18px",
    },
    "& h4": {
      padding: "0px 5px",
      borderRadius: "8px",
      border: "1px solid gray",
      color: "black",
    },
    transition: "opacity .3s ease-in-out",
  },
  unSelectedTab: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    opacity: 0.7,
    padding: "15px 5px",
    marginBottom: "2px",
    marginRight: "20px",
    "& p": {
      fontSize: "18px",
      fontWeight: "500",
      margin: "0px 10px",
    },
    "& svg": {
      fontSize: "18px",
    },
    "& h4": {
      padding: "0px 5px",
      borderRadius: "8px",
      border: "1px solid gray",
      color: "black",
    },
    transition: "opacity .3s ease-in-out",
  },
  bodyCont: {
    width: "100%",
    height: "calc(100vh - 130px)",
    overflow: "scroll",
  },
}));

const TabComp = ({ Icon, title, count, selected, onClick }) => {
  const classes = useStyles();
  return (
    <div
      className={
        selected === title ? classes.selectedTab : classes.unSelectedTab
      }
      onClick={() => onClick(title)}
    >
      <Icon />
      <p>{title}</p>
      <h4>{count}</h4>
    </div>
  );
};

const PlanningHome2o = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const currentTab = query.get("tab") || "Target";

  const classes = useStyles();
  const { profileId } = useParams();
  const [selected, setSelected] = React.useState(currentTab);
  const [counts, setCounts] = React.useState({});
  const [entityType, setEntityType] = React.useState("");

  const handleTabChange = (tab) => {
    setSelected(tab);
    history.push(`/planning/${profileId}?tab=${tab}`);
  };

  useEffect(() => {
    getPlanningCount({ profileId })
      .then((data) => {
        console.log(data, "getPlanningCount ");
        setCounts(data);
        setEntityType(data?.entityType);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className={classes.mainCont}>
      <div className={classes.header}>
        <h3>Planning (test)</h3>
        <div className={classes.tabsCont}>
          <TabComp
            Icon={GiArrowScope}
            title={"Target"}
            count={counts?.totalTargetCount || 0}
            selected={selected}
            onClick={(v) => {
              handleTabChange(v);
            }}
          />
          <TabComp
            Icon={BiTask}
            title={"Tasks"}
            count={counts?.totalTaskCount || 0}
            selected={selected}
            onClick={(v) => {
              handleTabChange(v);
            }}
          />
          <TabComp
            Icon={SafetyCheck}
            title={"Quality"}
            count={counts?.totalChecklistCount || 0}
            selected={selected}
            onClick={(v) => {
              handleTabChange(v);
            }}
          />
          <TabComp
            Icon={FaBinoculars}
            title={"Observations"}
            count={counts?.totalObservationCount || 0}
            selected={selected}
            onClick={(v) => {
              handleTabChange(v);
            }}
          />
          <TabComp
            Icon={FaFilter}
            title={"Activities"}
            count={counts?.totalActivityCount || 0}
            selected={selected}
            onClick={(v) => {
              handleTabChange(v);
            }}
          />
        </div>
      </div>
      <div className={classes.bodyCont}>
        {selected === "Target" && <TargetsList entityType={entityType} />}
        {selected === "Tasks" && <TasksList entityType={entityType} />}
        {selected === "Quality" && <SafetyChecklist entityType={entityType} />}
        {selected === "Observations" && (
          <ObservationsList entityType={entityType} />
        )}
        {selected === "Activities" && (
          <ActivitiesList entityType={entityType} modules={["Planning"]} />
        )}
      </div>
    </div>
  );
};

export default PlanningHome2o;
