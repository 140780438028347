import { IconButton, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteChecklist } from "../api.call";
import { Edit } from "@material-ui/icons";
import EditChecklistDrawer from "./EditChecklistDrawer";
import { Box, Button, Popover } from "@material-ui/core";

export default function ChecklistOptions({
  checklist,
  setMainLoader,
  getData,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      setMainLoader(true);
      await deleteChecklist({
        checklistId: checklist._id,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Checklist deleted successfully",
        },
      });
      history.goBack();
    } catch (error) {
      console.error("Error deleting checklist:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: error.message,
        },
      });
    } finally {
      setMainLoader(false);
      handleMenuClose();
    }
  };

  return (
    checklist?.status === "Draft" && (
      <>
        <IconButton size="small" onClick={handleMenuClick}>
          <MoreVertIcon />
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          color="primary.main"
        >
          <Box sx={{ p: 1 }}>
            <Button
              startIcon={<Edit />}
              fullWidth
              sx={{ justifyContent: "flex-start", mb: 1 }}
              onClick={() => setIsEditDrawerOpen(true)}
            >
              Edit
            </Button>
            <Button
              startIcon={<DeleteIcon />}
              fullWidth
              sx={{ justifyContent: "flex-start", mb: 1 }}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
        </Popover>
        <EditChecklistDrawer
          isDrawerOpen={isEditDrawerOpen}
          setIsDrawerOpen={setIsEditDrawerOpen}
          checklist={checklist}
          getData={getData}
        />
      </>
    )
  );
}
