const { default: Api } = require('../../helpers/Api');

export const createEventApiCall = async (obj) => {
    try {
        const resResult = await Api.post("calendar/event/create/new", obj);
        const data = resResult?.data || [];
        return data;
    }
    catch (err) {
        console.log(err)
    }
};

export const createRecurringEventApiCall = async (obj) => {
    try {
        const resResult = await Api.post("calendar/event/create/recurring", obj);
        const data = resResult?.data || [];
        return data;
    }
    catch (err) {
        console.log(err)
    }
};

export const deleteEventApiCall = async (obj) => {
    try {
        const resResult = await Api.post("calendar/event/delete/by-id", obj);
        const data = resResult?.data || [];
        return data;
    }
    catch (err) {
        console.log(err)
    }
};

export const getEventsByProfileId = async (obj) => {
    try {
        const resResult = await Api.post("calendar/event/get/by-profile", obj);
        const data = resResult?.data || [];
        return data;
    }
    catch (err) {
        console.log(err)
    }
};

export const getEventDetailsById = async (obj) => {
    try {
        const resResult = await Api.post("calendar/event-details/get/by-id", obj);
        const data = resResult?.data || {};
        return data;
    }
    catch (err) {
        console.log(err)
    }
};

export const updateEventDetailsById = async (obj) => {
    try {
        const resResult = await Api.post("calendar/event/updateEvent/attendees", obj);
        const data = resResult?.data || {};
        return data;
    }
    catch (err) {
        console.log(err)
    }
};
