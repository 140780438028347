import Api from '../../helpers/Api';

export const getProfileParent = async (obj) => {
    try {
        const res = await Api.post('profile/getProfileParent', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const addAssetApiCall = async (obj) => {
    try {
        const res = await Api.post('add/offering/as/asset', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getAllAssetsApiCall = async (obj) => {
    try {
        const res = await Api.post('get/offering/as/asset', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const addMultipleEvents = async (obj) => {
    try {
        const res = await Api.post('create/asset/event/multiple', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const offeringBulkUpdate = async (obj) => {
    try {
        const res = await Api.post('bulk-update/offerings', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getEventsByAsset = async (obj) => {
    try {
        const res = await Api.post('get/asset-event/by-asset', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getEventsByProfile = async (obj) => {
    try {
        const res = await Api.post('get/asset-event/by-profile', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getAssetById = async (obj) => {
    try {
        const res = await Api.post('/get/asset/by-Id', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getAllAssetAssignTo = async (obj) => {
    try {
        const res = await Api.post('/get/all-unique/assign-profiles', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getAllCategories = async (obj) => {
    try {
        const res = await Api.post('/get/all-unique/categories', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getAllAggregateValuesForAssetHome = async (obj) => {
    try {
        const res = await Api.post('/get/asset/all-aggregate-values/by-profile', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const createDefaultAssetCategories = async (obj) => {
    try {
        const res = await Api.post('/create/default-asset/categories', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const updateAsset = async (obj) => {
    try {
        const res = await Api.post('offering/asset/update', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};