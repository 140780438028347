import React, { useEffect, useState } from "react";
import {
    Grid, LinearProgress, Typography, IconButton, Box, Tooltip
} from "@mui/material";
import { Add, EditOutlined } from "@material-ui/icons";
import HorizBox from "../styled/generic/HorizBox.jsx";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween.jsx";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { makeStyles } from "@material-ui/core";
import StandardAppContainerRoundedWithHeader from "../styled/generic/StandardAppContainerRoundedWithHeader.jsx";
import Divider from "../styled/generic/Divider.jsx";
import StatusChip from "../styled/generic/StatusChip.jsx";
import SingleUserWithCaptionTableCell from "../styled/generic/SingleUserWithCaptionTableCell.jsx";
import { GiArrowScope } from "react-icons/gi";
import { BsCheckCircleFill } from "react-icons/bs";
import { AiOutlineClockCircle } from "react-icons/ai";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        backgroundColor: "white"
    },
    progressSection: {
        padding: "16px",
        backgroundColor: "#F8F9FA",
        borderRadius: "8px",
        marginBottom: "16px"
    },
    statsContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "12px",
        gap: "8px"
    },
    statBox: {
        flex: 1,
        padding: "12px",
        backgroundColor: "white",
        borderRadius: "8px",
        textAlign: "center",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)"
    },
    tasksNumCont: {
        display: "flex",
        width: "36px",
        height: "36px",
        padding: "8px 9px",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "32px",
        backgroundColor: "#DBEDFF",
        color: "#2D76E0",
    }
}));

const TargetCard = ({
    target, setTargetToUpdate, setOpenCreateTargetDrawer
}) => {
    const classes = useStyles();
    const history = useHistory();

    const getProgressColor = (progress) => {
        if (progress >= 75) return "#4CAF50";
        if (progress >= 50) return "#2196F3";
        if (progress >= 25) return "#FF9800";
        return "#F44336";
    };

    return (
        <Grid item xs={12} md={6} lg={4}>
            <StandardAppContainerRoundedWithHeader
                icon={<GiArrowScope style={{ fontSize: "25px" }} />}
                title={target?.title || "Untitled"}
                actions={
                    <>
                        <IconButton
                            color="primary"
                            sx={{ 
                                transition: 'all 0.2s',
                                '&:hover': { 
                                    bgcolor: 'rgba(25, 118, 210, 0.08)',
                                    boxShadow: 1 
                                }
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                window.open(`/analytics/target/${target?._id}`, '_blank');
                            }}
                        >
                            <Typography sx={{ fontSize: '1.2rem' }}>📊</Typography>
                        </IconButton>
                        <IconButton
                            color="primary"
                            onClick={(e) => {
                                e.stopPropagation();
                                setTargetToUpdate(target);
                                setOpenCreateTargetDrawer(true);
                            }}
                        >
                            <EditOutlined />
                        </IconButton>
                    </>
                }
                headerTypographyVariant={"subtitle1"}
                headerTypographySx={{ fontWeight: "bold" }}
                onClick={() => history.push(`/planning/target/${target?._id}`)}
                sx={{
                    cursor: "pointer",
                    "&:hover": {
                        transform: "scale(1.01)",
                    },
                }}
            >
                <div className={classes.progressSection}>
                    <BoxSpaceBetween>
                        <Typography variant="body1" fontWeight="500">
                            Overall Progress
                        </Typography>
                        <Typography 
                            variant="body1" 
                            style={{ 
                                color: getProgressColor(target?.stats?.averageProgress),
                                fontWeight: "600"
                            }}
                        >
                            {target?.stats?.averageProgress || 0}%
                        </Typography>
                    </BoxSpaceBetween>
                    <LinearProgress
                        variant="determinate"
                        value={target?.stats?.averageProgress || 0}
                        sx={{
                            mt: 1,
                            mb: 2,
                            height: 8,
                            borderRadius: 4,
                            backgroundColor: '#E0E0E0',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: getProgressColor(target?.stats?.averageProgress),
                                borderRadius: 4,
                            },
                        }}
                    />
                    <div className={classes.statsContainer}>
                        <Tooltip title="Total Tasks">
                            <div className={classes.statBox}>
                                <Typography variant="h6" color="primary">
                                    {target?.stats?.totalTasks || 0}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    Total Tasks
                                </Typography>
                            </div>
                        </Tooltip>
                        <Tooltip title="Completed Tasks">
                            <div className={classes.statBox}>
                                <Typography variant="h6" style={{ color: "#4CAF50" }}>
                                    {target?.stats?.completedTasks || 0}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    Completed
                                </Typography>
                            </div>
                        </Tooltip>
                        <Tooltip title="Pending Tasks">
                            <div className={classes.statBox}>
                                <Typography variant="h6" style={{ color: "#FF9800" }}>
                                    {(target?.stats?.totalTasks || 0) - (target?.stats?.completedTasks || 0)}
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    Pending
                                </Typography>
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <BoxSpaceBetween sx={{ mb: 1 }}>
                    <Typography variant="body1">Project</Typography>
                    <Typography variant="body1" fontWeight="500">
                        {target?.project?.displayName || "-"}
                    </Typography>
                </BoxSpaceBetween>

                <BoxSpaceBetween sx={{ mb: 2 }}>
                    <Typography variant="body1">Organization</Typography>
                    <Typography variant="body1" fontWeight="500">
                        {target?.organization?.displayName || "-"}
                    </Typography>
                </BoxSpaceBetween>

                <Divider />

                <BoxSpaceBetween sx={{ mt: 2 }}>
                    <StatusChip
                        label={`${dayjs(target?.startDate).format("DD MMM YY") + " • " + dayjs(target?.endDate).format("DD MMM YY")}`}
                        variant="Info"
                        icon={<AiOutlineClockCircle />}
                    />
                    <SingleUserWithCaptionTableCell
                        label={target?.creator?.parent?.displayName}
                        src={target?.creator?.parent?.displayPicture?.url}
                        avatarSx={{
                            width: "28px",
                            height: "28px",
                        }}
                        avatarTextGap={1}
                    />
                </BoxSpaceBetween>
            </StandardAppContainerRoundedWithHeader>
        </Grid>
    );
};

export default TargetCard;