import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { Grid, Pagination } from "@mui/material";
import { TextField, Paper, Avatar } from "@material-ui/core";
import { useDebounce } from "react-use";
import LinearProgress from "@material-ui/core/LinearProgress";
import { FaInbox } from "react-icons/fa";
import NoteIcon from '@mui/icons-material/Note';
import { getActivityNotesByLead } from "../api.call";
import moment from "moment";
import "../../../App.css";

const useStyles = makeStyles((theme) => ({
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #d2d1d1",
    },
    sectionTop: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "17px",
            fontWeight: "500",
            color: "#193B56"
        }
    },
    progressCont: {
        width: "100%",
        height: "5px"
    },
    emptyCont: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        padding: "50px",
        "& p": {
            fontSize: "16px",
            fontWeight: "500",
            color: "gray"
        }
    },
    cardsCont: {
        padding: "10px 15px 15px",
        width: "100%",
        height: "calc(100% - 105px)",
        overflowY: "auto"
    },
    paperCard: {
        width: "100%",
        padding: "15px",
        "& h4": {
            fontSize: "14px",
            fontWeight: "500",
            color: "gray",
            marginTop: "10px",
        },
    },
    avatarTitleCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "500",
            color: "gray"
        },
    }
}));

const PSLinkedNotes = ({ finrelId }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [filterObj, setFilterObj] = useState({
        searchStr: "",
    })
    const [activities, setActivities] = useState([])
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)
    const [changeState, setChangeState] = useState()
    const [totalPages, setTotalPages] = useState(0)
    const [loader, setLoader] = useState(true)

    const getTickets = async () => {
        setLoader(true)
        await getActivityNotesByLead({
            finrelId: finrelId,
            filterObj,
            curPage: curPage - 1,
            limit: pageLimit
        })
            .then((data) => {
                console.log(data)
                setActivities(data?.data || [])
                let locTotalPage = Math.ceil((data?.count || 0) / pageLimit);
                setTotalPages(locTotalPage)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoader(false)
            })
    }

    useDebounce(() => {
        if (finrelId) {
            getTickets()
        }
    }, 1000, [curPage, finrelId, changeState])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [filterObj, finrelId])

    return (
        <>
            <div className={classes.sectionTop} >
                <h3></h3>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={filterObj?.searchStr}
                    style={{ width: "300px", marginRight: "10px" }}
                    onChange={(e) => {
                        setFilterObj({
                            ...filterObj,
                            searchStr: e.target.value
                        })
                    }}
                />
            </div>
            <div className={classes.progressCont} >
                {loader && (<LinearProgress />)}
            </div>
            {(activities && activities.length > 0) ? (<>
                <div className={classes.cardsCont} >
                    <Grid container spacing={1}>
                        {activities.map((activity, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index} >
                                <Paper
                                    elevation={2}
                                    className={classes.paperCard}
                                >
                                    <div className={classes.avatarTitleCont} >
                                        <Avatar style={{ backgroundColor: "purple" }} >
                                            <NoteIcon />
                                        </Avatar>
                                        <div style={{ marginLeft: "10px", marginTop: "-2px" }} >
                                            <h3>{activity?.title}</h3>
                                            <p>{moment(activity?.createdAt).format('MMM Do YYYY, hh:mm a')}</p>
                                        </div>
                                    </div>
                                    <h4>{activity?.note}</h4>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
            </>) : (<div className={classes.emptyCont} >
                <FaInbox style={{ fontSize: "80px", color: "gray" }} />
                <p>No Notes is available!</p>
            </div>)}
        </>
    );
};

export default PSLinkedNotes;