
import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import { Button, Grid } from '@material-ui/core';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { FaSquare } from "react-icons/fa";
import { FaTasks } from "react-icons/fa";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import BlockSelectDrawer from './BlockSelectDrawer';
import UnitSelectDrawer from './UnitSelectDrawer';
import TaskSelectDrawer from './TaskSelectDrawer';
import WarehouseSelectDrawer from './WarehouseSelectDrawer';
import BlockCard from './BlockCard';
import UnitCard from './UnitCard';
import TaskCard from './TaskCard';
import WarehouseCard from './WarehouseCard';
import { getLinkedResourcesByParent, linkeResourcesWithResource } from './api.call';
import LinearProgress from '@material-ui/core/LinearProgress';
import SecondPartyBillingAccountSelectDrawer from './SecondPartyBillingAccountSelectDrawer';
import BillingAccountCard from './BillingAccountCard';

const useStyles = makeStyles((theme) => ({
    paperMainCont: {
        width: "100%",
        margin: "15px 0px",
    },
    emptyCont: {
        width: "100%",
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& p": {
            fontSize: "14px",
            fontWeignt: "500",
            marginTop: "10px"
        },
        "& svg": {
            fontSize: "60px"
        }
    },
    sectionTop: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #d0cfcf",
        marginBottom: "15px",
        padding: "5px",
        top: 0,
        "& h3": {
            fontSize: "21px",
            fontWeight: "600",
            color: "#193B56",
        }
    },
}));

const LinkItems = ({
    profileId, parentId, parentModel, needToMakeDisable, finrelId,
    linkableItems = ["block", "unit", "task", "warehouse"]
}) => {
    const classes = useStyles();

    const [loader, setLoader] = useState(false)
    const [linkedBlocks, setLinkedBlocks] = useState([])
    const [linkedUnits, setLinkedUnits] = useState([])
    const [linkedTasks, setLinkedTasks] = useState([])
    const [linkedSecondPartyBillingAccount, setLinkedSecondPartyBillingAccount] = useState([])
    const [linkedWarehouse, setLinkedWarehouse] = useState([])
    const [openBlockSelect, setOpenBlockSelect] = useState(false)
    const [openUnitSelect, setOpenUnitSelect] = useState(false)
    const [openTaskSelect, setOpenTaskSelect] = useState(false)
    const [openWarehouseSelect, setOpenWarehouseSelect] = useState(false)
    const [openSecondPartyBillingAccountSelect, setOpenSecondPartyBillingAccountSelect] = useState(false)

    const getLinkedResources = async () => {
        setLoader(true)
        await getLinkedResourcesByParent({ parentId, parentModel })
            .then((data) => {
                setLinkedBlocks(data?.projectBlocks || [])
                setLinkedUnits(data?.units || [])
                setLinkedTasks(data?.tasks || [])
                setLinkedWarehouse(data?.warehouse || null)
                setLinkedSecondPartyBillingAccount(data?.secondPartyBillingAccount || null)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoader(false)
            })
    }

    useEffect(() => {
        if (parentId && parentModel) {
            getLinkedResources()
        }
    }, [parentId, parentModel])

    const updateLinkedItems = async (type, value) => {
        let requestBody = {
            parentId,
            parentModel
        }
        let updateBody = {}
        if (type === "block") {
            updateBody.projectBlocks = value.map((b) => b?._id)
        }
        if (type === "unit") {
            updateBody.units = value.map((b) => b?._id)
        }
        if (type === "task") {
            updateBody.tasks = value.map((b) => b?._id)
        }
        if (type === "warehouse") {
            updateBody.warehouse = value?._id || null
        }
        if (type === "secondPartyBillingAccount") {
            updateBody.secondPartyBillingAccount = value?._id || null
        }
        setLoader(true)
        await linkeResourcesWithResource({ requestBody, updateBody })
            .then((data) => {
                if (type === "block") {
                    setLinkedBlocks(data?.projectBlocks || [])
                    setOpenBlockSelect(false)
                }
                if (type === "unit") {
                    setLinkedUnits(data?.units || [])
                    setOpenUnitSelect(false)
                }
                if (type === "task") {
                    setLinkedTasks(data?.tasks || [])
                    setOpenTaskSelect(false)
                }
                if (type === "warehouse") {
                    setLinkedWarehouse(data?.warehouse || null)
                    setOpenWarehouseSelect(false)
                }
                if (type === "secondPartyBillingAccount") {
                    setLinkedSecondPartyBillingAccount(data?.secondPartyBillingAccount || null)
                    setOpenSecondPartyBillingAccountSelect(false)
                }
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoader(false)
            })
    }


    return (<div style={{ width: "100%", height: "100%" }} >
        <div style={{ width: "100%", height: "5px" }} >
            {loader && (<LinearProgress />)}
        </div>
        <div
            className={classes.paperMainCont}
        >
            {profileId ? (<>
                {linkableItems.includes("block") && (
                    <div>
                        <div className={classes.sectionTop}>
                            <h3>Linked Block(s)</h3>
                            {!needToMakeDisable && (<>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenBlockSelect(true)
                                    }}
                                    size="medium"
                                    disabled={loader}
                                >
                                    Select Block(s)
                                </Button>
                                <BlockSelectDrawer
                                    profileId={profileId}
                                    openBlockSelect={openBlockSelect}
                                    setOpenBlockSelect={setOpenBlockSelect}
                                    linkedBlocks={linkedBlocks}
                                    onSave={(data) => {
                                        updateLinkedItems("block", data)
                                    }}
                                />
                            </>)}
                        </div>
                        <div>
                            {(linkedBlocks && linkedBlocks.length > 0) ? (<Grid container spacing={2} >
                                {linkedBlocks.map((cardData) => (
                                    <Grid item xs={4} key={cardData?._id} >
                                        <BlockCard
                                            cardData={cardData}
                                            setLinkedBlocks={setLinkedBlocks}
                                            linkedBlocks={linkedBlocks}
                                            isForRemove={true}
                                            loader={loader}
                                            needToMakeDisable={needToMakeDisable}
                                            removeFun={(data) => {
                                                updateLinkedItems("block", linkedBlocks.filter((b) => b?._id != data?._id))
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>) : (
                                <div className={classes.emptyCont} >
                                    <WidgetsIcon />
                                    <p>No block(s) linked with current resources!</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {linkableItems.includes("unit") && (
                    <div style={{ marginTop: "20px" }} >
                        <div className={classes.sectionTop}>
                            <h3>Linked Unit(s)</h3>
                            {!needToMakeDisable && (<>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenUnitSelect(true)
                                    }}
                                    size="medium"
                                    disabled={linkedBlocks.length === 0 ? true : loader}
                                >
                                    Select Unit(s)
                                </Button>
                                <UnitSelectDrawer
                                    profileId={profileId}
                                    openUnitSelect={openUnitSelect}
                                    setOpenUnitSelect={setOpenUnitSelect}
                                    linkedUnits={linkedUnits}
                                    linkedBlocks={linkedBlocks}
                                    onSave={(data) => {
                                        updateLinkedItems("unit", data)
                                    }}
                                />
                            </>)}
                        </div>
                        <div>
                            {(linkedUnits && linkedUnits.length > 0) ? (<Grid container spacing={2} >
                                {linkedUnits.map((cardData) => (
                                    <Grid item xs={4} key={cardData?._id} >
                                        <UnitCard
                                            cardData={cardData}
                                            setLinkedUnits={setLinkedUnits}
                                            linkedUnits={linkedUnits}
                                            isForRemove={true}
                                            loader={loader}
                                            needToMakeDisable={needToMakeDisable}
                                            removeFun={(data) => {
                                                updateLinkedItems("unit", linkedUnits.filter((b) => b?._id != data?._id))
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>) : (
                                <div className={classes.emptyCont} >
                                    <FaSquare />
                                    <p>No unit(s) linked with current resources. To link unit, you have to link block(s) first.</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {linkableItems.includes("task") && (
                    <div style={{ marginTop: "20px" }} >
                        <div className={classes.sectionTop}>
                            <h3>Linked Task(s)</h3>
                            {!needToMakeDisable && (<>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenTaskSelect(true)
                                    }}
                                    size="medium"
                                    disabled={loader}
                                >
                                    Select Task(s)
                                </Button>
                                <TaskSelectDrawer
                                    profileId={profileId}
                                    openTaskSelect={openTaskSelect}
                                    setOpenTaskSelect={setOpenTaskSelect}
                                    linkedTasks={linkedTasks}
                                    onSave={(data) => {
                                        updateLinkedItems("task", data)
                                    }}
                                />
                            </>)}
                        </div>
                        <div>
                            {(linkedTasks && linkedTasks.length > 0) ? (<Grid container spacing={2} >
                                {linkedTasks.map((cardData) => (
                                    <Grid item xs={4} key={cardData?._id} >
                                        <TaskCard
                                            cardData={cardData}
                                            setLinkedTasks={setLinkedTasks}
                                            linkedTasks={linkedTasks}
                                            isForRemove={true}
                                            loader={loader}
                                            needToMakeDisable={needToMakeDisable}
                                            removeFun={(data) => {
                                                updateLinkedItems("task", linkedTasks.filter((b) => b?._id != data?._id))
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>) : (
                                <div className={classes.emptyCont} >
                                    <FaTasks />
                                    <p>No task(s) linked with current resources!</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {linkableItems.includes("warehouse") && (
                    <div style={{ marginTop: "20px" }} >
                        <div className={classes.sectionTop}>
                            <h3>Linked Warehouse</h3>
                            {!needToMakeDisable && (<>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenWarehouseSelect(true)
                                    }}
                                    size="medium"
                                    disabled={loader}
                                >
                                    Select Warehouse
                                </Button>
                                <WarehouseSelectDrawer
                                    profileId={profileId}
                                    openWarehouseSelect={openWarehouseSelect}
                                    setOpenWarehouseSelect={setOpenWarehouseSelect}
                                    linkedWarehouse={linkedWarehouse}
                                    onSave={(data) => {
                                        updateLinkedItems("warehouse", data)
                                    }}
                                />
                            </>)}
                        </div>
                        <div>
                            {(linkedWarehouse && linkedWarehouse?._id) ? (<Grid container spacing={2} >
                                <Grid item xs={4} >
                                    <WarehouseCard
                                        cardData={linkedWarehouse}
                                        setLinkedWarehouse={setLinkedWarehouse}
                                        linkedWarehouse={linkedWarehouse}
                                        isForRemove={true}
                                        loader={loader}
                                        needToMakeDisable={needToMakeDisable}
                                        removeFun={(data) => {
                                            updateLinkedItems("warehouse", null)
                                        }}
                                    />
                                </Grid>
                            </Grid>) : (
                                <div className={classes.emptyCont} >
                                    <WarehouseIcon />
                                    <p>No warehouse(s) linked with current resources!</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {linkableItems.includes("secondPartyBillingAccount") && (
                    <div style={{ marginTop: "20px" }} >
                        <div className={classes.sectionTop}>
                            <h3>Billing Account</h3>
                            {!needToMakeDisable && (<>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenSecondPartyBillingAccountSelect(true)
                                    }}
                                    size="medium"
                                    disabled={loader}
                                >
                                    Select Billing Account
                                </Button>
                                <SecondPartyBillingAccountSelectDrawer
                                    finrelId={finrelId}
                                    openSecondPartyBillingAccountSelect={openSecondPartyBillingAccountSelect}
                                    setOpenSecondPartyBillingAccountSelect={setOpenSecondPartyBillingAccountSelect}
                                    linkedSecondPartyBillingAccount={linkedSecondPartyBillingAccount}
                                    onSave={(data) => {
                                        updateLinkedItems("secondPartyBillingAccount", data)
                                    }}
                                />
                            </>)}
                        </div>
                        <div>
                            {(linkedSecondPartyBillingAccount && linkedSecondPartyBillingAccount?._id) ? (<Grid container spacing={2} >
                                <Grid item xs={4} >
                                    <BillingAccountCard
                                        billingAccount={linkedSecondPartyBillingAccount}
                                        removeFun={(data) => {
                                            updateLinkedItems("secondPartyBillingAccount", null)
                                        }}
                                        isForRemove={true}
                                        loader={loader}
                                        needToMakeDisable={needToMakeDisable}
                                        curSelectedBillingAcc={linkedSecondPartyBillingAccount}
                                        setCurSelectedBillingAcc={setLinkedSecondPartyBillingAccount}
                                    />
                                </Grid>
                            </Grid>) : (
                                <div className={classes.emptyCont} >
                                    <AccountBalanceIcon />
                                    <p>No billing account linked with current resources!</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </>) : (<div className={classes.emptyCont} >
                <DatasetLinkedIcon />
                <p>Please add project or organization first, before linking any other resources</p>
            </div>)}
        </div>
    </div>);
};

export default LinkItems;