import React from "react";
import {
    Box, FormControl, MenuItem, Select, Radio,
    RadioGroup, FormControlLabel, Checkbox,
    TextField, Grid,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { makeStyles } from '@material-ui/core/styles';
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "13px",
        fontWeight: "500",
        marginBottom: "3px",
        marginTop: "2px"
    }
}));

export const generateRecurringEventDates = ({
    startDate, endDate, repeatEvery, repeatType, daysOfWeek = [],
    monthlyOption, dayOfMonth, nthWeekOfMonth, dayOfWeek, yearlyOption,
    specificDateMonth, specificDateDay, nthWeek, weekDay, nthWeekMonth,
}) => {
    const resultDates = [];
    const start = dayjs(new Date(startDate));
    const end = dayjs(new Date(endDate));

    if (!start.isValid() || !end.isValid()) return [];

    const addDateIfInRange = (date) => {
        if (date.isBetween(start, end, null, "[]")) {
            resultDates.push(date.format("YYYY-MM-DD"));
        }
    };

    switch (repeatType) {
        case "Day": {
            let current = start.clone();
            while (current.isBefore(end) || current.isSame(end)) {
                addDateIfInRange(current);
                current = current.add(repeatEvery, "day");
            }
            break;
        }

        case "Week": {
            let current = start.clone();
            while (current.isBefore(end) || current.isSame(end)) {
                if (daysOfWeek.includes(current.format("dddd"))) {
                    addDateIfInRange(current);
                }
                current = current.add(1, "day");
            }
            break;
        }

        case "Month": {
            let current = start.clone();
            while (current.isBefore(end) || current.isSame(end)) {
                if (monthlyOption === "OnDate") {
                    const date = current.date(dayOfMonth);
                    addDateIfInRange(date);
                } else if (monthlyOption === "OnDay") {
                    const nthWeekDate = getNthWeekdayInMonth(
                        current.year(),
                        current.month(),
                        nthWeekOfMonth,
                        dayOfWeek
                    );
                    if (nthWeekDate) addDateIfInRange(nthWeekDate);
                }
                current = current.add(repeatEvery, "month");
            }
            break;
        }

        case "Year": {
            let current = start.clone();
            while (current.isBefore(end) || current.isSame(end)) {
                if (yearlyOption === "OnDate") {
                    const date = current
                        .month(specificDateMonth)
                        .date(specificDateDay);
                    addDateIfInRange(date);
                } else if (yearlyOption === "OnNthWeekday") {
                    const nthWeekDate = getNthWeekdayInMonth(
                        current.year(),
                        parseInt(nthWeekMonth),
                        parseInt(nthWeek),
                        weekDay
                    );
                    if (nthWeekDate) addDateIfInRange(nthWeekDate);
                }
                current = current.startOf("year").add(repeatEvery, "year");
            }
            break;
        }

        default:
            break;
    }

    return resultDates;
};

const getNthWeekdayInMonth = (year, month, nthWeek, dayOfWeek) => {
    const adjustedMonth = month;

    const firstDay = dayjs(new Date(year, adjustedMonth, 1));

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayIndex = dayNames.indexOf(dayOfWeek);
    
    if (dayIndex === -1) {
        throw new Error('Invalid day of week');
    }

    let firstOccurrence = firstDay.day(dayIndex);

    if (firstOccurrence.month() !== adjustedMonth) {
        firstOccurrence = firstOccurrence.add(7, 'day');
    }

    if (nthWeek === "Last") {
        const lastDay = firstDay.endOf('month');
        let lastOccurrence = lastDay.day(dayIndex);
        
        if (lastOccurrence.month() !== adjustedMonth) {
            lastOccurrence = lastOccurrence.subtract(7, 'day');
        }
        
        return lastOccurrence;
    } else {
        const nthOccurrence = firstOccurrence.add((parseInt(nthWeek) - 1) * 7, 'day');
        
        return nthOccurrence.month() === adjustedMonth ? nthOccurrence : null;
    }
};

const RecurringEventUI = ({
    startDateRE, setStartDateRE, repeatEvery, setRepeatEvery,
    repeatType, setRepeatType, daysOfWeek, setDaysOfWeek,
    monthlyOption, setMonthlyOption, dayOfMonth, setDayOfMonth,
    dayOfWeek, setDayOfWeek, endDateRE, setEndDateRE,
    yearlyOption, setYearlyOption, specificDateMonth,
    setSpecificDateMonth, specificDateDay, setSpecificDateDay,
    nthWeek, setNthWeek, weekDay, setWeekDay, nthWeekMonth,
    setNthWeekMonth, nthWeekOfMonth, setNthWeekOfMonth,
}) => {
    const classes = useStyles();

    const handleDaysOfWeekChange = (event) => {
        const { value } = event.target;
        setDaysOfWeek((prev) =>
            prev.includes(value)
                ? prev.filter((day) => day !== value)
                : [...prev, value]
        );
    };

    return (
        <>
            <Grid item xs={6}>
                <p className={classes.label} >Start Date</p>
                <DateTimePicker
                    value={startDateRE}
                    onChange={(newValue) => setStartDateRE(newValue)}
                    slotProps={{
                        textField: {
                            size: "small",
                            fullWidth: true,
                            InputProps: {
                                value: dayjs(startDateRE).format("D MMM YYYY"),
                            },
                        },
                    }}
                />
            </Grid>

            <Grid item xs={6}>
                <p className={classes.label} >End Date</p>
                <DateTimePicker
                    value={endDateRE}
                    onChange={(newValue) => setEndDateRE(newValue)}
                    slotProps={{
                        textField: {
                            size: "small",
                            fullWidth: true,
                            InputProps: {
                                value: dayjs(endDateRE).format("D MMM YYYY"),
                            },
                        },
                    }}
                />
            </Grid>

            <Grid item xs={3}>
                <p className={classes.label} >Repeat Every</p>
                <TextField
                    type="number"
                    size="small"
                    fullWidth
                    value={repeatEvery}
                    onChange={(e) => setRepeatEvery(e.target.value)}
                />
            </Grid>

            <Grid item xs={4}>
                <p className={classes.label} >Frequency</p>
                <FormControl size="small" fullWidth >
                    <Select
                        value={repeatType}
                        onChange={(e) => setRepeatType(e.target.value)}
                    >
                        <MenuItem value="Day">Day</MenuItem>
                        <MenuItem value="Week">Week</MenuItem>
                        <MenuItem value="Month">Month</MenuItem>
                        <MenuItem value="Year">Year</MenuItem>
                    </Select>
                </FormControl>
            </Grid>


            {/* Dynamic Options Based on Recurrence Type */}
            {repeatType === "Week" && (
                <Grid item xs={12}>
                    <p className={classes.label} >Select Days of the Week:</p>
                    {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                        <FormControlLabel
                            key={day}
                            control={
                                <Checkbox
                                    checked={daysOfWeek.includes(day)}
                                    onChange={handleDaysOfWeekChange}
                                    value={day}
                                />
                            }
                            label={day}
                        />
                    ))}
                </Grid>
            )}

            {repeatType === "Month" && (
                <Grid item xs={12}>
                    <FormControl>
                        <RadioGroup
                            value={monthlyOption}
                            onChange={(e) => setMonthlyOption(e.target.value)}
                        >
                            <FormControlLabel
                                value="OnDate"
                                control={<Radio />}
                                label={<>
                                    <span style={{ position: "relative", top: "8px" }} >On day</span>
                                    <TextField
                                        type="number"
                                        size="small"
                                        value={dayOfMonth}
                                        onChange={(e) => setDayOfMonth(e.target.value)}
                                        sx={{ width: 150, ml: 2 }}
                                    />
                                </>}
                            />
                            <FormControlLabel
                                style={{ marginTop: "12px" }}
                                value="OnDay"
                                control={<Radio />}
                                label={
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <span>On the</span>
                                        <FormControl sx={{ width: 120 }} style={{ marginLeft: "20px" }} size="small" >
                                            <Select
                                                value={nthWeekOfMonth}
                                                onChange={(e) => setNthWeekOfMonth(e.target.value)}
                                            >
                                                <MenuItem value="1">First</MenuItem>
                                                <MenuItem value="2">Second</MenuItem>
                                                <MenuItem value="3">Third</MenuItem>
                                                <MenuItem value="4">Fourth</MenuItem>
                                                <MenuItem value="Last">Last</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ width: 150 }} style={{ marginLeft: "20px" }} size="small" >
                                            <Select
                                                value={dayOfWeek}
                                                onChange={(e) => setDayOfWeek(e.target.value)}
                                            >
                                                <MenuItem value="Monday">Monday</MenuItem>
                                                <MenuItem value="Tuesday">Tuesday</MenuItem>
                                                <MenuItem value="Wednesday">Wednesday</MenuItem>
                                                <MenuItem value="Thursday">Thursday</MenuItem>
                                                <MenuItem value="Friday">Friday</MenuItem>
                                                <MenuItem value="Saturday">Saturday</MenuItem>
                                                <MenuItem value="Sunday">Sunday</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            )}

            <Grid item xs={12}>
                {repeatType === "Year" && (
                    <RadioGroup
                        value={yearlyOption}
                        onChange={(e) => setYearlyOption(e.target.value)}
                    >
                        <FormControlLabel
                            value="OnDate"
                            control={<Radio />}
                            label={
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <span>On</span>
                                    <FormControl sx={{ width: 135, mr: 2, ml: 2 }} size="small" >
                                        <Select
                                            value={specificDateMonth}
                                            onChange={(e) => setSpecificDateMonth(e.target.value)}
                                        >
                                            {[
                                                "January",
                                                "February",
                                                "March",
                                                "April",
                                                "May",
                                                "June",
                                                "July",
                                                "August",
                                                "September",
                                                "October",
                                                "November",
                                                "December",
                                            ].map((month, index) => (
                                                <MenuItem key={index} value={index}>
                                                    {month}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        type="number"
                                        size="small"
                                        value={specificDateDay}
                                        onChange={(e) => setSpecificDateDay(e.target.value)}
                                        sx={{ width: 70 }}
                                    />
                                </Box>
                            }
                        />
                        <FormControlLabel
                            value="OnNthWeekday"
                            sx={{ mt: 2 }}
                            control={<Radio />}
                            label={
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <span>On the</span>
                                    <FormControl sx={{ width: 120, mr: 2, ml: 2 }} size="small" >
                                        <Select
                                            value={nthWeek}
                                            onChange={(e) => setNthWeek(e.target.value)}
                                        >
                                            <MenuItem value="1">First</MenuItem>
                                            <MenuItem value="2">Second</MenuItem>
                                            <MenuItem value="3">Third</MenuItem>
                                            <MenuItem value="4">Fourth</MenuItem>
                                            <MenuItem value="Last">Last</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{ width: 140, mr: 2 }} size="small" >
                                        <Select
                                            value={weekDay}
                                            onChange={(e) => setWeekDay(e.target.value)}
                                        >
                                            {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                                                <MenuItem key={day} value={day}>
                                                    {day}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <span>Of</span>
                                    <FormControl sx={{ width: 150, ml: 2 }} size="small" >
                                        <Select
                                            value={nthWeekMonth}
                                            onChange={(e) => setNthWeekMonth(e.target.value)}
                                        >
                                            {[
                                                "January",
                                                "February",
                                                "March",
                                                "April",
                                                "May",
                                                "June",
                                                "July",
                                                "August",
                                                "September",
                                                "October",
                                                "November",
                                                "December",
                                            ].map((month, index) => (
                                                <MenuItem key={index} value={index}>
                                                    {month}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            }
                        />
                    </RadioGroup>
                )}
            </Grid>
        </>
    );
};

export default RecurringEventUI;