import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
} from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Api from "../../helpers/Api";
import MinusSquare from "./icons/MinusSquare";
import PlusSquare from "./icons/PlusSquare";
import CloseSquare from "./icons/CloseSquare";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 600,
    padding: 20,
  },
  header: {
    marginBottom: 20,
  },
  footer: {
    marginTop: 20,
    display: "flex",
    justifyContent: "flex-end",
    gap: 10,
  },
  label: {
    padding: "4px 0",
  }
}));

const LIBRARY_OPTIONS = [
  { label: "Gagan Activity Library", value: "Gagan_activity_V1" },
];

// Internal CategoryTree component
const CategoryTree = ({ category }) => {
  const classes = useStyles();

  return (
    <TreeItem 
      nodeId={category.name}
      label={
        <div className={classes.label}>
          {category.name}
        </div>
      }
    >
      {category.children?.map((child, index) => (
        <CategoryTree 
          key={`${child.name}-${index}`}
          category={child}
        />
      ))}
    </TreeItem>
  );
};

const ImportLibraryDrawer = ({ open, setOpen, profileId, libraryId, afterImport }) => {
  const classes = useStyles();
  const userId = useSelector((state) => state?.auth?.user?._id);
  
  const [selectedLibrary, setSelectedLibrary] = useState("");
  const [previewData, setPreviewData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleLibraryChange = async (event) => {
    const value = event.target.value;
    setSelectedLibrary(value);
    if (value) {
      try {
        const response = await Api.post("/library/preview/category-structure", {
          libraryCode: value,
        });
        setPreviewData(response?.data || []);
      } catch (error) {
        console.error("Error fetching preview:", error);
      }
    }
  };

  const handleImport = async () => {
    if (!selectedLibrary || !libraryId || !userId) return;
    
    setLoading(true);
    try {
      await Api.post("library/create/activity-category/custom", {
        libraryId: libraryId,
        libraryCode: selectedLibrary,
        userId,
        profileId
      });
      afterImport();
      setOpen(false);
    } catch (error) {
      console.error("Error importing library:", error);
    }
    setLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
    >
      <div className={classes.drawer}>
        <Typography variant="h6" className={classes.header}>
          Import Library
        </Typography>

        <FormControl fullWidth>
          <Select
            value={selectedLibrary}
            onChange={handleLibraryChange}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Library
            </MenuItem>
            {LIBRARY_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {previewData.length > 0 && (
          <Box mt={3}>
            <Typography variant="subtitle1" gutterBottom>
              Preview Structure:
            </Typography>
            <TreeView
              defaultCollapseIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<CloseSquare />}
              defaultExpanded={['Construction']}
            >
              {previewData.map((category, index) => (
                <CategoryTree
                  key={`${category.name}-${index}`}
                  category={category}
                />
              ))}
            </TreeView>
          </Box>
        )}

        <div className={classes.footer}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleImport}
            variant="contained"
            color="primary"
            disabled={!selectedLibrary || loading}
          >
            {loading ? "Importing..." : "Import"}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default ImportLibraryDrawer; 